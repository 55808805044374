import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainBookingPanelComponent } from './main-booking-panel/main-booking-panel.component';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { BookingPreviewComponent } from './booking-preview/booking-preview.component';
import { EventBookingPanelComponent } from './event-booking-panel/event-booking-panel.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

const routes: Routes = [
  Shell.childRoutes([{ path: 'booking', component: MainBookingPanelComponent, data: { title: marker('Services') } }]),
];

@NgModule({
  declarations: [MainBookingPanelComponent, BookingPreviewComponent, EventBookingPanelComponent],
  imports: [
    CommonModule,
    GlobalPipesModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    FlexModule,
    FlexLayoutModule,
    RouterModule.forChild(routes),
  ],
  entryComponents: [BookingPreviewComponent],
  exports: [BookingPreviewComponent, EventBookingPanelComponent],
})
export class BookingPanelModule {}
