import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { GlobalPipesModule } from './pipes/globalPipes.module';

@NgModule({
  imports: [CommonModule, GlobalPipesModule],
  declarations: [LoaderComponent],
  exports: [LoaderComponent, GlobalPipesModule],
})
export class SharedModule {}
