import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public currentLang = 'en';

  constructor(private http: HttpClient, private translateService: TranslateService) {
    this.currentLang = this.translateService.getDefaultLang();
    console.log('this.currentLang', this.currentLang);
    this.translateService.onLangChange.subscribe((data2: any) => {
      this.currentLang = data2 && data2.lang;
      console.log('data2', this.currentLang);
    });
  }

  getProfileData(email: string) {
    return this.getRequest(`/api/pinbox24-profile/${email}`, {});
  }

  getHttpHeaders(header: any = {}): HttpHeaders {
    console.log(header);
    const lang = localStorage.getItem('selectedLanguage');
    if (this.currentLang && this.currentLang.includes('-')) {
      this.currentLang = this.currentLang.split('-')[0];
    }
    if (this.currentLang) {
      header.lang = lang || this.currentLang;
    }

    if (localStorage.getItem('ngStorage-token')) {
      header.Authorization = localStorage.getItem('ngStorage-token');
    }
    console.log(header);
    return new HttpHeaders(header);
  }

  getRequest(getUrl: string, headerObj: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const headers = this.getHttpHeaders(headerObj);
        this.http.get(`${getUrl}`, { headers }).subscribe(
          (data: Response) => {
            this.buildBackendResponse(data, resolve, reject);
          },
          (err) => this.buildHttpError(err, reject)
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  patchRequest(getUrl: string, updatedData: any, headerObj: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (getUrl && updatedData) {
          const headers = this.getHttpHeaders(headerObj);
          this.http.patch(`${getUrl}`, updatedData, { headers }).subscribe(
            (data) => {
              this.buildBackendResponse(data, resolve, reject);
            },
            (err) => this.buildHttpError(err, reject)
          );
        } else {
          reject('Error occurred while updating the document');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  putRequest(getUrl: string, updatedData: any, headerObj: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (getUrl && updatedData) {
          const headers = this.getHttpHeaders(headerObj);
          this.http.put(`${getUrl}`, updatedData, { headers }).subscribe(
            (data) => {
              this.buildBackendResponse(data, resolve, reject);
            },
            (err) => this.buildHttpError(err, reject)
          );
        } else {
          reject('Error occurred while updating the document');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  postRequest(getUrl: string, updatedData: any, headerObj: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (getUrl && updatedData) {
          const headers = this.getHttpHeaders(headerObj);
          this.http.post(`${getUrl}`, updatedData, { headers }).subscribe(
            (data) => {
              this.buildBackendResponse(data, resolve, reject);
            },
            (err) => this.buildHttpError(err, reject)
          );
        } else {
          reject('Error occurred while updating the document');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteRequest(deleteUrl: string, headerObj: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const headers = this.getHttpHeaders(headerObj);
        this.http.delete(`${deleteUrl}`, { headers }).subscribe(
          (data: Response) => {
            if (data && data['success']) {
              resolve(data['success']);
            } else {
              reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
            }
            // this.buildBackendResponse(data, resolve, reject);
          },
          (err) => this.buildHttpError(err, reject)
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  buildBackendResponse(data: any, resolve: any, reject: any): void {
    if (data && data['success']) {
      if (data['result']) {
        resolve(data['result']);
      } else {
        reject('Response is empty. Please check the configuration');
      }
    } else {
      reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
    }
  }

  buildHttpError(err: any, reject: any): void {
    reject((err && err.error && err.error.message) || err.message || 'Error Occured while fetching the data');
  }
}
