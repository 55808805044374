import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/helpers/common.service';
import { Location } from '@angular/common';
import { bounce, slideInRight, slideOutLeft } from 'ngx-animate';
import { trigger, transition, useAnimation } from '@angular/animations';
import { ApiService } from '@app/helpers/api.service';
import { environment } from '@env/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CredentialsService } from '@app/auth';

@Component({
  selector: 'app-shared-service-detail',
  templateUrl: './shared-service-detail.component.html',
  styleUrls: ['./shared-service-detail.component.scss'],
  animations: [
    trigger('inForm', [transition('void => *', useAnimation(slideInRight))]),
    trigger('outForm', [transition('* => void', useAnimation(slideInRight))]),
    trigger('inDetail', [transition('void => *', useAnimation(slideInRight))]),
    trigger('outDetail', [transition('* => void', useAnimation(slideOutLeft))]),
  ],
})
export class SharedServiceDetailComponent implements OnInit {
  @ViewChild('contactemail') contactemail: any;

  files: any[] = [];
  serviceInfo: any;
  officeId: any;
  frontUrl = environment.pinbox24MainFront;
  formData: any = {};
  otpStatus = '';
  view = 'detail';

  formView = 'description';
  otpSent: boolean;
  phoneVerified: boolean;
  userDetail: any;
  otp: any;
  extraData: any;
  view2: string;

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private credentialsService: CredentialsService,
    private location: Location,
    private apiService: ApiService
  ) {
    this.route.queryParams.subscribe((data) => {
      this.view = data.view || 'detail';
      this.formData = JSON.parse(JSON.stringify(data));
      if (this.credentialsService && this.credentialsService.credentials && this.credentialsService.credentials.email) {
        this.formData.email = this.credentialsService.credentials.email;
      }
      console.log(this.formData, data);
    });
  }

  ngOnInit(): void {
    this.officeId = this.commonService.getOfficeId(this.route);
    if (this.officeId) {
      this.getData();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      this.serviceInfo = await this.commonService.getAboutService(this.officeId, this.route.snapshot.params.serviceId);
      this.formInitView();
      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }

  goBack() {
    console.log('go backe clicked - ');
    // this.location.back();
    this.router.navigateByUrl('/our-services');
  }

  registerCase() {
    this.view = 'form';
  }

  formInitView() {
    if (this.serviceInfo && this.serviceInfo.formShowDescription === 'Yes') {
      this.formView = 'description';
    } else {
      this.formView = 'email';
    }
  }

  async nextStep() {
    switch (this.formView) {
      case 'description':
        this.formView = 'email';
        break;
      case 'email':
        if (this.serviceInfo && this.serviceInfo.slotSelection === 'Yes') {
          this.formView = 'slotBooking';
        } else if (this.serviceInfo && this.serviceInfo.formShowPhoneVerify === 'Yes') {
          this.formView = 'phoneVerify';
        } else {
          if (!this.serviceInfo.webhookToken) {
            return alert('Webhook token not found for provder, Contact admin for this.');
          }
          await this.submit();
          this.formView = 'thanks';
        }
        break;
      case 'slotBooking':
        if (this.serviceInfo && this.serviceInfo.formShowPhoneVerify === 'Yes') {
          this.formView = 'phoneVerify';
        } else {
          if (!this.serviceInfo.webhookToken) {
            return alert('Webhook token not found for provder, Contact admin for this.');
          }
          await this.submit();
          this.formView = 'thanks';
        }
        break;
      case 'phoneVerify':
        this.formView = 'otpView';
        break;
      case 'otpView':
        if (!this.serviceInfo.webhookToken) {
          return alert('Webhook token not found for provder, Contact admin for this.');
        }
        await this.submit();
        this.formView = 'thanks';
        break;
    }
  }

  previousStep() {
    switch (this.formView) {
      case 'description':
        break;
      case 'email':
        if (this.serviceInfo && this.serviceInfo.formShowDescription === 'Yes') {
          this.formView = 'description';
        }
        break;
      case 'otpView':
        this.formView = 'phoneVerify';
        break;
      case 'phoneVerify':
        if (this.serviceInfo && this.serviceInfo.slotSelection === 'Yes') {
          this.formView = 'slotBooking';
        } else if (this.serviceInfo && this.serviceInfo.formShowPhoneVerify === 'Yes') {
          this.formView = 'email';
        }
        break;
      case 'slotBooking':
        this.formView = 'email';
        break;
    }
  }

  async sendOtp() {
    if (this.formData.phoneNo) {
      this.commonService.onLoaderChange.next(true);
      try {
        await this.apiService.postRequest(`/auth/send-otp`, { phoneNo: this.formData.phoneNo, lang: 'en' });
        this.otpSent = true;
        this.otpStatus = 'OTP_SENDED';
        this.commonService.onLoaderChange.next(false);
        this.nextStep();
      } catch (error) {
        this.commonService.onLoaderChange.next(false);
      }
    }
  }

  async verifyOtp() {
    if (this.formData.phoneNo && this.otp) {
      try {
        this.commonService.onLoaderChange.next(true);
        await this.apiService.postRequest(`/auth/verify-otp`, { phoneNo: this.formData.phoneNo, otp: this.otp });
        this.otpStatus = 'OTP_VERIFIED';
        this.phoneVerified = true;
        this.userDetail = this.userDetail || {};
        this.userDetail.phoneNoVerified = true;
        this.commonService.onLoaderChange.next(false);
        this.nextStep();
      } catch (error) {
        this.otpStatus = 'INVALID_OTP';
        this.commonService.onLoaderChange.next(false);
      }
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async submit() {
    try {
      if (this.formData.email) {
        this.view2 = 'table';
        try {
          this.formData.nextServiceId = this.serviceInfo._id;
          this.formData.subType = this.serviceInfo.serviceType;
          this.formData.description = `global.new_service_desc - ${this.serviceInfo.serviceName}`;
          this.formData.mainServiceName = this.serviceInfo.serviceName;
          this.commonService.onLoaderChange.next(true);
          this.extraData = await this.apiService.postRequest(`/webook/start-shared-service`, this.formData, {
            'webhook-token': this.serviceInfo.webhookToken,
          });
          this.commonService.onLoaderChange.next(false);
          // alert("Service Started");
          this.view2 = 'success';
        } catch (error) {
          this.commonService.onLoaderChange.next(false);
          this.view2 = 'error';
          this.extraData = error;
          // alert(error);
        }
      } else {
        alert('Email is required');
      }
    } catch (error) {
      this.view2 = 'error';
      this.formView = 'thanks';
    }
  }

  open(view: any) {
    if (this.formView === 'thanks') {
      this.formData = {};
      this.formInitView();
      this.router.navigateByUrl('/our-services');

      // this.view='detail'
      return true;
    }
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.closed.subscribe((data) => {
      if (data === 'Continue') {
        this.formData = {};
        this.formInitView();
        switch (view) {
          case 'home':
            this.router.navigateByUrl('/');
            break;
          case 'services':
            this.router.navigateByUrl('/our-services');
            break;
          case 'service_detail':
            this.view = 'detail';
        }
        // this.router.navigateByUrl();
      }
    });
  }

  updateData(eve: any) {
    this.serviceInfo = Object.assign(this.serviceInfo, eve);
  }
}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Alert</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'externalSharedService.you_will_lose_data' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        {{ 'externalSharedService.cancel' | translate }}
      </button>
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Continue')">
        {{ 'externalSharedService.continue' | translate }}
      </button>
    </div>
  `,
})
export class ConfirmDialogComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
