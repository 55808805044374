<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Services</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <section class="service-categories text-xs-center" *ngIf="showLocation">
    <div class="container" fxLayoutAlign="space-between center" fxLayout="column">
      <div style="width: 100%">
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-2 col-form-label" style="color: white">{{
            'externalSharedService.search_location' | translate
          }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              [(ngModel)]="searchText"
              id="mapSearch"
              class="form-control"
              (keydown.enter)="$event.preventDefault()"
              [placeholder]="'externalSharedService.searc_location' | translate"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              #search
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-2 col-form-label" style="color: white">{{
            'externalSharedService.range' | translate
          }}</label>
          <div class="col-sm-10">
            <div fxLayoutAlign="start center" fxLayout="row">
              <button
                *ngFor="let item of rangeArray"
                type="button"
                (click)="range = item.range; selectedRange = item"
                class="btn btn-light mr-2 ml-2"
              >
                {{ item.title | translate }}
              </button>
            </div>
            <!-- <input [placeholder]="'externalSharedService.range' | translate" type="number" [(ngModel)]="range" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
          </div>
        </div>

        <div fxLayout="row">
          <span fxFlex></span>
          <div class="ml-2">
            <button (click)="activeModal.dismiss('Cross click')" type="button" class="btn btn-primary mr-1">
              {{ 'externalSharedService.search' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
