import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { environment } from '@env/environment';
import jwt_decode from 'jwt-decode';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as md5 from 'md5';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-event-booking-panel',
  templateUrl: './event-booking-panel.component.html',
  styleUrls: ['./event-booking-panel.component.scss'],
})
export class EventBookingPanelComponent implements OnInit {
  allData: any;
  view: any = 'optionSelect';
  loading: boolean;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Qatar];
  loadingText = '';
  registerForm = [
    {
      type: 'input',
      title: 'externalSharedService.first_name',
      key: 'firstName',
    },
    {
      type: 'input',
      title: 'externalSharedService.last_name',
      key: 'lastName',
    },

    {
      type: 'input',
      title: 'externalSharedService.email',
      key: 'email',
    },
    {
      type: 'input',
      title: 'externalSharedService.FB_profile',
      key: 'fbProfile',
    },
    {
      type: 'dropdown',
      title: 'externalSharedService.gender',
      key: 'gender',
      options: [
        {
          title: 'externalSharedService.man',
          key: 'man',
        },
        {
          title: 'externalSharedService.woman',
          key: 'woman',
        },
      ],
    },
    {
      type: 'input',
      title: 'externalSharedService.phone_no',
      key: 'phoneNumber',
    },
    {
      type: 'dropdown',
      title: 'externalSharedService.meals',
      key: 'meals',
      options: [
        {
          title: 'externalSharedService.vegetarian',
          key: 'vegetarian',
        },
        {
          title: 'externalSharedService.vegan',
          key: 'vegan',
        },
        {
          title: 'externalSharedService.no_requirements',
          key: 'no_requirements',
        },
      ],
    },
    {
      type: 'dropdown',
      title: 'externalSharedService.country',
      key: 'country',
      options: [],
    },
    {
      type: 'input',
      title: 'externalSharedService.city',
      key: 'city',
    },
  ];
  registerData: any = {
    user1: {
      meals: 'no_requirements',
      gender: 'man',
    },
  };
  user = 1;
  formData: any = {};
  coupleApply = false;
  headingInfo = 'externalSharedService.select_your_plan';
  reqBody: any = {
    creditAmount: 0,
    amountToPay: 0,
    multiple: 1,
    paymentSource: 'online',
    paymentMethod: 'przelewy24',
    tax: 23,
    totalAmountToPay: 0,
    discountPromoCredit: 0,
    currency: '$',
    currencyId: 3,
  };
  loginData: any = {
    appType: 'externalPage',
  };

  signupData: any = {};
  accountExists: boolean;
  caseDoc: any;
  eventData: any;
  orderDetail: any;
  singeForm: FormGroup;
  countryList: any;
  formReady: boolean;
  paymentSelected: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private apiService: ApiService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    console.log('allData - ', this.allData);
    const formConfig: any = {
      email: ['', [Validators.required, Validators.email]],
    };
    this.allData.resourceData.eventConfig = this.allData.resourceData.eventConfig || {};
    // this.allData.resourceData.eventConfig
    let temp: any = [];
    if (this.allData.resourceData.eventConfig && Object.keys(this.allData.resourceData.eventConfig).length) {
      this.registerForm.forEach((ele: any, i) => {
        if (this.allData.resourceData.eventConfig[ele.key] && this.allData.resourceData.eventConfig[ele.key].visible) {
          ele.sort = this.allData.resourceData.eventConfig[ele.key].sort;
          temp.push(ele);
          if (this.allData.resourceData.eventConfig[ele.key].required && ele.key !== 'email') {
            formConfig[ele.key] = [
              '',
              ele.key === 'email' ? [Validators.required, Validators.email] : [Validators.required],
            ];
          }
        }
      });
      this.registerForm = temp;
    } else {
      this.registerForm.forEach((ele: any, i) => {
        if (!this.allData.resourceData.eventConfig[ele.key] && ele.key !== 'email') {
          formConfig[ele.key] = ['', ele.key === 'email' ? [Validators.email] : []];
        }
      });
    }
    this.singeForm = this._formBuilder.group(formConfig);
    this.singeForm.patchValue({
      meals: 'no_requirements',
      gender: 'man',
    });

    this.formReady = true;

    this.registerForm = this.commonService.transformSort(this.registerForm, 'sort');

    this.getCountry();
  }

  async getCountry() {
    try {
      this.countryList = await this.apiService.getRequest('/public/country-list');
      this.registerForm.forEach((ele) => {
        if (ele.key === 'country') {
          ele.options = this.countryList.map((item: any) => ({ title: item && item.name, key: item && item.name }));
        }
      });
    } catch (error) {
      console.log('err - ', error);
    }
  }

  async continueV2() {
    try {
      if (this.allData.type !== 'resource') {
        this.loading = true;
        this.eventData = await this.apiService.postRequest(
          `/external-page/calendar/${this.allData.resourceData._id}/${this.allData.serviceData._id}`,
          {
            date: this.allData.selectedDate,
            startSlot: this.allData.slot.formatted,
            endSlot: this.allData.slot.end,
          }
        );
        if (localStorage.getItem('ngStorage-token')) {
          const decodedToken: any = jwt_decode(localStorage.getItem('ngStorage-token'));
          this.formData = this.formData || {};
          this.formData.email = decodedToken.login;
          // await this.submit();
          // this.view = 'payment';
        }
      }
      this.view = 'email';
      this.loading = false;
      // this.activeModal.close({
      //   operation: "continue",
      //   eventId: data._id
      // });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  getType() {
    if (this.coupleApply) {
      return 'couple';
    } else {
      return 'single';
    }
  }

  async continue() {
    console.log('singeForm - ', this.singeForm.valid);
    switch (this.view) {
      case 'summary':
        if (this.eventData) {
          this.view = 'email';
        } else {
          // this.view = 'payment';
          if (this.allData.serviceData && this.allData.serviceData.paymentRequirements === 'pay-not-required') {
            await this.continueV2();
            this.activeModal.close({
              operation: 'continue',
              eventId: this.eventData && this.eventData._id,
            });
          } else {
            await this.continueV2();
            this.headingInfo = 'externalSharedService.enter_email';
          }
        }
        break;
      case 'optionSelect':
        this.view = 'person';
        this.headingInfo = 'externalSharedService.enter_detail_' + this.getType();
        // if (this.coupleApply && this.user === 1) {
        //   this.user = 2;
        //   this.registerData['user2'] = {};
        //   this.headingInfo = "externalSharedService.partner_detail";
        // } else {
        //   this.headingInfo = "externalSharedService.enter_detail";
        //   this.view = "payment";
        // }
        this.loading = false;
        break;
      case 'person':
        if (this.user === 1) {
          this.registerData['user1'] = this.singeForm.value;
        }
        if (this.coupleApply && this.user === 1) {
          this.view = 'person';
          this.user = 2;
          this.registerData['user1'] = this.singeForm.value;
          this.singeForm.reset();
          this.singeForm.patchValue({
            meals: 'no_requirements',
            gender: 'woman',
          });
          this.headingInfo = 'externalSharedService.partner_detail';
        } else {
          this.headingInfo = 'externalSharedService.select_payment_option_' + this.getType();
          this.paymentSelected = [];
          this.view = 'paymentSelection';
        }
        this.loading = false;
        break;
      case 'paymentSelection':
        this.loading = true;
        console.log('this.formData ---- ', this.registerData, this.singeForm.value);
        this.formData.email = this.registerData['user1'].email;
        this.registerData['user2'] = this.singeForm.value;
        this.loadingText = 'externalSharedService.loading_please_wait';
        await this.afterLogin();
        this.view = 'payment';

        this.loading = false;
        break;
      case 'password':
      case 'loginInfo':
      case 'signupInfo':
        this.loading = true;
        if (this.allData.type === 'resource') {
          this.view = 'optionSelect';
        } else {
          this.view = 'payment';
        }
        await this.afterLogin();
        this.headingInfo = 'externalSharedService.payment_heading_' + this.getType();
        this.loading = false;
        break;
    }
  }

  async getOrderDetail(serviceId: string) {
    try {
      const temp = await this.apiService.getRequest(`/external-page/order-detail/${serviceId}`);
      this.orderDetail = (temp && temp.orderDetail) || {};
      let total = 0;
      this.orderDetail.selectedVariant.forEach((ele: any) => {
        total = total + ele.price;
      });
      this.orderDetail.totalOrder = total;
    } catch (error) {
      alert(error);
    }
  }

  async pay() {
    console.log('pay now');
    try {
      this.loading = true;
      this.reqBody.creditAmount = this.allData.resourceData.price;
      this.reqBody.amountToPay = this.allData.resourceData.price;
      const data: any = await this.apiService.postRequest(
        `/external-page/payment/pay`,
        {
          allContUrl: `${window.location.origin}${window.location.pathname}?status=success`,
          // allContUrl: `${window.location.origin}/payment/success`,
          paymentType: 'przelewy24',
          serviceId: this.caseDoc && this.caseDoc.serviceDoc && this.caseDoc.serviceDoc._id,
          ...this.reqBody,
          email: this.formData.email,
          description: `Making payment for booking`,
          currencyCode: 'PLN',
          products: [],
        },
        { officeId: '5cb9648308e32961acf1ff1f' }
      );
      console.log(data);
      if (data) {
        window.location.href = data;
      }
      this.loading = false;
    } catch (error) {
      this.commonService.showInfo(error, 'Error', 'error');
      this.loading = false;
      console.log('making payment', error);
    }
  }

  async submit() {
    try {
      console.log(this.formData);
      if (this.formData.email) {
        try {
          this.formData.nextServiceId = this.allData.serviceData._id;
          this.formData.subType = this.allData.serviceData.serviceType;
          this.formData.description = `global.new_service_desc - ${this.allData.serviceData.serviceName}`;
          this.formData.mainServiceName = this.allData.serviceData.serviceName;
          this.formData.booking = true;
          this.allData.resourceData.paymentOptions = this.allData.resourceData.paymentOptions || [];
          this.formData.selectedVariant = this.allData.resourceData.paymentOptions
            .filter((ele: any) => ele && ele.selected)
            .map((ele: any) => {
              return {
                name: ele.name,
                productCode: ele.name,
                singlePrice: parseFloat(ele.price),
                qty: this.coupleApply ? 2 : 1,
                price: parseFloat(ele.price) * (this.coupleApply ? 2 : 1),
                currency: this.allData.resourceData.currency,
              };
            });
          this.formData.eventId = this.eventData && this.eventData._id;
          this.caseDoc = await this.apiService.postRequest(
            `/webook/start-shared-service`,
            { ...this.formData, ...this.registerData },
            { 'webhook-token': this.allData.webhookToken }
          );
          console.log('this.caseDoc - ', this.caseDoc);
        } catch (error) {
          alert(error);
        }
      }
    } catch (error) {
      alert(error);
    }
  }

  logIn(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      const header = new HttpHeaders();
      header.append('Content-Type', 'application/json');
      let url = `${environment.serverUrl}/auth`;
      if (user && user.provider === 'GOOGLE') {
        url = `${environment.serverUrl}/google-auth`;
      }
      return this.http.post(url, user, { headers: header }).subscribe(
        async (data: any) => {
          this.loading = false;
          if (data['success'] && data['result']) {
            data = data['result'] || {};
            localStorage.setItem('ngStorage-token', data.token);
            await this.submit();
            this.continue();
            resolve(true);
          } else {
            reject(data);
          }
        },
        (err) => {
          this.loading = false;
          reject(err);
        }
      );
    });
  }

  signUp(user: any) {
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/json');
    return this.http.post(environment.serverUrl + '/registration', user, {
      headers: header,
    });
  }

  signup(): void {
    this.signUp(this.formData).subscribe(
      (res: any) => {
        if (!res['success']) {
          this.commonService.showInfo('Register Success, Login now.', 'Success', 'success');
          this.view = 'email';
          // this.continue();
        } else {
          this.commonService.showInfo(res['error'], 'Error', 'error');
        }
      },
      (error: any) => {
        this.commonService.showInfo(error, 'Error', 'error');
      }
    );
  }

  login() {
    let user = {
      login: this.formData.email,
      password: md5(this.formData.password),
      appType: 'external',
    };
    this.logIn(user);
  }

  async afterLogin() {
    await this.submit();
    await this.getOrderDetail(this.caseDoc && this.caseDoc.serviceDoc && this.caseDoc.serviceDoc._id);
  }

  async checkAndDecide() {
    try {
      this.accountExists = false;
      this.loading = true;
      const data = await this.apiService.getRequest(`/pinbox24-profile/${this.formData.email}`);
      if (data && data.emailVerified) {
        this.accountExists = true;
        this.headingInfo = 'externalSharedService.already_have_account';
        this.view = 'loginInfo';
      } else {
        this.headingInfo = 'externalSharedService.we_have_created_account';
        this.view = 'signupInfo';
      }

      this.loading = false;
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  async cancelClick() {
    if (this.orderDetail) {
      this.loading = true;
      try {
        await this.apiService.getRequest(`external-page/cancel-order/${this.caseDoc._id}`);
      } catch (error) {
        console.log('error - ', error);
        this.loading = false;
      }
      this.loading = false;
    }
    this.activeModal.close();
  }

  tooglePayment(item: any) {
    item.selected = !item.selected;
    if (this.paymentSelected.includes(item.name)) {
      this.paymentSelected.splice(this.paymentSelected.indexOf(item.name), 1);
    } else {
      this.paymentSelected.push(item.name);
    }
  }
}
