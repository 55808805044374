import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/helpers/common.service';

@Component({
  selector: 'app-s-contact',
  templateUrl: './s-contact.component.html',
  styleUrls: ['./s-contact.component.scss'],
})
export class SContactComponent implements OnInit {
  providerInfo: any;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      this.providerInfo = await this.commonService.getHtmlTemplate({ route: 'service_contact' });
      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }
}
