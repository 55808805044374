import { NgModule } from '@angular/core';
import { HtmlWithStylePipe } from './htmlWithStyle.pipe';
import { FullTextSearchPipe } from './textSearch.pipe';
import { TextTranslatePipe } from './textTranslate.pipe';

@NgModule({
  declarations: [TextTranslatePipe, HtmlWithStylePipe, FullTextSearchPipe],
  imports: [],
  exports: [TextTranslatePipe, HtmlWithStylePipe, FullTextSearchPipe],
})
export class GlobalPipesModule {}
