import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService, CredentialsService } from '@app/auth';
import { CommonService } from '@app/helpers/common.service';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;
  officeId: any;

  credentials: any;
  public currentLang = 'en';
  public languages = [
    {
      id: 'en',
      title: 'English',
      flag: 'us',
    },
    {
      id: 'pl',
      title: 'Polish',
      flag: 'pl',
    },
  ];

  headerRoute: any = [];
  hostName: any;
  view = 'provider';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    public commonService: CommonService,
    private headerService: HeaderService,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.hostName = window.location.hostname;
    // this.officeId =  this.route.snapshot.params.officeId || '60409b2d82c7f0002547857c';
    const temp: any = window.location.host;
    const pathname: any = window.location.pathname;

    const t1 = temp.substr(0, temp.indexOf('.'));
    const t2 = pathname.split('/')[1];
    const config = this.headerService.getMenuOption();
    this.headerRoute = config.headerRoute;
    this.view = config.view;
    if (t1) {
      this.officeId = t1;
    } else if (t2) {
      this.officeId = t2;
    } else {
      this.officeId = '60409b2d82c7f0002547857c';
    }
    this.headerService.onMenuChange.subscribe((data) => {
      this.headerRoute = data;
    });
    this.credentials = this.credentialsService.getAllInfo();
    console.log('header office', this.credentials);
    // this.getActiveLanguages();
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  // logout() {
  //   this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  // }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.email : null;
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  logout() {
    localStorage.clear();
    this.deleteAllCookies();
    window.location.reload();
  }
}
