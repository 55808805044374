import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GdprInfoComponent } from './gdpr-info/gdpr-info.component';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { GdprDescriptionComponent } from './gdpr-description/gdpr-description.component';
import { GdprDetailsComponent } from './gdpr-details/gdpr-details.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'gdpr', component: GdprDescriptionComponent, data: { title: marker('GDPR Description') } },
    { path: ':officeId/gdpr', component: GdprDescriptionComponent, data: { title: marker('GDPR Description') } },
    { path: 'gdpr-details', component: GdprDetailsComponent, data: { title: marker('GDPR Details') } },
    { path: ':officeId/gdpr-details', component: GdprDetailsComponent, data: { title: marker('GDPR Details') } },
    { path: 'gdpr-info', component: GdprInfoComponent, data: { title: marker('GDPR Info') } },
    { path: ':officeId/gdpr-info', component: GdprInfoComponent, data: { title: marker('GDPR') } },
  ]),
];

@NgModule({
  declarations: [GdprInfoComponent, GdprDescriptionComponent, GdprDetailsComponent],
  imports: [CommonModule, TranslateModule, GlobalPipesModule, FormsModule, RouterModule.forChild(routes)],
})
export class GdprInfoModule {}
