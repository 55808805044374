import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/helpers/common.service';

@Component({
  selector: 'app-office-contact',
  templateUrl: './office-contact.component.html',
  styleUrls: ['./office-contact.component.scss'],
})
export class OfficeContactComponent implements OnInit {
  providerInfo: any;
  officeId: any;

  constructor(private commonService: CommonService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.officeId = this.commonService.getOfficeId(this.route);
    if (this.officeId) {
      this.getData();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      this.providerInfo = await this.commonService.getAboutOffice(this.officeId, { route: 'contact' });
      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }
}
