import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';

@Component({
  selector: 'app-specialist-form',
  templateUrl: './specialist-form.component.html',
  styleUrls: ['./specialist-form.component.scss'],
})
export class SpecialistFormComponent implements OnInit {
  formData: any = {};
  constructor(private commonService: CommonService, private apiService: ApiService) {}

  ngOnInit(): void {}

  register() {
    try {
      const reqData = {
        serviceName: 'specialist',
        serviceCategory: 'specialist',
        providerOfficeName: 'Eat',
        processName: 'specialist' || '',
      };
      const temp = {
        webhookToken: '9d954d042c519831e8a48dd227b8cf884009154dc479bb60e2474c665b63159f',
        type: 'shared-services',
        description: `global.new_service_desc`,
        mainServiceName: `${'specialist'}`,
        email: this.formData.email,
        subType: 'specialist',
        nextServiceId: '60e683cb5392783c6496582d',
        // "responsibleOfficeId": this.officeDoc._id,
        tmplServiceId: '60e683cb5392783c6496582d',
      };
      console.log('data ', temp);
      this.commonService.onLoaderChange.next(true);
      this.apiService
        .postRequest(`api/webook/start-shared-service`, temp, {
          'webhook-token': '9d954d042c519831e8a48dd227b8cf884009154dc479bb60e2474c665b63159f',
        })
        .then((retData) => {
          this.commonService.onLoaderChange.next(false);
          // this.getData();
          // this.router.navigate([`/shared-service/admin/admin-services/${retData.regId}/${retData._id}`]);
          // this.moreDetails(retData.serviceDoc);
          // this.loading = false;
        })
        .catch((err) => {
          this.commonService.onLoaderChange.next(false);

          // this.loading = false;
        });
    } catch (error) {
      this.commonService.onLoaderChange.next(false);

      console.error(error);
    }
  }
}
