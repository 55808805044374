<div
  *ngIf="commonService.showMenu"
  style="width: 100%; height: 30px; background: #f8f9fa; display: flex; justify-content: flex-end; padding: 0px 12px"
  fxLayout="row"
>
  <ng-container *ngIf="!username">
    <p>Pinbox24</p>
  </ng-container>
  <ng-container *ngIf="username">
    <div ngbDropdown class="d-inline-block">
      <a id="dropdownBasic1" ngbDropdownToggle>{{ view === 'provider' ? 'Provider Panel' : 'Shared service Panel' }}</a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <a
          *ngIf="credentials && credentials.officeId"
          ngbDropdownItem
          [href]="'https://v41-stage.dev.fullpentest.com/login?host=' + hostName"
          >Pinbox24 Panel</a
        >
        <a ngbDropdownItem href="https://w4.pinbox24.com/cp/dashboard">Client Panel</a>
        <a *ngIf="view === 'provider'" ngbDropdownItem href="https://w4.pinbox24.com/cp/dashboard"
          >Shared service panel</a
        >
        <a ngbDropdownItem href="https://w4.pinbox24.com/specialist/login">Specialist Login</a>
      </div>
    </div>
  </ng-container>
  <span fxFlex></span>
  <div fxLayout="row">
    <ng-container *ngIf="!username">
      <a [href]="'https://v41-stage.dev.fullpentest.com/cp-login?host=' + hostName">Login</a>
    </ng-container>
    <ng-container *ngIf="username">
      <p>{{ username }}</p>
      <p class="ml-2 mr-2">|</p>

      <a (click)="logout()" style="cursor: pointer">Logout</a>
    </ng-container>
  </div>
</div>
<header *ngIf="commonService.showMenu">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="d-flex flex-column flex-sm-row">
      <!-- <div>
        <a style="font-size: 16px;" class="navbar-brand" href="https://pinbox24.com" translate>Pinbox24.com
        </a>
      </div> -->
      <div style="color: white; margin: auto">
        <span>{{ (commonService && commonService.officeName) || 'Pinbox24.com' }} </span>
      </div>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      aria-controls="navbar-menu"
      aria-label="Toggle navigation"
      (click)="toggleMenu()"
      [attr.aria-expanded]="!menuHidden"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav ml-auto">
        <div class="navbar-nav">
          <a
            class="nav-item nav-link text-uppercase"
            *ngFor="let item of headerRoute"
            [routerLink]="item.route"
            routerLinkActive="active"
          >
            <i [class]="item.icon"></i>
            <span translate>{{ item.title | translate }}</span>
          </a>
        </div>
        <app-language-selector inNavbar="true" menuClass="dropdown-menu dropdown-menu-right"></app-language-selector>
      </div>
    </div>
  </nav>
</header>
