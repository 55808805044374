import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';

@Component({
  selector: 'app-s-services',
  templateUrl: './s-services.component.html',
  styleUrls: ['./s-services.component.scss'],
})
export class SServicesComponent implements OnInit {
  providerInfo: any;
  serviceList: any = [];
  query = '';

  constructor(private commonService: CommonService, private apiService: ApiService) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      this.providerInfo = await this.commonService.getHtmlTemplate({ route: 'service_service' });
      this.serviceList = await this.apiService.getRequest(`/external-page/all-services/client`);

      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }
}
