import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeRangePickerComponent } from './date-time-range-picker.component';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {
  DlDateTimeCoreModule,
  DlDateTimeDateModule,
  DlDateTimeInputModule,
  DlDateTimeMomentModule,
  DlDateTimePickerModule,
} from 'angular-bootstrap-datetimepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTimepickerConfig, NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [DateTimeRangePickerComponent],
  imports: [
    CommonModule,
    FlexModule,
    TranslateModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    DlDateTimeCoreModule,
    DlDateTimeInputModule,
    DlDateTimeMomentModule,
    FormsModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    NgbTimepickerModule,
  ],
  exports: [DateTimeRangePickerComponent],
  providers: [NgbTimepickerConfig],
})
export class DateTimeRangePickerModule {}
