<!--PEN HEADER-->
<header class="header">
  <h1 class="header__title">Multi Step Form with animations</h1>
  <div class="header__btns btns">
    <a
      class="header__btn btn"
      href="https://github.com/nat-davydova/multisteps-form"
      title="Check on Github"
      target="_blank"
      >Check on Github</a
    >
  </div>
</header>
<!--PEN CONTENT     -->
<div class="content">
  <!--content inner-->
  <div class="content__inner">
    <div class="container">
      <!--content title-->
      <h2 class="content__title content__title--m-sm">Pick animation type</h2>
      <!--animations form-->
      <form class="pick-animation my-4">
        <div class="form-row">
          <div class="col-5 m-auto">
            <select class="pick-animation__select form-control">
              <option value="scaleIn" selected="selected">ScaleIn</option>
              <option value="scaleOut">ScaleOut</option>
              <option value="slideHorz">SlideHorz</option>
              <option value="slideVert">SlideVert</option>
              <option value="fadeIn">FadeIn</option>
            </select>
          </div>
        </div>
      </form>
      <!--content title-->
      <h2 class="content__title">Click on steps or 'Prev' and 'Next' buttons</h2>
    </div>
    <div class="container overflow-hidden">
      <!--multisteps-form-->
      <div class="multisteps-form">
        <!--progress bar-->
        <div class="row">
          <div class="col-12 col-lg-8 ml-auto mr-auto mb-4">
            <div class="multisteps-form__progress">
              <button class="multisteps-form__progress-btn js-active" type="button" title="User Info">User Info</button>
              <button class="multisteps-form__progress-btn" type="button" title="Address">Address</button>
              <button class="multisteps-form__progress-btn" type="button" title="Order Info">Order Info</button>
              <button class="multisteps-form__progress-btn" type="button" title="Comments">Comments</button>
            </div>
          </div>
        </div>
        <!--form panels-->
        <div class="row">
          <div class="col-12 col-lg-8 m-auto">
            <form class="multisteps-form__form">
              <!--single form panel-->
              <h1>hello</h1>
              <div class="multisteps-form__panel shadow p-4 rounded bg-white js-active" data-animation="scaleIn">
                <h3 class="multisteps-form__title">Your User Info</h3>
                <div class="multisteps-form__content">
                  <div class="form-row mt-4">
                    <div class="col-12 col-sm-6">
                      <input class="multisteps-form__input form-control" type="text" placeholder="First Name" />
                    </div>
                    <div class="col-12 col-sm-6 mt-4 mt-sm-0">
                      <input class="multisteps-form__input form-control" type="text" placeholder="Last Name" />
                    </div>
                  </div>
                  <div class="form-row mt-4">
                    <div class="col-12 col-sm-6">
                      <input class="multisteps-form__input form-control" type="text" placeholder="Login" />
                    </div>
                    <div class="col-12 col-sm-6 mt-4 mt-sm-0">
                      <input class="multisteps-form__input form-control" type="email" placeholder="Email" />
                    </div>
                  </div>
                  <div class="form-row mt-4">
                    <div class="col-12 col-sm-6">
                      <input class="multisteps-form__input form-control" type="password" placeholder="Password" />
                    </div>
                    <div class="col-12 col-sm-6 mt-4 mt-sm-0">
                      <input
                        class="multisteps-form__input form-control"
                        type="password"
                        placeholder="Repeat Password"
                      />
                    </div>
                  </div>
                  <div class="button-row d-flex mt-4">
                    <button class="btn btn-primary ml-auto js-btn-next" type="button" title="Next">Next</button>
                  </div>
                </div>
              </div>
              <!--single form panel-->
              <div class="multisteps-form__panel shadow p-4 rounded bg-white" data-animation="scaleIn">
                <h3 class="multisteps-form__title">Your Address</h3>
                <div class="multisteps-form__content">
                  <div class="form-row mt-4">
                    <div class="col">
                      <input class="multisteps-form__input form-control" type="text" placeholder="Address 1" />
                    </div>
                  </div>
                  <div class="form-row mt-4">
                    <div class="col">
                      <input class="multisteps-form__input form-control" type="text" placeholder="Address 2" />
                    </div>
                  </div>
                  <div class="form-row mt-4">
                    <div class="col-12 col-sm-6">
                      <input class="multisteps-form__input form-control" type="text" placeholder="City" />
                    </div>
                    <div class="col-6 col-sm-3 mt-4 mt-sm-0">
                      <select class="multisteps-form__select form-control">
                        <option selected="selected">State...</option>
                        <option>...</option>
                      </select>
                    </div>
                    <div class="col-6 col-sm-3 mt-4 mt-sm-0">
                      <input class="multisteps-form__input form-control" type="text" placeholder="Zip" />
                    </div>
                  </div>
                  <div class="button-row d-flex mt-4">
                    <button class="btn btn-primary js-btn-prev" type="button" title="Prev">Prev</button>
                    <button class="btn btn-primary ml-auto js-btn-next" type="button" title="Next">Next</button>
                  </div>
                </div>
              </div>
              <!--single form panel-->
              <div class="multisteps-form__panel shadow p-4 rounded bg-white" data-animation="scaleIn">
                <h3 class="multisteps-form__title">Your Order Info</h3>
                <div class="multisteps-form__content">
                  <div class="row">
                    <div class="col-12 col-md-6 mt-4">
                      <div class="card shadow-sm">
                        <div class="card-body">
                          <h5 class="card-title">Item Title</h5>
                          <p class="card-text">Small and nice item description</p>
                          <a class="btn btn-primary" href="#" title="Item Link">Item Link</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mt-4">
                      <div class="card shadow-sm">
                        <div class="card-body">
                          <h5 class="card-title">Item Title</h5>
                          <p class="card-text">Small and nice item description</p>
                          <a class="btn btn-primary" href="#" title="Item Link">Item Link</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="button-row d-flex mt-4 col-12">
                      <button class="btn btn-primary js-btn-prev" type="button" title="Prev">Prev</button>
                      <button class="btn btn-primary ml-auto js-btn-next" type="button" title="Next">Next</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--single form panel-->
              <div class="multisteps-form__panel shadow p-4 rounded bg-white" data-animation="scaleIn">
                <h3 class="multisteps-form__title">Additional Comments</h3>
                <div class="multisteps-form__content">
                  <div class="form-row mt-4">
                    <textarea
                      class="multisteps-form__textarea form-control"
                      placeholder="Additional Comments and Requirements"
                    ></textarea>
                  </div>
                  <div class="button-row d-flex mt-4">
                    <button class="btn btn-primary js-btn-prev" type="button" title="Prev">Prev</button>
                    <button class="btn btn-success ml-auto" type="button" title="Send">Send</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<script>
  //DOM elements
  const DOMstrings = {
    stepsBtnClass: 'multisteps-form__progress-btn',
    stepsBtns: document.querySelectorAll(`.multisteps-form__progress-btn`),
    stepsBar: document.querySelector('.multisteps-form__progress'),
    stepsForm: document.querySelector('.multisteps-form__form'),
    stepsFormTextareas: document.querySelectorAll('.multisteps-form__textarea'),
    stepFormPanelClass: 'multisteps-form__panel',
    stepFormPanels: document.querySelectorAll('.multisteps-form__panel'),
    stepPrevBtnClass: 'js-btn-prev',
    stepNextBtnClass: 'js-btn-next',
  };

  //remove class from a set of items
  const removeClasses = (elemSet, className) => {
    elemSet.forEach((elem) => {
      elem.classList.remove(className);
    });
  };

  //return exect parent node of the element
  const findParent = (elem, parentClass) => {
    let currentNode = elem;

    while (!currentNode.classList.contains(parentClass)) {
      currentNode = currentNode.parentNode;
    }

    return currentNode;
  };

  //get active button step number
  const getActiveStep = (elem) => {
    return Array.from(DOMstrings.stepsBtns).indexOf(elem);
  };

  //set all steps before clicked (and clicked too) to active
  const setActiveStep = (activeStepNum) => {
    //remove active state from all the state
    removeClasses(DOMstrings.stepsBtns, 'js-active');

    //set picked items to active
    DOMstrings.stepsBtns.forEach((elem, index) => {
      if (index <= activeStepNum) {
        elem.classList.add('js-active');
      }
    });
  };

  //get active panel
  const getActivePanel = () => {
    let activePanel;

    DOMstrings.stepFormPanels.forEach((elem) => {
      if (elem.classList.contains('js-active')) {
        activePanel = elem;
      }
    });

    return activePanel;
  };

  //open active panel (and close unactive panels)
  const setActivePanel = (activePanelNum) => {
    //remove active class from all the panels
    removeClasses(DOMstrings.stepFormPanels, 'js-active');

    //show active panel
    DOMstrings.stepFormPanels.forEach((elem, index) => {
      if (index === activePanelNum) {
        elem.classList.add('js-active');

        setFormHeight(elem);
      }
    });
  };

  //set form height equal to current panel height
  const formHeight = (activePanel) => {
    const activePanelHeight = activePanel.offsetHeight;

    DOMstrings.stepsForm.style.height = `${activePanelHeight}px`;
  };

  const setFormHeight = () => {
    const activePanel = getActivePanel();

    formHeight(activePanel);
  };

  //STEPS BAR CLICK FUNCTION
  DOMstrings.stepsBar.addEventListener('click', (e) => {
    //check if click target is a step button
    const eventTarget = e.target;

    if (!eventTarget.classList.contains(`${DOMstrings.stepsBtnClass}`)) {
      return;
    }

    //get active button step number
    const activeStep = getActiveStep(eventTarget);

    //set all steps before clicked (and clicked too) to active
    setActiveStep(activeStep);

    //open active panel
    setActivePanel(activeStep);
  });

  //PREV/NEXT BTNS CLICK
  DOMstrings.stepsForm.addEventListener('click', (e) => {
    const eventTarget = e.target;

    //check if we clicked on `PREV` or NEXT` buttons
    if (
      !(
        eventTarget.classList.contains(`${DOMstrings.stepPrevBtnClass}`) ||
        eventTarget.classList.contains(`${DOMstrings.stepNextBtnClass}`)
      )
    ) {
      return;
    }

    //find active panel
    const activePanel = findParent(eventTarget, `${DOMstrings.stepFormPanelClass}`);

    let activePanelNum = Array.from(DOMstrings.stepFormPanels).indexOf(activePanel);

    //set active step and active panel onclick
    if (eventTarget.classList.contains(`${DOMstrings.stepPrevBtnClass}`)) {
      activePanelNum--;
    } else {
      activePanelNum++;
    }

    setActiveStep(activePanelNum);
    setActivePanel(activePanelNum);
  });

  //SETTING PROPER FORM HEIGHT ONLOAD
  window.addEventListener('load', setFormHeight, false);

  //SETTING PROPER FORM HEIGHT ONRESIZE
  window.addEventListener('resize', setFormHeight, false);

  //changing animation via animation select !!!YOU DON'T NEED THIS CODE (if you want to change animation type, just change form panels data-attr)

  const setAnimationType = (newType) => {
    DOMstrings.stepFormPanels.forEach((elem) => {
      elem.dataset.animation = newType;
    });
  };

  //selector onchange - changing animation
  const animationSelect = document.querySelector('.pick-animation__select');

  animationSelect.addEventListener('change', () => {
    const newAnimationType = animationSelect.value;

    setAnimationType(newAnimationType);
  });
</script>

_________________________________________________

<div class="jumbotron d-flex align-items-center">
  <div class="container">
    <ng-container [ngSwitch]="view">
      <ng-container *ngSwitchCase="'table'">
        <h2>{{ 'externalSharedService.new_service' | translate }} ({{ providerInfo && providerInfo.name }})</h2>
        <br />
        <table>
          <tbody>
            <tr>
              <td>
                {{ 'externalSharedService.provider_detail' | translate }}
              </td>
              <td>
                {{ providerInfo && providerInfo.name }}
              </td>
            </tr>
            <tr>
              <td>
                {{ 'externalSharedService.type' | translate }}
              </td>
              <td>
                {{ formData.type }}
              </td>
            </tr>
            <tr>
              <td>
                {{ 'externalSharedService.description' | translate }}
              </td>
              <td>
                {{ formData.description }}
              </td>
            </tr>
            <tr>
              <td>
                {{ 'externalSharedService.email' | translate }}
              </td>
              <td>
                {{ formData.email }}
              </td>
            </tr>
            <tr *ngIf="formData.phoneNo">
              <td>
                {{ 'externalSharedService.phone_number' | translate }}
              </td>
              <td>
                {{ formData.phoneNo }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngSwitchCase="'error'">
        <h2>
          {{ 'externalSharedService.some_error_occurred' | translate }}
        </h2>
        <button (click)="view = ''" class="btn btn-primary">{{ 'externalSharedService.go_back' | translate }}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'success'">
        <ng-container *ngIf="extraData.isNew; else alreadyAccount">
          <div>
            <h2>
              {{ 'externalSharedService.new_account_detail' | translate }}
              <!-- You didn't have account on pinbox24, we create one account for you and sended credentials to you email. Thanks for choosing us. -->
            </h2>
            <button (click)="view = ''" class="btn btn-primary">
              {{ 'externalSharedService.go_back' | translate }}
            </button>
          </div>
        </ng-container>

        <ng-template #alreadyAccount>
          <div>
            <h2>
              {{ 'externalSharedService.already_account_detail' | translate }}
              <!-- We have started the service for you, You can see details by going to following link. -->
            </h2>
            <a target="_blank" [href]="frontUrl + 'personal-platform/my-cases/' + extraData.caseId">{{
              'externalSharedService.open_case' | translate
            }}</a>
            <br />
            <br />
            <button (click)="view = ''" class="btn btn-primary">
              {{ 'externalSharedService.go_back' | translate }}
            </button>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <h2>{{ 'externalSharedService.new_service' | translate }} ({{ providerInfo && providerInfo.name }})</h2>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="first">{{ 'externalSharedService.select_type' | translate }}</label>
              <select [(ngModel)]="formData.type" class="form-control" name="cc_exp_mo" size="0">
                <option *ngFor="let item of types" [value]="item.key">{{ item.title | textTranslate: 'en' }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="last">{{ 'externalSharedService.description' | translate }}</label>
              <textarea
                [(ngModel)]="formData.description"
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="company">{{ 'externalSharedService.email' | translate }}</label>
              <input
                [(ngModel)]="formData.email"
                (blur)="getUserDetail()"
                type="text"
                class="form-control"
                placeholder=""
                id="company"
              />
            </div>
          </div>

          <ng-container *ngIf="!userDetail.phoneNoVerified">
            <div class="form-group col-md-12">
              <label for="phone">{{ 'externalSharedService.phone_no' | translate }}</label>
              <input
                [disabled]="otpSent"
                [(ngModel)]="formData.phoneNo"
                type="tel"
                class="form-control"
                id="phone"
                placeholder="phone"
              />
            </div>
            <div *ngIf="!otpSent" class="d-flex flex-row-reverse w-100 mr-3">
              <a class="btn btn-info" (click)="sendOtp()">{{ 'externalSharedService.send_otp' | translate }}</a>
            </div>

            <div class="form-group col-md-12" *ngIf="otpStatus">
              <label for="otp">{{ 'externalSharedService.otp' | translate }}</label>
              <input [(ngModel)]="otp" type="tel" class="form-control" id="otp" placeholder="otp" />
            </div>

            <div class="col-md-12">
              <ng-container [ngSwitch]="otpStatus">
                <ng-container *ngSwitchCase="'OTP_SENDED'">
                  <div class="alert alert-success" role="alert">
                    {{ 'externalSharedService.otp_sended' | translate }}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'OTP_VERIFIED'">
                  <div class="alert alert-success" role="alert">
                    {{ 'externalSharedService.otp_verified' | translate }}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'INVALID_OTP'">
                  <div class="alert alert-danger" role="alert">
                    {{ 'externalSharedService.invalid_otp' | translate }}
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div *ngIf="otpStatus" class="d-flex flex-row-reverse w-100 mr-3">
              <a class="btn btn-info" (click)="verifyOtp()">{{ 'externalSharedService.verify_otp' | translate }}</a>
            </div>
          </ng-container>
          <button [disabled]="!phoneVerified" (click)="submit()" class="btn btn-primary">
            {{ 'externalSharedService.submit' | translate }}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
