<div [ngClass]="{ 'nav-item': inNavbar }" ngbDropdown>
  <a *ngIf="inNavbar; else button" id="language-dropdown" class="nav-link" ngbDropdownToggle>
    <img style="width: 20px" [src]="'./assets/images/flags/' + currentLang + '.png'" alt="" />
    <!-- {{ currentLang | translate }} -->
  </a>
  <ng-template #button>
    <button id="language-dropdown" class="btn btn-sm btn-secondary" ngbDropdownToggle>
      <img style="width: 20px" [src]="'./assets/images/flags/' + currentLang + '.png'" alt="" />

      {{ currentLang }}
    </button>
  </ng-template>
  <div ngbDropdownMenu aria-labelledby="language-dropdown" [ngClass]="menuClass">
    <button class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language.id)">
      <img style="width: 20px; margin-bottom: 3px" [src]="'./assets/images/flags/' + language.id + '.png'" alt="" />
      <span>
        {{ language.title | translate }}
      </span>
    </button>
  </div>
</div>
