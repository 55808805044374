<section class="wrapper" *ngIf="providerInfo">
  <div class="container-fostrap">
    <div *ngIf="types && types.length">
      <ng-container *ngIf="myTypes && myTypes.length > 1">
        <h2 style="margin: 24px">
          <!-- We offer services of severall types -->
          {{ 'externalSharedService.we_offer_services_of_several_types' | translate }}
        </h2>
        <br />
        <button
          (click)="changeType('all')"
          type="button"
          class="btn {{ currentType === 'all' ? 'btn-dark' : 'btn-light' }} mr-1"
        >
          {{ 'externalSharedService.all' | translate }} ({{ staticServices.length || 0 }})
        </button>
        <button
          *ngFor="let item of myTypes"
          (click)="changeType(item.key)"
          type="button"
          class="btn {{ currentType === item.key ? 'btn-dark' : 'btn-light' }} mr-1"
        >
          {{ item.title | textTranslate: currentLang }} ({{
            (staticServices | fullTextSearch: item.key:'serviceData.type').length
          }})
        </button>
        <br />
      </ng-container>
      <ng-container *ngIf="myTypes && myTypes.length === 1">
        <h2 style="margin: 24px">
          {{ 'externalSharedService.we_offer_service_for' | translate }}
          {{ myTypes[0].title | textTranslate: currentLang }}
        </h2>
        <br />
      </ng-container>
      <br />
    </div>
    <div class="content">
      <div class="container">
        <div>
          <div class="form-group">
            <input
              style="
                font-size: 24px;
                background: lightyellow;
                text-align: center;
                max-width: 600px;
                margin: auto;
                margin-bottom: 26px;
              "
              [placeholder]="'externalSharedService.search' | translate"
              type="email"
              [(ngModel)]="query"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" *ngIf="localtoken">
            <p style="margin-right: 16px; font-size: 20px">
              {{ 'externalSharedService.internal_service' | translate }}
              <input type="checkbox" (click)="onInternalChange($event, 'internal')" [(ngModel)]="isInternal" />
            </p>
            <p style="font-size: 20px">
              {{ 'externalSharedService.external_service' | translate }}
              <input type="checkbox" (click)="onInternalChange($event, 'external')" [(ngModel)]="!isInternal" />
            </p>
          </div>
        </div>
        <div class="row">
          <ul class="list-group">
            <li
              style="display: flex; border-bottom: 1px solid #c7c4c4; margin-bottom: 10px; border: 1px solid #c7c4c4"
              *ngFor="let item of allServices | fullTextSearch: query:['serviceName']"
              class="list-group-item clearfix"
            >
              <div>
                <img
                  style="width: 124px; height: 124px"
                  class="d-none d-md-block img-responsive img-rounded"
                  *ngIf="item.img"
                  [src]="item.img"
                />
                <img
                  style="width: 124px; height: 124px"
                  class="d-none d-md-block img-responsive img-rounded"
                  *ngIf="!item.img"
                  src="https://pinbox24.com/wp-content/uploads/2020/08/cropped-Mock-2rescale30pxhigh-e1605388640872.png"
                />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  margin-left: 20px;
                  width: 100%;
                  justify-content: space-between;
                "
              >
                <h3 class="list-group-item-heading">
                  {{ item.serviceName }}
                </h3>
                <p>
                  {{ item.signature }}
                </p>
                <div class="d-flex flex-row happ-tag-container">
                  <span *ngIf="item.serviceData && item.serviceData.lang">
                    {{ item.serviceData.lang }}
                  </span>
                  <span *ngIf="item.serviceData && item.serviceData.type">
                    {{ item.serviceData.type }}
                  </span>
                </div>
                <p class="list-group-item-text lead">
                  {{
                    item.serviceData &&
                      item.serviceData.recordData &&
                      item.serviceData.recordData.clientShortDescription
                  }}
                </p>
                <div class="btn-toolbar pull-right d-flex justify-content-between" role="toolbar" aria-label="">
                  <span></span>

                  <a [routerLink]="['./' + item._id]" class="btn btn-outline-primary">
                    {{ 'externalSharedService.view_more' | translate }}
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
