import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SServicesComponent } from './s-services.component';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SharedModule } from '@app/@shared';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  Shell.childRoutes([{ path: 'all-services', component: SServicesComponent, data: { title: marker('Services') } }]),
];

@NgModule({
  declarations: [SServicesComponent],
  imports: [CommonModule, SharedModule, FormsModule, TranslateModule, GlobalPipesModule, RouterModule.forChild(routes)],
})
export class SServicesModule {}
