import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AboutModule } from './about/about.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedServiceCreateModule } from './pages/shared-service-create/shared-service-create.module';
import { FooterModule } from './@shared/components/footer/footer.module';
import { OfficeAboutModule } from './pages/office-about/office-about.module';
import { OfficeHomeModule } from './pages/office-home/office-home.module';
import { OfficeContactModule } from './pages/office-contact/office-contact.module';
import { OfficeOurServicesModule } from './pages/office-our-services/office-our-services.module';
import { GlobalPipesModule } from './@shared/pipes/globalPipes.module';
import { GdprInfoModule } from './pages/gdpr-info/gdpr-info.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedServiceDetailModule } from './pages/shared-service-detail/shared-service-detail.module';
import { SHomeModule } from './pages/services/s-home/s-home.module';
import { SServicesModule } from './pages/services/s-services/s-services.module';
import { SContactModule } from './pages/services/s-contact/s-contact.module';
import { SRodoModule } from './pages/services/s-rodo/s-rodo.module';
import { SProviderModule } from './pages/services/s-provider/s-provider.module';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { SPlatformModule } from './pages/services/s-platform/s-platform.module';
import { BookingPanelModule } from './pages/booking-panel/booking-panel.module';
import { MatchMediaService } from './@shared/services/match-media.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { PaymentResultModule } from './pages/payment-result/payment-result.module';
import { SpecialistListModule } from './pages/specialist-list/specialist-list.module';
import { SpecialistFormModule } from './pages/specialist-form/specialist-form.module';
import { SsEventListModule } from './pages/ss-event-list/ss-event-list.module';
import { SsEventDetailModule } from './pages/ss-event-detail/ss-event-detail.module';

@NgModule({
  imports: [
    BrowserModule,
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    GlobalPipesModule,
    SharedModule,
    ShellModule,
    HomeModule,
    AboutModule,
    AuthModule,
    OfficeAboutModule,
    OfficeHomeModule,
    PaymentResultModule,
    OfficeContactModule,
    OfficeOurServicesModule,
    FooterModule,
    SharedServiceCreateModule,
    GdprInfoModule,
    SharedServiceDetailModule,
    SHomeModule,
    SServicesModule,
    SContactModule,
    SRodoModule,
    SProviderModule,
    SPlatformModule,
    BrowserAnimationsModule,
    BookingPanelModule,
    SpecialistListModule,
    DlDateTimeDateModule,
    SpecialistFormModule,
    SsEventListModule,
    DlDateTimePickerModule,
    SsEventDetailModule,
    ToastrModule.forRoot(),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [MatchMediaService, ToastrService],
  bootstrap: [AppComponent],
})
export class AppModule {}
