<app-header></app-header>
<div class="global-spinner" *ngIf="loading">
  <div>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p>
      {{ 'externalSharedService.loading_info' | translate }}
    </p>
  </div>
</div>
<div style="margin-top: 88px">
  <router-outlet></router-outlet>
</div>
