import { Pipe, PipeTransform } from '@angular/core';
import * as objectPath from 'object-path';

@Pipe({
  name: 'fullTextSearch',
  pure: false,
})
export class FullTextSearchPipe implements PipeTransform {
  constructor() {}

  transform(value: any, query: string, field?: any): any {
    return query
      ? value.reduce((prev: any, next: any) => {
          query = query.toLowerCase();
          if (field) {
            if (typeof field === 'object' && field.length > 0) {
              field.forEach((element: any) => {
                if (next[element] && typeof next[element] === 'string') {
                  const searchField = next[element].toLowerCase();
                  if (searchField.includes(query)) {
                    prev.push(next);
                  }
                }
              });
            } else {
              let searchField = next[field] || objectPath.get(next, field);
              if (searchField) {
                searchField = searchField.toLowerCase();
                if (searchField.includes(query)) {
                  prev.push(next);
                }
              }
            }
          } else {
            if (next && next.toLowerCase().includes(query)) {
              prev.push(next);
            }
          }
          return prev;
        }, [])
      : value;
  }
}
