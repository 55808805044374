import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
})
export class MapSearchComponent implements OnInit {
  @Input() range: any;
  @Input() selectedRange: any;
  @Input() showLocation: any;

  searchText: any;

  rangeArray = [
    {
      title: 'externalSharedService.nearby',
      range: 10000,
      zoom: 18,
    },
    {
      title: 'externalSharedService.city',
      range: 6000,
      zoom: 12,
    },
    {
      title: 'externalSharedService.country',
      range: 1500000,
      zoom: 3,
    },
  ];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    console.log('------', this.range);
  }
}
