import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfficeHomeComponent } from './office-home.component';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', component: OfficeHomeComponent, data: { title: marker('Home') } },
    { path: 'home', component: OfficeHomeComponent, data: { title: marker('Home') } },
    // { path: ':officeId/home', component: OfficeHomeComponent, data: { title: marker('Home') } },
  ]),
];

@NgModule({
  declarations: [OfficeHomeComponent],
  imports: [CommonModule, GlobalPipesModule, RouterModule.forChild(routes)],
})
export class OfficeHomeModule {}
