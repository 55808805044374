<div style="padding: 30px 10px">
  <section class="wrapper" *ngIf="providerInfo">
    <div
      *ngIf="providerInfo && providerInfo.bookingHeaderHtml"
      [innerHTML]="providerInfo.bookingHeaderHtml | htmlWithStyle"
    ></div>
    <ng-container
      *ngIf="
        providerInfo &&
          providerInfo.resourceList &&
          providerInfo.resourceList.length &&
          providerInfo.serviceList &&
          providerInfo.serviceList.length;
        else otherCase
      "
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <ol class="stepper">
              <li
                (click)="currentView = 'service'"
                class="step"
                [ngClass]="{ current: currentView === 'service', past: currentView !== 'service' }"
              >
                <div style="display: flex; justify-content: center">
                  <p class="happ-step-head" [ngClass]="{ 'happ-active': currentView === 'service' }">
                    1 - {{ 'externalSharedService.step' | translate }} - {{ 'externalSharedService.step1' | translate }}
                  </p>
                </div>
              </li>
              <li
                (click)="currentView = 'booking'"
                class="step"
                [ngClass]="{ current: currentView === 'booking', past: currentView !== 'booking' }"
              >
                <div style="display: flex; justify-content: center">
                  <p class="happ-step-head" [ngClass]="{ 'happ-active': currentView === 'booking' }">
                    2 - {{ 'externalSharedService.step' | translate }} - {{ 'externalSharedService.step2' | translate }}
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
                <button (click)="currentView='service'" type="button" fxFlex="49" class="btn btn-lg" [ngClass]="{'btn-primary': (currentView==='service'), 'btn-outline-secondary': (currentView!=='service'), 'happ-class-dark': (currentView!=='service')}">
                    {{ 'externalSharedService.select_service' | translate }}
                </button>
                <button (click)="currentView='booking'" type="button" fxFlex="49" class="btn btn-lg" [ngClass]="{'btn-primary': (currentView==='booking'), 'btn-outline-secondary': (currentView!=='booking'), 'happ-class-dark': (currentView!=='booking')}">
                    {{ (serviceInfo && serviceInfo.serviceName) || 'externalSharedService.booking' | translate }}
                </button>
            </div> -->
      <!-- <ul class="nav nav-tabs">
                <li (click)="currentView='service'" class="nav-item">
                  <a class="nav-link" [ngClass]="{active: (currentView==='service')}">  </a>
                </li>
                <li (click)="currentView='booking'" class="nav-item">
                  <a class="nav-link" [ngClass]="{active: (currentView==='booking')}" >{{ (serviceInfo && serviceInfo.serviceName) || 'externalSharedService.booking' | translate }}</a>
                </li>
              </ul> -->
      <div *ngIf="currentView === 'service'" style="padding: 20px 0px">
        <div fxLayout="row" style="border-bottom: 4px solid #9e9999; padding-left: 12px; padding-right: 12px">
          <p style="font-size: 26px; margin: 0px">
            {{ 'externalSharedService.select_service' | translate }}
          </p>
          <span fxFlex></span>
          <ng-container *ngIf="localtoken">
            <p style="margin-right: 16px; font-size: 26px; margin-bottom: 0px">
              {{ 'externalSharedService.internal_service' | translate }}
              <input type="checkbox" (click)="onInternalChange($event, 'internal')" [(ngModel)]="isInternal" />
            </p>
            <p style="font-size: 26px; margin-bottom: 0px; margin-right: 12px">
              {{ 'externalSharedService.external_service' | translate }}
              <input type="checkbox" (click)="onInternalChange($event, 'external')" [(ngModel)]="!isInternal" />
            </p>
          </ng-container>
        </div>

        <div style="padding: 12px">
          <div class="page-content page-container" id="page-content">
            <div>
              <div class="row">
                <div style="width: 100%">
                  <div>
                    <div class="list list-row card" id="sortable" data-sortable-id="0" aria-dropeffect="move">
                      <div
                        (click)="onSelecteChange('service', item); currentView = 'booking'"
                        *ngFor="let item of providerInfo.serviceList"
                        class="list-item"
                        data-id="13"
                        data-item-sortable-id="0"
                        draggable="true"
                        role="option"
                        aria-grabbed="false"
                        style="border: 1px solid #e0dfdf; cursor: pointer"
                      >
                        <div>
                          <a data-abc="true"><span class="w-40 avatar gd-primary">P</span></a>
                        </div>
                        <div class="flex">
                          <a class="item-author text-color" data-abc="true">{{
                            item.serviceName || item.signature || 'NA'
                          }}</a>
                          <div class="item-except text-muted text-sm h-1x">
                            {{ item.serviceName || item.signature || 'NA' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="currentView === 'booking'" style="padding: 20px 0px">
        <div fxLayout="row" style="border-bottom: 4px solid blue; margin-bottom: 24px">
          <h2 style="text-align: center; margin-bottom: 18px">
            {{ 'externalSharedService.service_booking' | translate }}
          </h2>
          <span fxFlex></span>
          <h2 style="text-align: center; margin-bottom: 18px">
            {{ (serviceInfo && serviceInfo.serviceName) || 'externalSharedService.booking' | translate }} /
            <span *ngIf="selectedResourceObj">
              {{ selectedResourceObj.name || selectedResourceObj.resName || selectedResourceObj.signature || 'NA' }}
            </span>
          </h2>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" style="padding: 0px">
          <div fxFlex="30" fxFlex.lt-md="100" fxLayout="column" style="padding: 12px 8px">
            <ng-container *ngIf="filteredResrouceList && filteredResrouceList.length; else otherResCase">
              <p style="font-size: 20px; margin-left: 18px">
                {{ 'externalSharedService.select_resource' | translate }}
              </p>
              <!-- <div class="list-group happ-list-item-group">
                                  <a (click)="selectedResource=item._id; onSelecteChange('resource')" *ngFor="let item of filteredResrouceList" class="list-group-item" [ngClass]="{active: (selectedResource===item._id)}" >
                                    <h4 class="list-group-item-heading">{{ (item.name || item.resName || item.signature) || 'NA' }}</h4>
                                    <p class="list-group-item-text" style="margin: 0px;">{{ (item.name || item.resName || item.signature) || 'NA' }}</p>
                                  </a>
                                  
                                </div> -->
              <div class="page-content page-container" id="page-content">
                <div>
                  <div>
                    <div style="width: 100%">
                      <div>
                        <div class="list list-row card" id="sortable" data-sortable-id="0" aria-dropeffect="move">
                          <div
                            (click)="selectedResource = item._id; onSelecteChange('resource')"
                            *ngFor="let item of filteredResrouceList"
                            [ngClass]="{ 'happ-active': selectedResource === item._id, 'list-item': true }"
                            data-id="13"
                            data-item-sortable-id="0"
                            draggable="true"
                            role="option"
                            aria-grabbed="false"
                            style="border: 1px solid #e0dfdf"
                          >
                            <div *ngIf="selectedResource">
                              <a *ngIf="!selectedResource.mainImg" data-abc="true"
                                ><span class="w-40 avatar gd-primary">P</span></a
                              >
                              <img *ngIf="selectedResource.mainImg" [src]="selectedResource.mainImg" alt="" />
                            </div>
                            <div class="flex">
                              <a href="#" class="item-author text-color" data-abc="true">{{
                                item.name || item.resName || item.signature || 'NA'
                              }}</a>
                              <div class="item-except text-muted text-sm h-1x">
                                {{ item.name || item.resName || item.signature || 'NA' }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #otherResCase>
              <p>
                {{ 'externalSharedService.no_resources_for_this_service' }}
              </p>
            </ng-template>
          </div>

          <div fxFlex="70" fxFlex.lt-md="100">
            <ng-container *ngIf="filteredResrouceList && filteredResrouceList.length; else otherCalCase">
              <div class="appointment-popup">
                <div *ngIf="dateRange" fxLayout="row" fxLayout.lt-md="column">
                  <div class="appointment-calendar">
                    <div fxLayout="row" fxHide.gt-md class="current-week" fxLayoutAlign="space-between center">
                      <div class="calendar-nav" fxLayoutAlign="space-between center" style="width: 100%; padding: 12px">
                        <button
                          style="width: 48%; margin: 0px"
                          type="button"
                          (click)="moveDate(dateRange.header.dates.prev)"
                          class="prev"
                        >
                          <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        </button>
                        <button
                          style="width: 48%; margin: 0px"
                          type="button"
                          (click)="moveDate(dateRange.header.dates.next)"
                          class="next"
                        >
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                    <div fxLayout="row" fxHide fxShow.gt-md class="current-week" fxLayoutAlign="space-between center">
                      <div>
                        <span
                          ><i class="fa fa-calendar"></i> {{ dateRange.header.year }}, {{ dateRange.header.month }}
                          {{ dateRange.header.dates.formattedStart }} - {{ dateRange.header.dates.formattedEnd }}</span
                        >
                      </div>
                      <div class="calendar-nav" fxLayoutAlign="center center">
                        <button type="button" (click)="moveDate(dateRange.header.dates.prev)" class="prev">
                          <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        </button>
                        <button type="button" (click)="moveDate(dateRange.header.dates.next)" class="next">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>

                    <div class="calendar-wrapper">
                      <div class="calendar-week">
                        <ul>
                          <li
                            fxLayout="column"
                            class="happ-header-day-item"
                            [ngClass]="{ active: item.formattedDate === selectedDate }"
                            (click)="selectDate(item)"
                            *ngFor="let item of dateRange.range"
                          >
                            <span>
                              {{ item.formattedDate | date: 'MM-dd' }}
                            </span>
                            <span style="font-size: 20px; font-weight: 700">
                              {{ item.weekDay }}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div fxLayout="row wrap" class="mt-12">
                        <ng-container *ngIf="selectedDateSlots && selectedDateSlots.length; else otherData">
                          <div
                            class="happ-slot-item"
                            [ngClass]="{
                              active: slot.date === currentValue,
                              taken: slot.selected,
                              notTaken: !slot.selected,
                              tempSelected: slot.tempSelected
                            }"
                            (click)="selectSlot(slot)"
                            *ngFor="let slot of selectedDateSlots"
                          >
                            <!-- {{ 'externalSharedService.booked' }} -->
                            <ng-container *ngIf="slot.selected && !slot.tempSelected">
                              {{ 'externalSharedService.booked' | translate }}
                            </ng-container>
                            <ng-container *ngIf="!slot.selected"> {{ slot.formatted }} - {{ slot.end }} </ng-container>
                          </div>
                        </ng-container>
                        <ng-template #otherData>
                          <h2 style="margin-top: 50px; font-weight: 400; text-align: center; width: 100%">
                            {{ 'externalSharedService.provider_not_working' | translate }}
                          </h2>
                        </ng-template>
                      </div>

                      <!-- <div class="calendar-hours" style="height: calc(100vh - 450px) !important">
                                        <ul>
                                            <li *ngFor="let item of dateRange.range">
                                            <ul>
                                                <li (click)="selectSlot(time)" *ngFor="let time of item.times" [class]="(time.date === currentValue)?'active' : ''" >
                                                {{ time.formatted }}</li>
                                            </ul>
                                            </li>
                                        </ul>
                                        </div> -->
                    </div>
                  </div>
                </div>
                <div style="padding: 24px 2rem">
                  <h2>
                    {{ 'externalSharedService.legend' | translate }}
                  </h2>
                  <div fxLayout="row">
                    <div class="happ-slot-item" fxFlex="33">
                      {{ 'externalSharedService.bookable' | translate }}
                    </div>
                    <div class="happ-slot-item tempSelected" fxFlex="33">
                      {{ 'externalSharedService.temp_booked' | translate }}
                    </div>
                    <div class="happ-slot-item taken" fxFlex="33">
                      {{ 'externalSharedService.booked' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #otherCalCase>
              <p>
                {{ 'externalSharedService.no_resources_for_this_service' | translate }}
              </p>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #otherCase>
      <h2>Not configurred correctly.</h2>
      <ul>
        <li>
          <span> You are Provider </span>
          <span class="text-success" *ngIf="providerInfo"> Success </span>
          <span class="text-danger" *ngIf="!providerInfo"> Error </span>
          <!-- providerInfo && providerInfo.resourceList && providerInfo.resourceList.length && providerInfo.serviceList && providerInfo.serviceList.length -->
        </li>
        <li>
          <span> You Have services </span>
          <span
            class="text-success"
            *ngIf="providerInfo && providerInfo.serviceList && providerInfo.serviceList.length; else serviceDoc"
          >
            Success
          </span>
          <ng-template #serviceDoc>
            <span class="text-danger"> Error </span>
          </ng-template>
        </li>
        <li>
          <span> You Have Resources </span>
          <span
            class="text-success"
            *ngIf="providerInfo.resourceList && providerInfo.resourceList.length; else resourceDoc"
          >
            Success
          </span>
          <ng-template #resourceDoc>
            <span class="text-danger"> Error ( No Resource defined ) </span>
          </ng-template>
        </li>
      </ul>
    </ng-template>
    <div
      *ngIf="providerInfo && providerInfo.bookingHeaderHtml"
      [innerHTML]="providerInfo.bookingHeaderHtml | htmlWithStyle"
    ></div>
  </section>
</div>
