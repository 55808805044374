import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  basicRoutes = [
    {
      title: 'externalSharedService.home',
      icon: 'fas fa-home',
      type: 'home',
      route: '/home',
    },
    {
      title: 'externalSharedService.our_services',
      icon: 'fas fa-suitcase',
      type: 'our_services',
      route: '/our-services',
    },
    {
      title: 'externalSharedService.booking',
      type: 'booking',
      icon: 'fas facalendar-alt',
      route: '/booking',
    },
    {
      title: 'externalSharedService.event_list',
      type: 'events',
      icon: 'fas fa-list',
      route: '/event-list',
    },
    {
      title: 'externalSharedService.gdpr',
      type: 'gdpr',
      icon: 'fas fa-user-shield',
      route: '/gdpr',
    },
    {
      title: 'externalSharedService.contact',
      type: 'contact',
      icon: 'fas fa-phone-square-alt',
      route: '/contact',
    },
  ];

  specialistRoutes = [
    {
      title: 'externalSharedService .home',
      icon: 'fas fa-home',
      route: '/home',
    },
    {
      title: 'externalSharedService.specialist_list',
      icon: 'fas fa-suitcase',
      route: '/specialist-list',
    },
    {
      title: 'externalSharedService.register',
      icon: 'fas fa-user-shield',
      route: '/specialist-register',
    },
  ];

  servicesRoutes: any = [
    {
      title: 'externalSharedService.home',
      icon: 'fas fa-home',
      route: '/start',
    },
    {
      title: 'externalSharedService.providers',
      icon: 'fas fa-suitcase',
      route: '/all-providers',
    },
    {
      title: 'externalSharedService.our_services',
      icon: 'fas fa-suitcase',
      route: '/all-services',
    },
    {
      title: 'externalSharedService.gdpr',
      icon: 'fas fa-user-shield',
      route: '/rodo',
    },
    {
      title: 'externalSharedService.platform',
      icon: 'fas fa-user-shield',
      route: '/platform',
    },
    {
      title: 'externalSharedService.contact',
      icon: 'fas fa-phone-square-alt',
      route: '/contact-us',
    },
  ];

  onMenuChange: Subject<any> = new Subject<any>();
  constructor(private apiService: ApiService, private route: ActivatedRoute, private commonService: CommonService) {}

  getMenuOption() {
    const temp: any = window.location.host;
    const pathname: any = window.location.pathname;
    let headerRoute, view;
    if (temp.includes('specialist.pinbox24.com')) {
      headerRoute = this.specialistRoutes;
    } else if (temp && (temp.includes('shared-services') || temp.includes('services'))) {
      headerRoute = this.servicesRoutes;
      view = 'shared-service';
    } else {
      view = 'provider';
      headerRoute = [];
      this.getMenu();
      //   headerRoute = this.basicRoutes;
    }
    return { headerRoute, view };
  }

  async getMenu() {
    try {
      // onMenuChange
      const officeId = this.commonService.getOfficeId(this.route);
      const data = await this.commonService.getAboutOffice(officeId, { route: 'home' });
      const menuConfig = (data && data.providerContract && data.providerContract.webiste_navigation) || {};
      const menu = Object.keys(menuConfig)
        .filter((ele) => menuConfig[ele])
        .map((ele) => {
          return this.basicRoutes.find((item) => item && item.type === ele);
        })
        .filter((ele) => ele);
      this.onMenuChange.next(menu);
    } catch (error) {
      console.error('err - ', error);
    }
  }
}
