import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SRodoComponent } from './s-rodo.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SRodoDescriptionComponent } from './s-rodo-description/s-rodo-description.component';
import { SRodoListComponent } from './s-rodo-list/s-rodo-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { SRodoListModule } from './s-rodo-list/s-rodo-list.module';
import { SRodoDescriptionModule } from './s-rodo-description/s-rodo-description.module';

const routes: Routes = [
  Shell.childRoutes([{ path: 'rodo-detail', component: SRodoComponent, data: { title: marker('RODO Detail') } }]),
];

@NgModule({
  declarations: [SRodoComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    SRodoListModule,
    SRodoDescriptionModule,
    RouterModule.forChild(routes),
  ],
})
export class SRodoModule {}
