import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialistDetailPopupComponent } from './specialist-detail-popup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/@shared';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SpecialistDetailPopupComponent],
  imports: [
    CommonModule,
    CommonModule,
    SharedModule,
    FormsModule,
    FlexModule,
    TranslateModule,
    NgbModule,
    GlobalPipesModule,
  ],
  exports: [SpecialistDetailPopupComponent],
  entryComponents: [SpecialistDetailPopupComponent],
})
export class SpecialistDetailPopupModule {}
