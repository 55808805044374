import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-slot-booking',
  templateUrl: './slot-booking.component.html',
  styleUrls: ['./slot-booking.component.scss'],
})
export class SlotBookingComponent implements OnInit {
  @Input() model: string;
  @Input() serviceData: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() updateServiceData: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();

  currentValue: any;
  lang = 'en';

  dateRange: any;
  selectedDate: any;

  daysLimit = 2;
  slotSize = 15;
  startTime = '08:00';
  endTime = '13:00';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.currentValue = this.model;
    this.slotSize = this.serviceData.slotSize || this.slotSize;
    this.startTime = `${this.serviceData.startTime || this.startTime}:00`;
    this.endTime = `${this.serviceData.endTime || this.endTime}:00`;
    this.lang = this.translateService.currentLang || 'en';
    moment.locale(this.lang);
    this.selectedDate = moment();
    this.formatDateRange(this.selectedDate);
    this.translateService.onLangChange.subscribe((data) => {
      this.lang = this.translateService.currentLang;
      moment.locale(this.lang);
      this.selectedDate = moment(this.selectedDate);
      this.formatDateRange(this.selectedDate);
    });
  }

  formatDateRange(date: moment.Moment) {
    this.dateRange = this.dateRange || {};
    const startAndEnd = this.getStartAndEndDate(moment(date.toISOString()));
    this.dateRange.header = {
      month: date.format('MMMM'),
      dates: {
        start: startAndEnd.start,
        end: startAndEnd.end,
        prev: moment(startAndEnd.leftStart.toISOString()),
        next: moment(startAndEnd.end.toISOString()).add(1, 'day'),
        formattedStart: moment(startAndEnd.start.toISOString()).format('DD'),
        formattedEnd: moment(startAndEnd.end.toISOString()).format('DD'),
      },
      year: date.format('YYYY'),
    };
    console.log(this.dateRange.header.dates.start);
    console.log(this.dateRange.header.dates.end);
    this.getTimeRange(
      this.slotSize,
      moment(this.dateRange.header.dates.start.toISOString()),
      moment(this.dateRange.header.dates.end.toISOString()),
      this.startTime,
      this.endTime
    );
  }

  getStartAndEndDate(date: moment.Moment) {
    console.log(`${date.format('YYYY-MM-DD')}`);
    let count = 0;
    let end = moment(date.toISOString());
    let leftStart = moment(date.toISOString());
    while (count <= this.daysLimit) {
      end = end.add(1, 'day');
      console.log('count - ', count, end.format('YYYY-MM-DD HH:mm'));
      if (this.serviceData && this.serviceData.availableDays && this.serviceData.availableDays) {
        const tempDays = Object.keys(this.serviceData.availableDays);
        if (tempDays.length === 0) {
          continue;
        }
        if (end.format('dddd').toLowerCase() && !this.serviceData.availableDays[end.format('dddd').toLowerCase()]) {
          continue;
        }
      }
      count++;
    }
    count = 0;
    while (count <= this.daysLimit) {
      leftStart = leftStart.add(1, 'day');
      console.log('count - ', count, leftStart.format('YYYY-MM-DD HH:mm'));
      if (this.serviceData && this.serviceData.availableDays && this.serviceData.availableDays) {
        const tempDays = Object.keys(this.serviceData.availableDays);
        if (tempDays.length === 0) {
          continue;
        }
        if (
          leftStart.format('dddd').toLowerCase() &&
          !this.serviceData.availableDays[leftStart.format('dddd').toLowerCase()]
        ) {
          continue;
        }
      }
      count++;
    }
    return {
      start: date,
      leftStart: leftStart,
      end: end.subtract(1, 'day'),
    };
  }

  getTimeRange(slot: number, start: moment.Moment, end: moment.Moment, startTime: string, endTime: string) {
    this.dateRange.range = [];
    console.log('date --- ', start.format('YYYY-MM-DD HH:mm:ss'), end.format('YYYY-MM-DD HH:mm:ss'));
    for (let i = start; i <= end; i = i.add(1, 'day')) {
      if (this.serviceData && this.serviceData.availableDays && this.serviceData.availableDays) {
        const tempDays = Object.keys(this.serviceData.availableDays);
        if (tempDays.length === 0) {
          continue;
        }
        if (i.format('dddd').toLowerCase() && !this.serviceData.availableDays[i.format('dddd').toLowerCase()]) {
          continue;
        }
      }
      console.log(
        'date --- ',
        i.format('YYYY-MM-DD HH:mm:ss'),
        start.format('YYYY-MM-DD HH:mm:ss'),
        end.format('YYYY-MM-DD HH:mm:ss')
      );
      const tempObj: any = {};
      tempObj.date = i.toISOString();
      tempObj.weekDay = i.format('ddd');
      tempObj.times = tempObj.times || [];
      for (
        let j = moment(i.format('YYYY-MM-DD ' + startTime));
        j <= moment(i.format('YYYY-MM-DD ' + endTime));
        j = j.add(slot, 'minute')
      ) {
        tempObj.times.push({ date: j.toISOString(), selected: false, formatted: j.format('HH:mm') });
      }
      this.dateRange.range.push(tempObj);
    }
    console.log(this.dateRange);
  }

  selectSlot(time: any) {
    this.currentValue = time.date;
    this.model = time.date;
    this.modelChange.next(this.model);
  }

  moveDate(date: any) {
    this.selectedDate = moment(date.toISOString());
    this.formatDateRange(moment(date.toISOString()));
    this.updateServiceData.emit({
      selectedStartTime: moment(date).toISOString(),
      selectedEndTime: moment(date).add(this.slotSize, 'hour').toISOString(),
    });
  }

  previousStep() {
    this.next.emit();
  }

  nextStep() {
    this.previous.emit();
  }
}
