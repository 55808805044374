import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct, NgbTimepickerConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

const getLangsData = (lang: any) => {
  moment.locale(lang);
  moment.weekdaysMin();
  return {
    // weekdays: [0, 1, 2, 3, 4, 5, 6].map(day => moment().locale('fr').weekday(day).format('dddd')),
    weekdays: moment.weekdaysMin(),
    // weekdays: moment.weekdays().map((item) => moment(item).format("dddd")),
    months: moment.monthsShort(),
    // months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    weekLabel: 'sem',
  };
};

const I18N_VALUES = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
    weekLabel: 'sem',
  },
  // other languages you would support
};

@Injectable()
export class I18n {
  language = localStorage.getItem('selectedLanguage') || 'en';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  lang = 'en';
  constructor(private _i18n: I18n) {
    super();
    this.lang = localStorage.getItem('selectedLanguage');
  }

  getWeekdayShortName(weekday: number): string {
    return getLangsData(this.lang).weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return getLangsData(this.lang).weekLabel;
  }
  getMonthShortName(month: number): string {
    return getLangsData(this.lang).months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-date-time-range-picker',
  templateUrl: './date-time-range-picker.component.html',
  styleUrls: ['./date-time-range-picker.component.scss'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }], // define custom NgbDatepickerI18n provider
})
export class DateTimeRangePickerComponent implements OnInit {
  @Input() model: string;
  @Input() type: string;
  @Input() serviceData: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() updateServiceData: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();

  hoveredDate: any | null = null;

  fromDate: any;
  toDate: any | null = null;
  startTime: any = { hour: 0, minute: 0 };
  endTime: any = { hour: 23, minute: 59 };

  constructor(config: NgbTimepickerConfig) {
    // config.seconds = true;
    config.spinners = false;
  }

  ngOnInit(): void {
    if (this.serviceData) {
      if (this.serviceData.selectedStartTime) {
        this.fromDate = this.serviceData.selectedStartTime;
      }
      if (this.serviceData.selectedEndTime) {
        this.toDate = this.serviceData.selectedEndTime;
      }
    }
    console.log('init date ', this.fromDate, this.toDate);

    const currentLang = localStorage.getItem('selectedLanguage') || 'en';
  }

  previousStep() {
    this.next.emit();
  }

  nextStep() {
    this.previous.emit();
  }

  onCustomDateChange(eve: any, type: string, next?: any) {
    this[type] = `${eve.year}-${eve.month}-${eve.day}`;

    if (next) {
      next.toggle();
    }

    if (this.type === 'single' && this.fromDate) {
      this.toDate = moment(`${moment(this.fromDate).format('YYYY-MM-DD')} 23:59:59`).toISOString();
      this.updateServiceData.emit({
        selectedStartTime: moment(
          `${moment(this.fromDate).format('YYYY-MM-DD')} ${this.startTime.hour}:${this.startTime.minute}:${
            this.startTime.second
          }`
        ).toISOString(),
        selectedEndTime: moment(`${moment(this.fromDate).format('YYYY-MM-DD')} 23:59:59`).toISOString(),
      });
    } else if (this.fromDate && this.toDate) {
      this.updateServiceData.emit({
        selectedStartTime: moment(
          `${moment(this.fromDate).format('YYYY-MM-DD')} ${this.startTime.hour}:${this.startTime.minute}:${
            this.startTime.second
          }`
        ).toISOString(),
        selectedEndTime: moment(
          `${moment(this.toDate).format('YYYY-MM-DD')} ${this.endTime.hour}:${this.endTime.minute}:${
            this.endTime.second
          }`
        ).toISOString(),
      });
    }
  }
}
