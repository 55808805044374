<div class="modal-header">
  <!-- <h4 class="modal-title" style="padding: 0px">
        {{ headingInfo | translate }}
    </h4> -->
  <!-- <div  class="modal-footer"> -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
    <button type="button" class="btn btn-light" (click)="cancelClick()">
      {{ 'externalSharedService.cancel' | translate }}
    </button>
  </div>
  <!-- </div> -->
</div>
<div class="modal-body">
  <!-- <p> {{ 'externalSharedService.you_will_lose_data' | translate }} </p> -->
  <div style="height: 400px" fxLayoutAlign="center center" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="allData && !loading">
    <!-- {{ allData | json }} -->
    <ng-container *ngIf="view !== 'summary'">
      <!-- <div class="stepwizard">
                <div class="stepwizard-row setup-panel">
                    <div class="stepwizard-step">
                        <a type="button" class="btn {{ (view === 'email') ? 'btn-primary' : 'btn-default' }} btn-circle">1</a>
                        <p>Enter Email</p>
                    </div>
                    <div class="stepwizard-step">
                        <a type="button" class="btn {{ (view === 'password') ? 'btn-primary' : 'btn-default' }} btn-circle" disabled="disabled">2</a>
                        <p>Enter password</p>
                    </div>
                    <div class="stepwizard-step">
                        <a type="button" class="btn {{ (view === 'payment') ? 'btn-primary' : 'btn-default' }} btn-circle" disabled="disabled">3</a>
                        <p>Make payment</p>
                    </div>
                </div>
            </div>
            <br> -->
    </ng-container>
    <div [ngSwitch]="view">
      <div *ngSwitchCase="'summary'">
        <br />
        <div fxLayout="row" style="margin-bottom: 12px">
          <h5>
            {{ 'externalSharedService.service_description' | translate }}
          </h5>
          <p style="margin-left: 12px">
            {{ allData.data.shortDescription }}
          </p>
        </div>
        <div fxLayout="row">
          <div style="width: 200px">
            <img style="width: 100%" [src]="allData.data.mainImg" alt="" />
          </div>
          <div style="margin-left: 12px">
            <h2>
              {{ allData.data.extHeading }}
            </h2>
            <div [innerHtml]="allData.data.description | htmlWithStyle"></div>
          </div>
        </div>
        <!-- <p>
                    {{ allData.serviceData.clientShortDescription }}
                </p>         -->
      </div>

      <div *ngSwitchCase="'email'" style="text-align: center">
        <h2 style="margin-bottom: 16px">
          {{ 'externalSharedService.enter_your_email' | translate }}
        </h2>
        <p style="margin-bottom: 16px">
          {{ 'externalSharedService.email_description' | translate }}
        </p>
        <div class="form-group">
          <!-- <label for="exampleInputEmail1">{{ 'externalSharedService.enter_email' | translate }}</label> -->
          <input
            autofocus="true"
            style="
              font-size: 24px;
              background: lightyellow;
              text-align: center;
              max-width: 600px;
              margin: auto;
              margin-bottom: 26px;
            "
            type="email"
            #contactemail="ngModel"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
            [(ngModel)]="formData.email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
          <div class="md-errors-spacer" [hidden]="contactemail.valid || contactemail.untouched">
            <div class="text-danger" *ngIf="contactemail.errors && contactemail.errors.pattern">
              {{ 'externalSharedService.email_is_invalid' | translate }}
            </div>
          </div>
        </div>

        <!-- <div class="form-group">
                    <label for="exampleInputEmail1">{{ 'externalSharedService.enter_password' | translate }}</label>
                    <input   [(ngModel)]="loginData.password" type="password" class="form-control" aria-describedby="emailHelp" >
                </div>
                <button type="button" class="btn btn-success" (click)="login()">{{ 'externalSharedService.login' | translate }}</button>
                <button type="button" class="btn btn-outline-dark" (click)="view='signup'">{{ 'externalSharedService.signup' | translate }}</button> -->

        <br />
        <div fxLayout="row" fxLayoutAlign="center center">
          <button type="button" class="btn btn-outline-dark" (click)="view = 'summary'">
            {{ 'externalSharedService.back' | translate }}
          </button>
          <span fxFlex></span>
          <button type="button" class="btn btn-success" (click)="checkAndDecide()">
            {{ 'externalSharedService.next' | translate }}
          </button>
        </div>
      </div>

      <div *ngSwitchCase="'password'">
        <ng-container *ngIf="accountExists">
          <h2>
            {{ 'externalSharedService.login_enter_your_password' | translate }}
          </h2>
          <p>
            {{ 'externalSharedService.login_password_description' | translate }}
          </p>
        </ng-container>
        <ng-container *ngIf="!accountExists">
          <h2>
            {{ 'externalSharedService.signup_enter_your_password' | translate }}
          </h2>
          <p>
            {{ 'externalSharedService.signup_password_description' | translate }}
          </p>
        </ng-container>

        <div class="form-group">
          <!-- <label for="exampleInputEmail1">{{ 'externalSharedService.enter_password' | translate }}</label> -->
          <input [(ngModel)]="formData.password" type="password" class="form-control" aria-describedby="emailHelp" />
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
          <button type="button" class="btn btn-outline-dark" (click)="view = 'email'">
            {{ 'externalSharedService.back' | translate }}
          </button>
          <span fxFlex></span>
          <ng-container *ngIf="accountExists">
            <button type="button" class="btn btn-success" (click)="login()">
              {{ 'externalSharedService.next' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="!accountExists">
            <button type="button" class="btn btn-success" (click)="signup()">
              {{ 'externalSharedService.next' | translate }}
            </button>
          </ng-container>
        </div>
      </div>

      <div *ngSwitchCase="'loginInfo'">
        <p>
          {{ 'externalSharedService.login_info_1' | translate }}
        </p>
        <p>
          {{ 'externalSharedService.login_info_2' | translate }}
        </p>
        <p>
          {{ 'externalSharedService.login_info_3' | translate }}
        </p>
        <br />
        <div fxLayout="row" fxLayoutAlign="center center">
          <button type="button" class="btn btn-outline-dark" (click)="view = 'summary'">
            {{ 'externalSharedService.back' | translate }}
          </button>
          <span fxFlex></span>
          <button type="button" class="btn btn-success" (click)="continue()">
            {{ 'externalSharedService.next' | translate }}
          </button>
        </div>
      </div>

      <div *ngSwitchCase="'signupInfo'">
        <p>
          {{ 'externalSharedService.signup_info_1' | translate }}
        </p>
        <p>
          {{ 'externalSharedService.signup_info_2' | translate }}
        </p>
        <p>
          {{ 'externalSharedService.signup_info_3' | translate }}
        </p>
        <br />
        <div fxLayout="row" fxLayoutAlign="center center">
          <button type="button" class="btn btn-outline-dark" (click)="view = 'summary'">
            {{ 'externalSharedService.back' | translate }}
          </button>
          <span fxFlex></span>
          <button type="button" class="btn btn-success" (click)="continue()">
            {{ 'externalSharedService.next' | translate }}
          </button>
        </div>
      </div>

      <div *ngSwitchCase="'payment'">
        <h2>Pay Now</h2>
        <table>
          <!-- <tr>
                      <td> {{ 'externalSharedService.payment_method' | translate }} </td>
                      <td>
                        <div>
                          <button class="mr-12" mat-raised-button (click)="reqBody.paymentSource='online';reqBody.paymentMethod='przelewy24'" [color]="reqBody.paymentSource==='online' ? 'primary' : ''" > {{ 'externalSharedService.online' | translate }} </button>
                          <button mat-raised-button (click)="reqBody.paymentSource='credit'" [color]="reqBody.paymentSource==='credit' ? 'primary' : ''" > {{ 'externalSharedService.credit' | translate }} </button>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr> -->
          <tr *ngIf="reqBody.paymentSource === 'online'">
            <td>{{ 'externalSharedService.payment_gateway' | translate }}</td>
            <td>
              <div>
                <select [(ngModel)]="reqBody.paymentMethod" class="form-select" aria-label="Default select example">
                  <option>Select</option>
                  <option value="przelewy24">przelewy24</option>
                </select>
                <!-- <button type="button" class="btn {{reqBody.paymentMethod==='przelewy24' ? 'btn-success' : 'btn-outline-success'}}" (click)="reqBody.paymentMethod='przelewy24'" > przelewy24 </button> -->
              </div>
            </td>
            <td>{{ 'externalSharedService.pay' | translate }}</td>
            <td style="text-align: right">
              <button type="button" class="btn btn-success" (click)="pay()" mat-raised-button>
                {{ 'externalSharedService.paynow' | translate }}
              </button>
            </td>
          </tr>
        </table>
        <h2 style="margin-top: 16px; margin-bottom: 14px">
          {{ 'externalSharedService.order_detail' | translate }}
        </h2>
        <table>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.platform' | translate }}
            </td>
            <td>pinbox24.com</td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.seller_name' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.providerOfficeId }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.seller_id' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.providerOfficeId }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.buyer_email' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.creatorEmail }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.buyer_id' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.clientId }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.prod_name' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.selectedVariant[0].name }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.prod_code' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.selectedVariant[0].productCode }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.price' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.selectedVariant[0].price }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.currency' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.selectedVariant[0].currency }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.order_id' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail._id }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.order_date' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.registredDataTime }}
            </td>
          </tr>
          <tr>
            <td>
              {{ 'externalSharedService.cancel_order' | translate }}
            </td>
            <td>
              <a (click)="cancelClick()" style="color: blue; cursor: pointer">
                {{ 'externalSharedService.cancel_order' | translate }}
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div *ngSwitchDefault>default</div>
    </div>
  </div>
</div>
