import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BookingPreviewComponent } from '../booking-preview/booking-preview.component';

@Component({
  selector: 'app-main-booking-panel',
  templateUrl: './main-booking-panel.component.html',
  styleUrls: ['./main-booking-panel.component.scss'],
})
export class MainBookingPanelComponent implements OnInit {
  serviceInfo: any;
  officeId: any;
  frontUrl = environment.pinbox24MainFront;
  providerInfo: any;
  selectedService: any;
  selectedResource: any;
  currentValue: any;
  currentView = 'service';
  isInternal = false;
  lang = 'en';

  dateRange: any;
  selectedDate: any;
  selectedDateSlots: any = [];
  filteredResrouceList: any = [];

  daysLimit = 2;
  slotSize = 15;
  startTime = '08:00';
  endTime = '13:00';
  resourceInfo: any;
  selectedSlot: any;
  localtoken: any;
  selectedResourceObj: any;

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.localtoken = localStorage.getItem('ngStorage-token');
    this.officeId = this.commonService.getOfficeId(this.route);
    if (this.officeId) {
      this.getData();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  onInternalChange(eve: any, type: any) {
    console.log('Eve - ', eve);
    this.providerInfo = null;
    if (type === 'internal') {
      this.isInternal = eve.target.checked;
    } else {
      this.isInternal = !eve.target.checked;
    }
    this.getData();
  }

  initSlotData() {
    // this.currentValue = this.model;
    this.slotSize = this.serviceInfo.slotSize || this.slotSize;
    this.startTime = `${this.serviceInfo.startTime || this.startTime}:00`;
    this.endTime = `${this.serviceInfo.endTime || this.endTime}:00`;
    this.lang = this.translateService.currentLang || 'en';
    moment.locale(this.lang);
    this.selectedDate = moment().format('YYYY-MM-DD');
    this.formatDateRange(moment(this.selectedDate));
    this.getAllTimeSlots();
    this.translateService.onLangChange.subscribe((data) => {
      this.lang = this.translateService.currentLang;
      moment.locale(this.lang);
      this.selectedDate = moment(this.selectedDate).format('YYYY-MM-DD');
      this.getAllTimeSlots();
      this.formatDateRange(moment(this.selectedDate));
    });
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      let temp: any = { route: 'booking' };
      console.log('this.isInternal - ', this.isInternal);
      if (this.isInternal) {
        temp.serviceVisbility = 'internalResBooking';
      }
      this.providerInfo = await this.commonService.getAboutOffice(this.officeId, temp);
      this.serviceInfo = (this.providerInfo && this.providerInfo.serviceList && this.providerInfo.serviceList[0]) || {};
      this.selectedService = this.serviceInfo && this.serviceInfo.serviceData && this.serviceInfo.serviceData._id;

      this.getResource();
      // this.resourceInfo = (this.providerInfo && this.providerInfo.resourceList && this.providerInfo.resourceList[0]) || {};

      this.initSlotData();
      this.getAllTimeSlots();
      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }

  getResource() {
    console.log('this.providerInfo.resourceList', this.providerInfo.resourceList, this.selectedService);
    this.providerInfo.resourceList = this.providerInfo.resourceList || [];
    this.filteredResrouceList = this.providerInfo.resourceList.filter((ele: any) => {
      ele.selectedServices = ele.selectedServices || [];
      return ele.selectedServices.includes(this.selectedService);
    });
    console.log('this.filteredResrouceList - ', this.filteredResrouceList);
    this.resourceInfo = this.filteredResrouceList[0] || {};
    this.getAllTimeSlots();
    this.selectedResourceObj = this.resourceInfo;
    this.selectedResource = this.resourceInfo._id;
    this.cdr.detectChanges();
  }

  formatDateRange(date: moment.Moment) {
    this.dateRange = this.dateRange || {};
    const startAndEnd = this.getStartAndEndDate(moment(date.toISOString()));
    this.dateRange.header = {
      month: date.format('MMMM'),
      dates: {
        start: startAndEnd.start,
        end: startAndEnd.end,
        prev: moment(startAndEnd.leftStart.toISOString()),
        next: moment(startAndEnd.end.toISOString()).add(1, 'day'),
        formattedStart: moment(startAndEnd.start.toISOString()).format('DD'),
        formattedEnd: moment(startAndEnd.end.toISOString()).format('DD'),
      },
      year: date.format('YYYY'),
    };
    console.log(this.dateRange.header.dates.start);
    console.log(this.dateRange.header.dates.end);
    this.getTimeRange(
      this.slotSize,
      moment(this.dateRange.header.dates.start.toISOString()),
      moment(this.dateRange.header.dates.end.toISOString()),
      this.startTime,
      this.endTime
    );
  }

  getStartAndEndDate(date: moment.Moment) {
    console.log(`${date.format('YYYY-MM-DD')}`);
    let count = 0;
    let end = moment(date.toISOString());
    let leftStart = moment(date.toISOString());
    while (count <= this.daysLimit) {
      end = end.add(1, 'day');
      console.log('count - ', count, end.format('YYYY-MM-DD HH:mm'));
      // if (this.serviceInfo && this.serviceInfo.availableDays && this.serviceInfo.availableDays) {
      //   const tempDays = Object.keys(this.serviceInfo.availableDays);
      //   if (tempDays.length === 0) {
      //     continue;
      //   }
      //   if (end.format("dddd").toLowerCase() && !this.serviceInfo.availableDays[end.format("dddd").toLowerCase()]) {
      //     continue;
      //   }
      // }
      count++;
    }
    count = 0;
    while (count <= this.daysLimit) {
      leftStart = leftStart.add(1, 'day');
      // console.log("count - ", count, leftStart.format("YYYY-MM-DD HH:mm"));
      // if (this.serviceInfo && this.serviceInfo.availableDays && this.serviceInfo.availableDays) {
      //   const tempDays = Object.keys(this.serviceInfo.availableDays);
      //   if (tempDays.length === 0) {
      //     continue;
      //   }
      //   if (leftStart.format("dddd").toLowerCase() && !this.serviceInfo.availableDays[leftStart.format("dddd").toLowerCase()]) {
      //     continue;
      //   }
      // }
      count++;
    }
    return {
      start: date,
      leftStart: leftStart,
      end: end.subtract(1, 'day'),
    };
  }

  getTimeRange(slot: number, start: moment.Moment, end: moment.Moment, startTime: string, endTime: string) {
    this.dateRange.range = [];
    console.log('date --- ', start.format('YYYY-MM-DD HH:mm:ss'), end.format('YYYY-MM-DD HH:mm:ss'));
    for (let i = start; i <= end; i = i.add(1, 'day')) {
      // if (this.serviceInfo && this.serviceInfo.availableDays && this.serviceInfo.availableDays) {
      //   const tempDays = Object.keys(this.serviceInfo.availableDays);
      //   if (tempDays.length === 0) {
      //     continue;
      //   }
      //   if (i.format("dddd").toLowerCase() && !this.serviceInfo.availableDays[i.format("dddd").toLowerCase()]) {
      //     continue;
      //   }
      // }
      // console.log("date --- ", i.format("YYYY-MM-DD HH:mm:ss"),  start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"));
      const tempObj: any = {};
      tempObj.date = i.toISOString();
      tempObj.formattedDate = i.format('YYYY-MM-DD');
      tempObj.weekDay = i.format('ddd');
      tempObj.times = tempObj.times || [];
      for (
        let j = moment(i.format('YYYY-MM-DD ' + startTime));
        j <= moment(i.format('YYYY-MM-DD ' + endTime));
        j = j.add(slot, 'minute')
      ) {
        tempObj.times.push({ date: j.toISOString(), selected: false, formatted: j.format('HH:mm') });
      }
      this.dateRange.range.push(tempObj);
    }
    console.log(this.dateRange);
  }

  selectSlot(time: any) {
    if (!time.selected) {
      this.currentValue = time.date;
      this.selectedSlot = time;
      this.openPreview();
    }
    // this.model = time.date;
    // this.modelChange.next(this.model);
  }

  moveDate(date: any) {
    this.selectedDate = moment(date.toISOString()).format('YYYY-MM-DD');
    this.selectedSlot = null;
    this.formatDateRange(moment(date.toISOString()));
    this.getAllTimeSlots();
    // this.updateServiceData.emit({
    //   selectedStartTime: moment(date).toISOString(),
    //   selectedEndTime: moment(date).add(this.slotSize, "hour").toISOString()
    // })
  }

  previousStep() {
    // this.next.emit();
  }

  nextStep() {
    // this.previous.emit();
  }

  selectDate(item: any) {
    this.selectedDate = item.formattedDate;
    this.getAllTimeSlots();
  }

  async getAllTimeSlots() {
    try {
      this.commonService.onLoaderChange.next(true);
      this.selectedDateSlots = [];
      if (this.selectedDate && this.resourceInfo.startTime && this.resourceInfo.endTime) {
        const tempAllSlots: any = [];
        for (
          let j = moment(moment(this.selectedDate).format('YYYY-MM-DD ' + (this.resourceInfo.startTime || '00:00:00')));
          j <= moment(moment(this.selectedDate).format('YYYY-MM-DD ' + (this.resourceInfo.endTime || '23:59:59')));
          j = j.add(this.serviceInfo.slotDuration || 15, 'minute')
        ) {
          tempAllSlots.push({
            date: j.toISOString(),
            selected: false,
            formatted: j.format('HH:mm'),
            end: moment(j.toISOString())
              .add(this.serviceInfo.slotDuration || 15, 'minute')
              .format('HH:mm'),
          });
        }

        var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'f', 'saturday'];
        var d = new Date(moment(this.selectedDate).toISOString());
        var dayName = days[d.getDay()];
        console.log(`moment(this.selectedDate).format("dddd").toLowerCase() --- `, dayName);
        let slotRange = (this.resourceInfo.slotRanges && this.resourceInfo.slotRanges[dayName]) || [];
        slotRange = slotRange || [];
        slotRange = slotRange.filter((ele: any) => ele && ele.startTime && ele.endTime);
        let availableSlots: any = [];
        slotRange.forEach((ele: any) => {
          let firstStart = tempAllSlots.filter((item: any) => ele.endTime >= item.formatted && ele.endTime <= item.end);
          firstStart = (firstStart[0] && firstStart[0].formatted) || ele.startTime;
          for (
            let j = moment(moment(this.selectedDate).format('YYYY-MM-DD ' + ele.startTime));
            j <= moment(moment(this.selectedDate).format('YYYY-MM-DD ' + ele.endTime));
            j = j.add(this.serviceInfo.slotDuration || 15, 'minute')
          ) {
            availableSlots.push({
              date: j.toISOString(),
              selected: false,
              formatted: j.format('HH:mm'),
              end: moment(j.toISOString())
                .add(this.serviceInfo.slotDuration || 15, 'minute')
                .format('HH:mm'),
            });
          }
        });
        if (this.selectedResource && this.selectedService) {
          const retData = await this.apiService.getRequest(
            `/external-page/calendar/${this.selectedResource}/${this.selectedService}?date=${this.selectedDate}`
          );

          availableSlots = availableSlots.map((ele: any) => {
            retData.forEach((item: any) => {
              const tempEnd = moment(`${moment().format('YYYY-MM-DD')} ${ele.end}:00`).format('HH:mm');
              console.log(
                'api data - ',
                ele.formatted,
                moment(item.start).format('HH:mm'),
                tempEnd,
                moment(item.end).format('HH:mm')
              );

              if (
                item &&
                ele.formatted <= moment(item.start).format('HH:mm') &&
                tempEnd >= moment(item.end).format('HH:mm')
              ) {
                ele.tempSelected = true;
                ele.selected = true;
              }
            });
            return ele;
          });
        }

        this.selectedDateSlots = tempAllSlots
          .map((ele: any) => {
            const temp = availableSlots.find((item: any) => ele.formatted === item.formatted && ele.end === item.end);
            // ele.selected = true;
            if (temp) {
              return { ...ele, ...temp };
            } else {
              return null;
            }
          })
          .filter((ele: any) => ele);
        console.log(' time slots ---- ', tempAllSlots, availableSlots, this.selectedDateSlots);
      }
      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      this.commonService.onLoaderChange.next(false);
      alert(error);
    }
  }

  onSelecteChange(type: string, item?: any) {
    setTimeout(() => {
      if (type === 'resource') {
        this.getAllTimeSlots();
      } else {
        this.serviceInfo = item;
        this.selectedService = item && item.serviceData && item.serviceData._id;
        this.getResource();
      }
    }, 100);
  }

  openPreview() {
    const allData = {
      resourceData: this.resourceInfo,
      serviceData: this.serviceInfo,
      selectedDate: this.selectedDate,
      slot: this.selectedSlot,
      webhookToken: this.providerInfo.webhookToken,
    };
    const modalRef = this.modalService.open(BookingPreviewComponent, {
      windowClass: 'happ-semi-width',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.allData = allData;
    modalRef.closed.subscribe((data) => {
      if (data === 'close') {
        // this.router
        console.log('slot --- ', this.selectedSlot);
        this.currentValue = null;
        this.getData();
      } else if (data && data.operation === 'continue') {
        this.router.navigate([`/our-services/${this.serviceInfo._id}`], {
          queryParams: {
            eventId: data.eventId,
            view: 'form',
            serviceId: this.serviceInfo._id,
            resourceId: this.selectedResource,
            date: this.selectedDate,
            slot: `${this.selectedSlot.formatted}-${this.selectedSlot.end}`,
          },
        });
      }
    });
  }
}
