<div fxLayout="row" fxLayout.lt-md="column">
  <div fxFlex="40" fxFlex.lt-md="100" style="padding: 12px; border: 1px solid #dadada">
    <h2>
      {{ serviceData.slotBookingHeading }}
    </h2>
    <br />
    <p>
      {{ serviceData.slotBookingDescription }}
    </p>
  </div>
  <div fxLayout="column" fxLayoutAlign="center start" fxFlex="60" fxFlex.lt-md="100" style="padding: 12px">
    <p style="margin: 0px">
      {{ 'externalSharedService.start_date' | translate }}
    </p>
    <input
      class="form-control"
      placeholder="yyyy-mm-dd"
      name="dp"
      (click)="start.toggle()"
      (dateSelect)="onDateSelection($event, 'fromDate', end)"
      ngbDatepicker
      #start="ngbDatepicker"
    />
    <br />
    <ng-container *ngIf="type !== 'single'">
      <p style="margin: 0px">
        {{ 'externalSharedService.end_date' | translate }}
      </p>
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        (click)="end.toggle()"
        (dateSelect)="onDateSelection($event, 'toDate')"
        ngbDatepicker
        #end="ngbDatepicker"
      />
    </ng-container>
  </div>
</div>

<div class="m-3 d-flex justify-content-between">
  <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
    {{ 'externalSharedService.previous' | translate }}
  </button>
  <button
    type="button"
    [disabled]="!fromDate || !toDate"
    (click)="nextStep()"
    class="m-2 btn btn-success btn-lg btn-block"
  >
    {{ 'externalSharedService.next' | translate }}
  </button>
</div>
