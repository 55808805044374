import { AgmMarker, MapsAPILoader } from '@agm/core';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MapSearchComponent } from '@app/@shared/components/map-search/map-search.component';
import { MatchMediaService } from '@app/@shared/services/match-media.service';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var google: any;

@Component({
  selector: 'app-s-provider',
  templateUrl: './s-provider.component.html',
  styleUrls: ['./s-provider.component.scss'],
})
export class SProviderComponent implements OnInit, OnDestroy {
  providerInfo: any;
  providerList: any = [];
  location: any;
  // view = "locationView"
  view = 'categoryView';
  varient: any;
  query = '';
  currentType: any;
  currentLang = 'en';
  myTypes: any;
  categoryList: any[] = [];

  latitude: number;
  showMap = false;

  longitude: number;

  zoom: number;
  searchText = '';
  address: string;
  geoCoder: any;
  closeResult = '';
  range = 20;

  selectedCatDoc: any;
  selectedTypeDoc: any;

  @ViewChild('search') public searchElementRef: ElementRef;
  selectedItem: any;
  private _unsubscribeAll: Subject<any>;
  isSmallScreen: boolean;
  selectedRange: any;
  itemSelected: boolean;
  rangeArray = [
    {
      title: 'externalSharedService.nearby',
      range: 100000000000000000000000000,
      zoom: 18,
    },
    {
      title: 'externalSharedService.city',
      range: 6000,
      zoom: 12,
    },
    {
      title: 'externalSharedService.country',
      range: 1500000,
      zoom: 3,
    },
  ];
  radiusLat: any;
  radiusLong: any;
  radius = 50000;
  markers: any[] = [];
  formData: any = {};
  showLocation: boolean;
  filteredType: any;

  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private mapsAPILoader: MapsAPILoader,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private ref: ChangeDetectorRef,
    private _mediaObserver: MediaObserver,
    private matchMediaService: MatchMediaService
  ) {
    this.selectedRange = this.rangeArray[0];
    this.range = this.selectedRange.range;
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async ngOnInit() {
    // this.getData();
    await this.getTypes();
    this.init();
    this.getCatData();
    if (localStorage.getItem('category')) {
      this.selectClick('category', { key: localStorage.getItem('category') });
    }

    if (localStorage.getItem('selectedType')) {
      this.selectClick('selectedType', { key: localStorage.getItem('selectedType') });
    }
    this.currentLang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe((data2: any) => {
      this.currentLang = data2;
    });

    this.matchMediaService.onMediaChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      if (this._mediaObserver.isActive('lt-md')) {
        this.isSmallScreen = true;
      } else {
        this.isSmallScreen = false;
      }
      console.log('isSmallScreen', this.isSmallScreen);
    });
  }

  async getTypes() {
    try {
      const temp = await this.apiService.getRequest(`/external-page/shared-service-types`);
      this.myTypes = temp.data || [];
    } catch (error) {
      alert(error);
    }
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      this.providerInfo = await this.commonService.getHtmlTemplate({ route: 'service_provider' });
      let url = '/external-page/all-providers';
      if (this.varient === 'resource') {
        url = '/external-page/all-resources';
      }
      this.providerList = await this.apiService.getRequest(
        `${url}?type=${this.currentType || null}&lat=${this.latitude}&long=${this.longitude}&range=${this.range}`
      );

      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }

  initAutoComplete() {
    console.log('this.searchElementRef', this.searchElementRef, document.getElementById('mapSearch'));
    // if (!this.searchElementRef) {
    //   return;
    // }
    let autocomplete = new google.maps.places.Autocomplete(document.getElementById('mapSearch'));

    autocomplete.addListener('place_changed', () => {
      console.log('on changing value');
      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        console.log('place.geometry - ', place);

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.latitude = place.geometry.location.lat();

        this.longitude = place.geometry.location.lng();
        this.address = place.formatted_address;

        this.zoom = 12;
      });
    });
  }

  init() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();

      this.geoCoder = new google.maps.Geocoder();

      console.log('geoCoder - ', this.geoCoder);

      this.initAutoComplete();
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;

        this.longitude = position.coords.longitude;

        this.zoom = 12;

        this.getData();
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      console.log('res --- ', results);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;

          this.address = results[0].formatted_address;
          setTimeout(() => {
            this.searchText = results[0].formatted_address;
          }, 200);
          console.log('address - ', this.address);
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  markerClicked(ele: AgmMarker, item: any) {
    this.itemSelected = true;
    this.selectedItem = item._id;
    let el = document.getElementById(item._id);
    el.scrollIntoView();
  }

  open(content: any, externalType?: string) {
    this.ref.detectChanges();
    setTimeout(() => {
      this.ref.detectChanges();
      this.initAutoComplete();
    }, 1000);
    if (externalType) {
      if (externalType === 'search') {
        const tempRef = this.modalService.open(MapSearchComponent, {
          windowClass: 'happ-full-width',
          ariaLabelledBy: 'modal-basic-title',
        });
        tempRef.componentInstance.showLocation = this.showLocation;
        tempRef.componentInstance.range = this.range;
        tempRef.componentInstance.selectedRange = this.selectedRange;
        tempRef.result.then(
          (result) => {
            this.getData();
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      }
    } else {
      this.modalService
        .open(content, { windowClass: 'happ-full-width', ariaLabelledBy: 'modal-basic-title' })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  radiusDragEnd($event: any) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.showHideMarkers();
  }

  event(type: any, $event: any) {
    console.log(type, $event);
    this.range = $event;
    this.showHideMarkers();
  }

  showHideMarkers() {
    this.getData();
  }

  getCatData() {
    this.apiService
      .getRequest(`/global-var/shared-service-category`)
      .then((data) => {
        this.categoryList = (data && data.data) || [];
        console.log('this.cat', this.categoryList);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getDistanceBetween(lat1: any, long1: any, lat2: any, long2: any) {
    var from = new google.maps.LatLng(lat1, long1);
    var to = new google.maps.LatLng(lat2, long2);

    if (google.maps.geometry.spherical.computeDistanceBetween(from, to) <= this.radius) {
      console.log('Radius', this.radius);
      console.log('Distance Between', google.maps.geometry.spherical.computeDistanceBetween(from, to));
      return true;
    } else {
      return false;
    }
  }

  selectClick(type: any, item: any) {
    this.formData[type] = item.key;
    localStorage.setItem(type, item.key);
    switch (type) {
      case 'selectedType':
        const temp2 = this.myTypes.find((ele: any) => ele && ele.key === item.key);
        this.selectedTypeDoc = temp2;
        this.view = 'locationView';
        this.showLocation = false;
        if (temp2.allowLocation) {
          this.showLocation = true;
          this.range = temp2.range;
          this.init();
        } else {
          this.getData();
        }

        break;
      case 'category':
        const temp = this.categoryList.find((ele) => ele && ele.key === item.key);
        this.selectedCatDoc = temp;
        this.myTypes = this.myTypes || [];
        console.log('filteredType - ', item.key, this.myTypes);
        this.filteredType = this.myTypes.filter(
          (ele: any) => ele && ele.category && Array.isArray(ele.category) && ele.category.includes(item.key)
        );
        // this.view = 'typeView';
        break;
    }
  }

  resetAll() {
    this.filteredType = [];
    localStorage.removeItem('category');
    localStorage.removeItem('selectedType');
  }
}
