import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialistFormComponent } from './specialist-form.component';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'specialist-register', component: SpecialistFormComponent, data: { title: marker('Service Register') } },
  ]),
];

@NgModule({
  declarations: [SpecialistFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FlexModule,
    TranslateModule,
    NgbModule,
    GlobalPipesModule,
    RouterModule.forChild(routes),
  ],
})
export class SpecialistFormModule {}
