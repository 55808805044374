import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
  @ViewChild('end') end: any;

  @Input() model: string;
  @Input() type: string;

  @Input() serviceData: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() updateServiceData: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: any | null = null;

  constructor() {}

  ngOnInit(): void {
    if (this.serviceData) {
      if (this.serviceData.selectedStartTime) {
        this.fromDate = this.serviceData.selectedStartTime;
      }
      if (this.serviceData.selectedEndTime) {
        this.toDate = this.serviceData.selectedEndTime;
      }
    }
    console.log('init date ', this.fromDate, this.toDate);
  }

  onDateSelection(eve: NgbDate, type: string, next?: any) {
    this[type] = eve;

    if (next && this.type !== 'single') {
      next.toggle();
    }

    if (this.type === 'single' && this.fromDate) {
      this.toDate = moment(`${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day} 23:59:59`).toISOString();
      this.updateServiceData.emit({
        selectedStartTime: moment(
          `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day} 00:00:00`
        ).toISOString(),
        selectedEndTime: moment(
          `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day} 23:59:59`
        ).toISOString(),
      });
    } else if (this.fromDate && this.toDate) {
      this.updateServiceData.emit({
        selectedStartTime: moment(`${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`).toISOString(),
        selectedEndTime: moment(`${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`).toISOString(),
      });
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  previousStep() {
    this.next.emit();
  }

  nextStep() {
    this.previous.emit();
  }
}
