<div class="appointment-popup" *ngIf="dateRange" fxLayout="row" fxLayout.lt-md="column">
  <div class="appointment-calendar" fxFlex="60" fxFlex.lt-md="100">
    <div fxLayout="row" fxLayout.lt-md="column" class="current-week" fxLayoutAlign="space-between center">
      <div>
        <span
          ><i class="fa fa-calendar"></i> {{ dateRange.header.year }}, {{ dateRange.header.month }}
          {{ dateRange.header.dates.formattedStart }} - {{ dateRange.header.dates.formattedEnd }}</span
        >
      </div>
      <div class="calendar-nav" fxLayoutAlign="center center">
        <button type="button" (click)="moveDate(dateRange.header.dates.prev)" class="prev">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <button type="button" (click)="moveDate(dateRange.header.dates.next)" class="next">
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="calendar-wrapper">
      <div class="calendar-week">
        <ul>
          <li *ngFor="let item of dateRange.range">{{ item.weekDay }}</li>
        </ul>
      </div>

      <div class="calendar-hours" style="height: calc(100vh - 450px) !important">
        <ul>
          <li *ngFor="let item of dateRange.range">
            <ul>
              <li
                (click)="selectSlot(time)"
                *ngFor="let time of item.times"
                [class]="time.date === currentValue ? 'active' : ''"
              >
                {{ time.formatted }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    fxFlex="40"
    fxFlex.lt-md="100"
    style="padding: 12px; border-left: 1px solid #dadada; border-top: 1px solid rgb(218, 218, 218)"
  >
    <h2>
      {{ serviceData.slotBookingHeading }}
    </h2>
    <br />
    <p>
      {{ serviceData.slotBookingDescription }}
    </p>
  </div>
</div>
<div class="m-3 d-flex justify-content-between">
  <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
    {{ 'externalSharedService.previous' | translate }}
  </button>
  <button type="button" [disabled]="!model" (click)="nextStep()" class="m-2 btn btn-success btn-lg btn-block">
    {{ 'externalSharedService.next' | translate }}
  </button>
</div>
