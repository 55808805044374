import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentResultComponent } from './payment-result.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'payment/success', component: PaymentResultComponent, data: { title: marker('Payment result') } },
  ]),
];

@NgModule({
  declarations: [PaymentResultComponent],
  imports: [CommonModule, GlobalPipesModule, FlexLayoutModule, TranslateModule, RouterModule.forChild(routes)],
})
export class PaymentResultModule {}
