import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SPlatformComponent } from './s-platform.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

const routes: Routes = [
  Shell.childRoutes([{ path: 'platform', component: SPlatformComponent, data: { title: marker('Platform') } }]),
];

@NgModule({
  declarations: [SPlatformComponent],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes)],
})
export class SPlatformModule {}
