<header class="header" *ngIf="eventData">
  <div class="row main-background happ-mb-div-1" style="margin: 0px; background: #f3f3f3; margin-top: 0px">
    <div class="col-1 happ-mb-div-2" style="background: #e8e8e8"></div>
    <div class="row col-12 col-sm-10 mt-0 happ-mb-div-3" style="background: #e8e8e8">
      <!-- text div -->
      <div id="res-name-text" class="col-sm-9 col-12" style="margin-top: 12px">
        <h2>
          <strong>{{ eventData.resName }}</strong>
        </h2>
        <p>{{ eventData.resDesc }}</p>
      </div>
      <div id="heading-action" class="col-12 col-sm-3" style="background: #f3f3f3">
        <div class="text-center">
          <div class="button-email">
            <a (click)="register()" class="btn btn-warning btn-block"
              ><i class="fa fa-envelope-o"></i> {{ 'externalSharedService.register' | translate }}</a
            >
          </div>
          <span class="number"
            ><strong> {{ eventData.signatura }} </strong></span
          >
        </div>
      </div>
    </div>
    <div class="col-sm-1" style="background: #f3f3f3"></div>
  </div>

  <div class="container"></div>
</header>

<div class="row">
  <div class="col-1"></div>
  <div class="col-12 col-sm-10" *ngIf="eventData">
    <div *ngIf="status === 'success'" class="alert alert-info" role="alert" style="text-align: center; font-size: 26px">
      {{ 'externalSharedService.payment_success_info' | translate }}
    </div>

    <div class="row" *ngIf="cardsConfig.description">
      <div class="col-sm-12">
        <div class="card card-block text-xs-left" style="padding: 12px">
          <h3 class="happ-head">
            <i class="fas fa-info-circle"></i>{{ 'externalSharedService.description' | translate }}
          </h3>

          <div [innerHtml]="eventData.description | htmlWithStyle"></div>
        </div>
      </div>
    </div>
    <div *ngIf="cardsConfig.program" class="row">
      <div class="col-sm-12">
        <div class="card card-block text-xs-left" style="padding: 12px">
          <h3 class="happ-head"><i class="fas fa-list-ol"></i>{{ 'externalSharedService.program' | translate }}</h3>

          <div [innerHtml]="eventData.program | htmlWithStyle"></div>
        </div>
      </div>
    </div>
    <div *ngIf="cardsConfig.when" class="row">
      <div class="col-sm-12">
        <div class="card card-block text-xs-left" style="padding: 12px">
          <h3 class="happ-head"><i class="fas fa-clock"></i>{{ 'externalSharedService.when' | translate }}</h3>

          <div [innerHtml]="eventData.when | htmlWithStyle"></div>
        </div>
      </div>
    </div>
    <div *ngIf="cardsConfig.who" class="row">
      <div class="col-sm-12">
        <div class="card card-block text-xs-left" style="padding: 12px">
          <h3 class="happ-head"><i class="fa fa-user fa-fw"></i>{{ 'externalSharedService.who' | translate }}</h3>

          <div [innerHtml]="eventData.who | htmlWithStyle"></div>
        </div>
      </div>
    </div>
    <div *ngIf="cardsConfig.additional_info" class="row">
      <div class="col-sm-12">
        <div class="card card-block text-xs-left" style="padding: 12px">
          <h3 class="happ-head">
            <i class="fas fa-info-circle"></i>{{ 'externalSharedService.additional_info' | translate }}
          </h3>

          <div [innerHtml]="eventData.additional_info | htmlWithStyle"></div>
        </div>
      </div>
    </div>

    <div *ngIf="cardsConfig.location" class="row">
      <div class="col-sm-12">
        <div class="card card-block text-xs-left" style="padding: 12px">
          <h3 class="happ-head"><i class="fas fa-map-signs"></i>{{ 'externalSharedService.location' | translate }}</h3>
          <div fxLayout="row">
            <div style="width: 70%">
              <ng-container *ngIf="eventData && eventData.location && eventData.location.coordinates">
                <agm-map
                  [style.height]="'300px'"
                  style="width: 100%"
                  [zoom]="18"
                  [latitude]="eventData.location.coordinates[1]"
                  [longitude]="eventData.location.coordinates[0]"
                >
                  <agm-marker
                    [latitude]="eventData.location.coordinates[1]"
                    [longitude]="eventData.location.coordinates[0]"
                    [markerDraggable]="true"
                  ></agm-marker>
                </agm-map>

                <a
                  target="_blank"
                  [href]="
                    'https://maps.google.com/?q=' +
                    eventData.location.coordinates[1] +
                    ',' +
                    eventData.location.coordinates[0]
                  "
                >
                  {{ 'externalSharedService.open_in_google_map' | translate }}
                </a>
              </ng-container>
            </div>
            <div
              fxLayout="column"
              style="width: 300px; padding: 12px"
              *ngIf="eventData && eventData.location && eventData.location.coordinates"
            >
              <h3 class="mb-0">Address: {{ eventData.selectedAddress }}</h3>

              <div>Latitude: {{ eventData.location.coordinates[1] }}</div>

              <div>Longitude: {{ eventData.location.coordinates[0] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
  </div>
  <div class="col-1"></div>
</div>
