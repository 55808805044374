import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialistListComponent } from './specialist-list.component';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/@shared';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SpecialistDetailPopupModule } from '../specialist-detail-popup/specialist-detail-popup.module';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'specialist-list', component: SpecialistListComponent, data: { title: marker('Service List') } },
  ]),
];

@NgModule({
  declarations: [SpecialistListComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FlexModule,
    TranslateModule,
    NgbModule,
    SpecialistDetailPopupModule,
    GlobalPipesModule,
    RouterModule.forChild(routes),
  ],
})
export class SpecialistListModule {}
