// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

export const environment = {
  production: true,
  version: '1.0.0',
  serverUrl: '{{BACKEND_SERVER_URL}}api',
  pinbox24MainFront: 'https://v41-stage.dev.fullpentest.com/',
  defaultLanguage: 'en-US',
  googleMapKey: 'AIzaSyBFsPmLE2vm5ZD48xN7ZWws6MP8SHjM3Ug',
  supportedLanguages: ['en-US', 'fr-FR'],
};
