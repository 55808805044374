import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedServiceCreateComponent } from './shared-service-create.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedServiceCreateRouting } from './shared-service-create-routing.module';
import { FormsModule } from '@angular/forms';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';

@NgModule({
  declarations: [SharedServiceCreateComponent],
  imports: [CommonModule, TranslateModule, SharedServiceCreateRouting, FormsModule, GlobalPipesModule],
})
export class SharedServiceCreateModule {}
