import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { TranslationLoaderService } from '@app/helpers/translateLoader.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-shared-service-create',
  templateUrl: './shared-service-create.component.html',
  styleUrls: ['./shared-service-create.component.scss'],
})
export class SharedServiceCreateComponent implements OnInit {
  phoneVerified = false;

  frontUrl = environment.pinbox24MainFront;

  formData: any = {
    type: '',
    description: '',
    webhookToken: 'db00d41c197fd9e9b22830364649c9ec8f2f7d63f5f16d527259c7f5543842c2',
    email: '',
    phoneNo: '',
  };

  otpStatus = '';
  otp: any;

  userDetail: any = {};
  providerInfo: any = {};
  otpSent = false;
  types: any = [];
  view: string;
  extraData: any;

  constructor(
    private apiHelper: ApiService,
    private commonHelper: CommonService,
    private route: ActivatedRoute,
    private translationLoader: TranslationLoaderService
  ) {}

  ngOnInit(): void {
    this.commonHelper
      .getTrans('externalSharedService')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      })
      .catch((err) => {
        console.info(err);
      });
    this.getTypes();
    const temp = this.route.snapshot.queryParams;
    this.formData = Object.assign(this.formData, temp);
    this.getProviderInfo();
    if (this.formData.email) {
      this.getUserDetail();
    }
  }

  async getTypes() {
    try {
      const temp = await this.apiHelper.getRequest(`/shared-service/getTypes`, {
        'webhook-token': this.formData.webhookToken,
      });
      this.types = (temp && temp.data) || [];
    } catch (error) {
      alert(error);
    }
  }

  async getProviderInfo() {
    if (this.formData.webhookToken) {
      try {
        this.providerInfo = await this.apiHelper.getRequest(`/webook/about-provider`, {
          'webhook-token': this.formData.webhookToken,
        });
      } catch (error) {
        alert(error);
      }
    }
  }

  async sendOtp() {
    if (this.formData.phoneNo) {
      this.commonHelper.onLoaderChange.next(true);
      try {
        await this.apiHelper.postRequest(`/auth/send-otp`, { phoneNo: this.formData.phoneNo, lang: 'en' });
        this.otpSent = true;
        this.otpStatus = 'OTP_SENDED';
        this.commonHelper.onLoaderChange.next(false);
      } catch (error) {
        this.commonHelper.onLoaderChange.next(false);
      }
    }
  }

  async verifyOtp() {
    if (this.formData.phoneNo && this.otp) {
      try {
        this.commonHelper.onLoaderChange.next(true);
        await this.apiHelper.postRequest(`/auth/verify-otp`, { phoneNo: this.formData.phoneNo, otp: this.otp });
        this.otpStatus = 'OTP_VERIFIED';
        this.phoneVerified = true;
        this.userDetail = this.userDetail || {};
        this.userDetail.phoneNoVerified = true;
        this.commonHelper.onLoaderChange.next(false);
      } catch (error) {
        this.otpStatus = 'INVALID_OTP';
        this.commonHelper.onLoaderChange.next(false);
      }
    }
  }

  async getUserDetail() {
    if (this.formData.email) {
      try {
        this.commonHelper.onLoaderChange.next(true);
        this.userDetail = await this.apiHelper.getRequest(`/pinbox24-profile/${this.formData.email}`, {});
        console.log(this.userDetail);
        this.commonHelper.onLoaderChange.next(false);
        if (this.userDetail.phoneNoVerified) {
          this.phoneVerified = true;
        }
      } catch (error) {
        this.commonHelper.onLoaderChange.next(false);
        console.error(error);
      }
    }
  }

  async submit() {
    if (this.formData.email) {
      this.view = 'table';
      try {
        this.commonHelper.onLoaderChange.next(true);
        this.extraData = await this.apiHelper.postRequest(`/webook/start-shared-service`, this.formData, {
          'webhook-token': this.formData.webhookToken,
        });
        this.commonHelper.onLoaderChange.next(false);
        // alert("Service Started");
        this.view = 'success';
      } catch (error) {
        this.commonHelper.onLoaderChange.next(false);
        this.view = 'error';
        this.extraData = error;
        // alert(error);
      }
    } else {
      alert('Email is required');
    }
  }
}
