import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfficeContactComponent } from './office-contact.component';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'contact', component: OfficeContactComponent, data: { title: marker('Contact') } },
    // { path: ':officeId/contact', component: OfficeContactComponent, data: { title: marker('Contact') } },
  ]),
];

@NgModule({
  declarations: [OfficeContactComponent],
  imports: [CommonModule, GlobalPipesModule, RouterModule.forChild(routes)],
})
export class OfficeContactModule {}
