import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecialistDetailPopupComponent } from '../specialist-detail-popup/specialist-detail-popup.component';

@Component({
  selector: 'app-specialist-list',
  templateUrl: './specialist-list.component.html',
  styleUrls: ['./specialist-list.component.scss'],
})
export class SpecialistListComponent implements OnInit {
  providerList: any;
  catData: any;
  ssType: any;
  selectedType: any;
  mappedData: any;
  selectedTypeData: any;
  providerInfo: any;

  constructor(private commonService: CommonService, private modalService: NgbModal, private apiService: ApiService) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    try {
      // this.commonService.onLoaderChange.next(true);
      // this.providerInfo = await this.commonService.getHtmlTemplate({ route: 'service_provider' });
      // let url = "/external-page/all-providers";
      // if (this.varient === "resource") {
      //   url = "/external-page/all-resources"
      // }
      // this.providerList = await this.apiService.getRequest(`${url}?type=${this.currentType || null}&lat=${this.latitude}&long=${this.longitude}&range=${this.range}`);

      // this.commonService.onLoaderChange.next(false);
      this.commonService.onLoaderChange.next(true);
      this.providerList = await this.apiService.getRequest(`/shared-service/available-services/specialist`);
      const data = await this.apiService.getRequest(`/global-var/shared-service-category`);
      this.catData = (data && data.data) || [];
      const data2 = await this.apiService.getRequest(`/external-page/shared-service-types`);
      this.ssType = (data2 && data2.data) || [];
      this.mappedData = this.catData.map((ele: any) => {
        ele.allTypes = ele.allTypes || [];
        this.ssType.forEach((ele2: any) => {
          if (ele && ele.key && ele2.category && ele2.category.includes(ele.key)) {
            ele.allTypes.push(ele2);
          }
        });
        return ele;
      });
      this.selectedType = this.mappedData[0];
      this.selectedTypeData = this.ssType[0];
      this.commonService.onLoaderChange.next(false);
      // this.staticSericeList = this.serviceList;
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }

  openProvider(item: any) {
    const allData = {
      data: item,
    };
    const modalRef = this.modalService.open(SpecialistDetailPopupComponent, {
      windowClass: 'happ-semi-width',
      ariaLabelledBy: 'modal-basic-title',
    });
    modalRef.componentInstance.allData = allData;
    modalRef.closed.subscribe((data) => {
      if (data === 'close') {
      } else if (data && data.operation === 'continue') {
      }
    });
  }
}
