<div class="d-flex align-items-center">
  <div [class]="providerInfo && providerInfo.useContainerClass === 'yes' ? 'container' : ''">
    <div *ngIf="providerInfo && providerInfo.html" [innerHTML]="providerInfo.html | htmlWithStyle"></div>
    <div style="margin-top: 30px">
      <div class="form-group">
        <input
          style="
            font-size: 24px;
            background: lightyellow;
            text-align: center;
            max-width: 600px;
            margin: auto;
            margin-bottom: 26px;
          "
          [placeholder]="'externalSharedService.search' | translate"
          type="email"
          [(ngModel)]="query"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
    </div>
    <div class="row">
      <ul class="list-group">
        <li
          style="display: flex; border-bottom: 1px solid #c7c4c4; margin-bottom: 10px; border: 1px solid #c7c4c4"
          *ngFor="let item of serviceList | fullTextSearch: query:['serviceName']"
          class="list-group-item clearfix"
        >
          <div>
            <img
              style="width: 124px; height: 124px"
              class="d-none d-md-block img-responsive img-rounded"
              *ngIf="item.img"
              [src]="item.img"
            />
            <img
              style="width: 124px; height: 124px"
              class="d-none d-md-block img-responsive img-rounded"
              *ngIf="!item.img"
              src="https://pinbox24.com/wp-content/uploads/2020/08/cropped-Mock-2rescale30pxhigh-e1605388640872.png"
            />
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              text-align: left;
              margin-left: 20px;
              width: 100%;
              justify-content: space-between;
            "
          >
            <h3 class="list-group-item-heading">
              {{ item.serviceName }}
            </h3>
            <div class="d-flex flex-row happ-tag-container">
              <span *ngIf="item.serviceData && item.serviceData.lang">
                {{ item.serviceData.lang }}
              </span>
              <span *ngIf="item.serviceData && item.serviceData.type">
                {{ item.serviceData.type }}
              </span>
            </div>
            <p class="list-group-item-text lead">
              {{
                item.serviceData && item.serviceData.recordData && item.serviceData.recordData.clientShortDescription
              }}
            </p>
            <div class="btn-toolbar pull-right d-flex justify-content-between" role="toolbar" aria-label="">
              <span></span>
              <a
                [href]="'https://' + item.providerOfficeId + '.pages.pinbox24.com/our-services/' + item._id"
                target="_blank"
                class="btn btn-outline-primary"
              >
                {{ 'externalSharedService.move_to_provider_panel' | translate }}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
