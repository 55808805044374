<div class="modal-header" style="background: darkblue" *ngIf="view !== 'payment'">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
    <button type="button" class="btn btn-light" (click)="cancelClick()">
      {{ 'externalSharedService.cancel' | translate }}
    </button>
    <div [ngSwitch]="view">
      <div *ngSwitchCase="'person'">
        <button type="button" class="btn btn-warning" [disabled]="!singeForm.valid" (click)="continue()">
          {{ 'externalSharedService.continue' | translate }}
        </button>
      </div>
      <div *ngSwitchCase="'paymentSelection'">
        <button type="button" class="btn btn-warning" [disabled]="paymentSelected.length === 0" (click)="continue()">
          {{ 'externalSharedService.continue' | translate }}
        </button>
      </div>
      <div *ngSwitchDefault>
        <button *ngIf="view !== 'optionSelect'" type="button" class="btn btn-warning" (click)="continue()">
          {{ 'externalSharedService.continue' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-header" style="background: darkblue" *ngIf="view === 'payment'">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
    <button type="button" class="btn btn-light" (click)="cancelClick()">
      {{ 'externalSharedService.cancel_order' | translate }}
    </button>
    <button type="button" class="btn btn-warning" (click)="pay()">
      {{ 'externalSharedService.paynow' | translate }}
    </button>
  </div>
</div>
<div class="modal-body">
  <!-- <p> {{ 'externalSharedService.you_will_lose_data' | translate }} </p> -->
  <div style="height: 400px" fxLayout="column" fxLayoutAlign="center center" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p>
      {{ loadingText | translate }}
    </p>
  </div>
  <div *ngIf="allData && !loading">
    <h2 style="padding-bottom: 12px; border-bottom: 2px solid; margin-bottom: 20px">
      {{ headingInfo | translate }}
    </h2>
    <div [ngSwitch]="view">
      <div *ngSwitchCase="'person'">
        <ng-container *ngIf="formReady">
          <form [formGroup]="singeForm">
            <ng-container *ngFor="let item of registerForm">
              <ng-container [ngSwitch]="item.type">
                <ng-container *ngSwitchCase="'dropdown'">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{ item.title | translate }}</label>
                    <select
                      [ngClass]="{ 'error-input': singeForm.get(item.key).errors }"
                      [formControlName]="item.key"
                      class="form-control"
                      aria-label="Default select example"
                    >
                      <!-- <select [(ngModel)]="registerData['user' + user][item.key]"  class="form-control" aria-label="Default select example"> -->
                      <option *ngFor="let option of item.options" [value]="option.key">
                        {{ option.title | translate }}
                      </option>
                    </select>
                    <!-- <p *ngIf="singeForm.get(item.key).errors" style="color: red;">
                                            {{ 'externalSharedService.required' | translate }}
                                        </p> -->
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'input'">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{ item.title | translate }}</label>
                    <input
                      [ngClass]="{ 'error-input': singeForm.get(item.key).errors }"
                      autofocus="true"
                      [formControlName]="item.key"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                    <!-- <p *ngIf="singeForm.get(item.key).errors" style="color: red;">
                                            {{ 'externalSharedService.required' | translate }}
                                        </p> -->
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'phoneNumber'">
                  <div class="form-group">
                    <label for="exampleInputEmail1">{{ item.title | translate }}</label>
                    <!-- <input autofocus="true" [formControlName]="item.key" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" > -->
                    <ngx-intl-tel-input
                      [ngClass]="{ 'error-input': singeForm.get(item.key).errors }"
                      [cssClass]="''"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [separateDialCode]="true"
                      name="phone"
                      [formControlName]="item.key"
                    >
                      <!-- <p *ngIf="singeForm.get(item.key).errors" style="color: red;">
                                            {{ 'externalSharedService.required' | translate }}
                                        </p> -->
                    </ngx-intl-tel-input>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </form>
        </ng-container>
        <br />
      </div>

      <div *ngSwitchCase="'optionSelect'">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="happ-sele-item"
            (click)="this.coupleApply = false; continue()"
            style="min-width: 300px"
          >
            <i style="font-size: 200px; color: gray" class="fas fa-user"></i>
            {{ 'externalSharedService.single' | translate }}
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            class="happ-sele-item"
            (click)="this.coupleApply = true; continue()"
            style="min-width: 300px"
          >
            <i style="font-size: 200px; color: gray" class="fas fa-user-friends"></i>
            {{ 'externalSharedService.couple' | translate }}
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'paymentSelection'">
        <p class="mb-2">
          {{ 'externalSharedService.payment_selection_desc' | translate }}
        </p>

        <table
          *ngIf="
            allData &&
            allData.resourceData &&
            allData.resourceData.paymentOptions &&
            allData.resourceData.paymentOptions.length
          "
        >
          <tr>
            <th style="width: 100%">
              {{ 'externalSharedService.name' | translate }}
            </th>
            <th style="min-width: 60px" class="text-center">
              {{ 'externalSharedService.price' | translate }}
            </th>
            <th style="min-width: 60px" class="text-center">
              {{ 'externalSharedService.qty' | translate }}
            </th>
            <th style="min-width: 60px" class="text-center">
              {{ 'externalSharedService.tax' | translate }}
            </th>
            <th style="min-width: 60px" class="text-right">
              {{ 'externalSharedService.selected' | translate }}
            </th>
          </tr>
          <tr *ngFor="let item of allData.resourceData.paymentOptions">
            <td>
              {{ item.name }}
            </td>
            <td class="text-center">
              {{ item.price }}
            </td>
            <td class="text-center">
              {{ this.coupleApply ? 2 : 1 }}
            </td>
            <td class="text-center">
              {{ item.tax || 0 }}
            </td>
            <td class="text-right">
              <!-- <input type="number" [(ngModel)]="item.qty" [placeholder]="'externalSharedService.qty' | translate" > -->
              <button
                style="width: 100%"
                type="button"
                class="btn btn-warning"
                *ngIf="!item.selected"
                (click)="tooglePayment(item)"
              >
                {{ 'externalSharedService.select' | translate }}
              </button>
              <button
                style="width: 100%"
                type="button"
                class="btn btn-primary"
                *ngIf="item.selected"
                (click)="tooglePayment(item)"
              >
                {{ 'externalSharedService.selected' | translate }}
              </button>
            </td>
          </tr>
        </table>
        <div
          fxLayout="row"
          fxLayoutAlign="space-around center"
          *ngIf="
            !(
              allData &&
              allData.resourceData &&
              allData.resourceData.paymentOptions &&
              allData.resourceData.paymentOptions.length
            )
          "
        >
          {{ 'externalSharedService.no_options_defined' | translate }}
        </div>
      </div>

      <div *ngSwitchCase="'payment'">
        <div style="text-align: center">
          <p *ngIf="orderDetail && orderDetail._id">
            {{ 'externalSharedService.your_order' | translate }} {{ orderDetail && orderDetail._id }}
          </p>
          <p *ngIf="orderDetail && orderDetail._id" style="font-size: 34px; font-weight: 700">
            {{ 'externalSharedService.to_pay' | translate }}
            {{ orderDetail && orderDetail.totalOrder | number: '.2-2' }}
          </p>
          <p *ngIf="orderDetail && orderDetail._id" style="margin-bottom: 20px">
            {{ 'externalSharedService.your_order_will_be_processed_by' | translate }}
          </p>
        </div>

        <h5 class="p-0">
          {{ 'externalSharedService.order_detail' | translate }}
        </h5>
        <table *ngIf="orderDetail.selectedVariant">
          <tr>
            <th style="width: 100%">
              {{ 'externalSharedService.prod_name' | translate }}
            </th>
            <th style="min-width: 60px" class="text-center">
              {{ 'externalSharedService.qty' | translate }}
            </th>
            <th style="min-width: 60px" class="text-center">
              {{ 'externalSharedService.tax' | translate }}
            </th>
            <th style="min-width: 60px" class="text-right">
              {{ 'externalSharedService.price' | translate }}
            </th>
          </tr>
          <tr *ngFor="let item of orderDetail.selectedVariant">
            <td>
              {{ item.name }}
            </td>
            <td style="min-width: 60px" class="text-center">
              {{ item.qty }}
            </td>
            <td style="min-width: 60px" class="text-center">{{ item.tax }}%</td>
            <td style="min-width: 60px" class="text-right">
              {{ item.price }}
            </td>
          </tr>
          <tr>
            <td colspan="1" class="text-right">
              <p style="font-size: 18px; font-weight: 600">
                <span style="padding-right: 8px">{{ 'externalSharedService.total_sum' | translate }}</span>
              </p>
            </td>
            <td colspan="3" class="text-right">
              <p style="font-size: 18px; font-weight: 600">
                {{ orderDetail && orderDetail.totalOrder | number: '.2-2' }}
              </p>
            </td>
          </tr>
        </table>
        <br />
        <h5 style="margin-top: 16px; margin-bottom: 14px">
          {{ 'externalSharedService.order_data' | translate }}
        </h5>
        <table>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.platform' | translate }}
            </td>
            <td>pinbox24.com</td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.seller_name' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.providerOfficeId }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.seller_id' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.providerOfficeId }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.buyer_email' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.creatorEmail }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.buyer_id' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.clientId }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.currency' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.selectedVariant && orderDetail.selectedVariant[0].currency }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.order_id' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail._id }}
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ 'externalSharedService.order_date' | translate }}
            </td>
            <td>
              {{ orderDetail && orderDetail.registredDataTime }}
            </td>
          </tr>
        </table>
        <br />
      </div>

      <div *ngSwitchDefault>default</div>
    </div>
  </div>
</div>
