<div style="overflow: auto">
  <div
    fxLayout="column"
    class="happ-provider-header"
    style="background: #3d6277; color: white; font-size: 34px; padding-top: 42px; padding-bottom: 42px"
    fxLayoutAlign="center center"
  >
    <div fxLayout="row" fxLayoutAlign="center center">
      <i
        *ngIf="formData.category"
        (click)="view = 'categoryView'; formData.category = null; formData.selectedType = null; resetAll()"
        class="fa fa-home"
        aria-hidden="true"
      ></i>
      <ng-container *ngIf="formData.category">
        <span class="mr-1 ml-1">></span>
        <p (click)="view = 'typeView'" *ngIf="formData.category">
          {{ formData.category }}
        </p>
      </ng-container>
      <ng-container *ngIf="formData.selectedType">
        <span class="mr-1 ml-1">></span>
        <p *ngIf="formData.selectedType">
          {{ formData.selectedType }}
        </p>
      </ng-container>
    </div>
    <ng-container *ngIf="formData.category && formData.selectedType">
      <div style="font-size: 12px">
        <p>
          {{ 'externalSharedService.your_location' | translate }}: {{ address }} |
          {{ 'externalSharedService.we_looking_in_range' | translate }}
          {{ selectedRange && selectedRange.title | translate }}
        </p>
        <a (click)="open(null, 'search')" style="cursor: pointer">
          {{ 'externalSharedService.change_location_or_range' | translate }}
        </a>
      </div>
    </ng-container>
  </div>
  <div [ngSwitch]="view">
    <div *ngSwitchCase="'categoryView'">
      <section class="service-categories text-xs-center" style="border-bottom: 1px solid white; padding-bottom: 8em">
        <div class="container">
          <div class="row">
            <div *ngFor="let item of categoryList" class="col-md-3" (click)="selectClick('category', item)">
              <a>
                <div class="card service-card card-inverse mb-4" [ngClass]="{ active: item.key === formData.category }">
                  <div class="card-block">
                    <span style="min-height: 48px" [class]="'fa ' + (item.icon || 'fa-briefcase') + ' fa-3x'"></span>
                    <div fxLayoutAlign="center center">
                      <h4 class="card-title">{{ item.title | textTranslate: currentLang }}</h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!--End Row-->
        </div>
      </section>
      <section class="service-categories service-categories-2 text-xs-center">
        <div fxLayoutAlign="center center" *ngIf="!filteredType || (filteredType && filteredType.length === 0)">
          <h2>
            {{ 'externalSharedService.please_select_category_from_top' | translate }}
          </h2>
        </div>
        <div *ngIf="filteredType && filteredType.length" style="margin-left: 2em; margin-right: 2em">
          <div class="row">
            <ng-container *ngFor="let item of myTypes">
              <div
                *ngIf="formData.category === item.category && formData.category"
                class="col-md-2"
                (click)="selectClick('selectedType', item)"
              >
                <a>
                  <div
                    class="card service-card card-inverse mb-4"
                    [ngClass]="{ active: item.key === formData.selectedType }"
                  >
                    <div class="card-block">
                      <span style="min-height: 48px" [class]="'fa ' + (item.icon || 'fa-briefcase') + ' fa-3x'"></span>
                      <div fxLayoutAlign="center center">
                        <h4 class="card-title">
                          {{ item.title | textTranslate: currentLang }} {{ item.category | json }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container>
          </div>
          <!--End Row-->
        </div>
      </section>
    </div>
    <div *ngSwitchCase="'locationView'">
      <br />
      <br />
      <div class="d-flex align-items-center">
        <div class="container">
          <div class="row" *ngIf="providerList && providerList.length; else noProvider">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-bottom: 20px; width: 100%">
              <div class="form-group m-0">
                <input
                  style="font-size: 24px; background: lightyellow; text-align: center; max-width: 600px; margin: auto"
                  [placeholder]="'externalSharedService.search' | translate"
                  type="text"
                  [(ngModel)]="query"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <button (click)="showMap = true; open(content)" type="button" class="btn btn-outline-dark mr-1">
                {{ 'externalSharedService.show_map' | translate }}
              </button>
            </div>
            <ul class="list-group">
              <li
                style="display: flex; border-bottom: 1px solid #c7c4c4; margin-bottom: 10px; border: 1px solid #c7c4c4"
                *ngFor="let item of providerList | fullTextSearch: query:['providerOfficeName']"
                class="list-group-item clearfix"
              >
                <div>
                  <img
                    style="width: 124px; height: 124px"
                    class="d-none d-md-block img-responsive img-rounded"
                    *ngIf="item.img"
                    [src]="item.img"
                  />
                  <img
                    style="width: 124px; height: 124px"
                    class="d-none d-md-block img-responsive img-rounded"
                    *ngIf="!item.img"
                    src="https://pinbox24.com/wp-content/uploads/2020/08/cropped-Mock-2rescale30pxhigh-e1605388640872.png"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin-left: 20px;
                    width: 100%;
                    justify-content: space-between;
                  "
                >
                  <h3 class="list-group-item-heading">
                    {{ item.providerOfficeName }}
                  </h3>
                  <p class="list-group-item-text lead">
                    {{ item.companyShortDesc }}
                  </p>
                  <div class="btn-toolbar pull-right d-flex justify-content-between" role="toolbar" aria-label="">
                    <span></span>

                    <a
                      [href]="'https://' + item.sharedOfficeId + '.pages.pinbox24.com'"
                      target="_blank"
                      class="btn btn-outline-primary"
                    >
                      {{ 'externalSharedService.move_to_provider_panel' | translate }}
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <ng-template #noProvider>
            <div>
              <h2>
                {{ 'externalSharedService.no_data_found' | translate }}
              </h2>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- <div *ngSwitchDefault>output2</div> -->
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'externalSharedService.enter_location_info' | translate }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div fxLayoutAlign="start center" fxLayout="row" fxLayout.lt-md="column">
        <p class="m-0">
          {{ address }}
        </p>
        <span fxFlex></span>
        <div fxLayout="row">
          <a (click)="open(null, 'search')" style="cursor: pointer">
            {{ 'externalSharedService.change_location_or_range' | translate }}
          </a>
        </div>
      </div>
      <br />
      <div fxLayout="row">
        <agm-map
          *ngIf="!(isSmallScreen && itemSelected)"
          [style.height]="showMap ? '70vh' : '0px'"
          style="width: 100%"
          [latitude]="latitude"
          [longitude]="longitude"
          [zoom]="zoom"
        >
          <agm-marker [label]="'M'" [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"></agm-marker>

          <ng-container *ngFor="let item of providerList">
            <agm-marker
              (markerClick)="markerClicked($event, item)"
              *ngIf="item && item.location && item.location.coordinates"
              [title]="item.providerOfficeName"
              [label]="'P'"
              [latitude]="item.location.coordinates[1]"
              [longitude]="item.location.coordinates[0]"
              [markerDraggable]="true"
            ></agm-marker>
          </ng-container>
          <agm-circle
            [latitude]="latitude"
            [longitude]="longitude"
            [radius]="range"
            [fillColor]="'red'"
            [circleDraggable]="true"
            [editable]="true"
            (dragEnd)="radiusDragEnd($event)"
            (radiusChange)="event('radiusChange', $event)"
          >
          </agm-circle>
        </agm-map>
        <div
          *ngIf="!isSmallScreen || (isSmallScreen && itemSelected)"
          style="overflow-y: auto; overflow-x: hidden; width: 400px; margin-left: 12px; height: 70vh"
        >
          <div style="padding-bottom: 14px" *ngIf="isSmallScreen" fxLayout="row">
            <i (click)="itemSelected = false" class="fa fa-arrow-left" aria-hidden="true"></i>
            <span fxFlex></span>
          </div>
          <ul class="list-group" style="width: 350px">
            <li
              [id]="item._id"
              style="
                width: 290px;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #c7c4c4;
                margin-bottom: 10px;
                border: 1px solid #c7c4c4;
              "
              [style.background]="selectedItem === item._id ? 'lightyellow' : ''"
              *ngFor="let item of providerList | fullTextSearch: query:['providerOfficeName']"
              class="list-group-item clearfix"
            >
              <div>
                <img
                  style="width: 100%; height: 124px"
                  class="d-none d-md-block img-responsive img-rounded"
                  *ngIf="item.img"
                  [src]="item.img"
                />
                <img
                  style="width: 100%; height: 124px"
                  class="d-none d-md-block img-responsive img-rounded"
                  *ngIf="!item.img"
                  src="https://pinbox24.com/wp-content/uploads/2020/08/cropped-Mock-2rescale30pxhigh-e1605388640872.png"
                />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  width: 100%;
                  justify-content: space-between;
                "
              >
                <h5>
                  {{ item.providerOfficeName }}
                </h5>
                <p>
                  {{ item.companyShortDesc }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
</div>
