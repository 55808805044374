import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import jwt_decode from 'jwt-decode';
import * as md5 from 'md5';

@Component({
  selector: 'app-specialist-detail-popup',
  templateUrl: './specialist-detail-popup.component.html',
  styleUrls: ['./specialist-detail-popup.component.scss'],
})
export class SpecialistDetailPopupComponent implements OnInit {
  allData: any;
  view: any = 'summary';
  loading: boolean;
  formData: any = {};
  headingInfo = 'externalSharedService.summary';
  reqBody: any = {
    creditAmount: 0,
    amountToPay: 0,
    multiple: 1,
    paymentSource: 'online',
    paymentMethod: 'przelewy24',
    tax: 23,
    totalAmountToPay: 0,
    discountPromoCredit: 0,
    currency: '$',
    currencyId: 3,
  };
  loginData: any = {
    appType: 'externalPage',
  };
  signupData: any = {};
  accountExists: boolean;
  caseDoc: any;
  eventData: any;
  orderDetail: any;
  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private apiService: ApiService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {}

  async continueV2() {
    try {
      this.loading = true;
      this.eventData = await this.apiService.postRequest(
        `/external-page/calendar/${this.allData.resourceData._id}/${this.allData.serviceData._id}`,
        {
          date: this.allData.selectedDate,
          startSlot: this.allData.slot.formatted,
          endSlot: this.allData.slot.end,
        }
      );
      if (localStorage.getItem('ngStorage-token')) {
        const decodedToken: any = jwt_decode(localStorage.getItem('ngStorage-token'));
        this.formData = this.formData || {};
        this.formData.email = decodedToken.login;
        // await this.submit();
        // this.view = 'payment';
      }
      this.view = 'email';
      this.loading = false;
      // this.activeModal.close({
      //   operation: "continue",
      //   eventId: data._id
      // });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  async continue() {
    switch (this.view) {
      case 'summary':
        if (this.eventData) {
          this.view = 'email';
        } else {
          // this.view = 'payment';
          if (this.allData.serviceData && this.allData.serviceData.paymentRequirements === 'pay-not-required') {
            await this.continueV2();
            this.activeModal.close({
              operation: 'continue',
              eventId: this.eventData._id,
            });
          } else {
            await this.continueV2();
            this.headingInfo = 'externalSharedService.enter_email';
          }
        }
        break;
      case 'password':
      case 'loginInfo':
      case 'signupInfo':
        this.loading = true;
        await this.afterLogin();
        this.headingInfo = 'externalSharedService.payment_heading';
        this.view = 'payment';
        this.loading = false;
        break;
    }
  }

  async getOrderDetail(serviceId: string) {
    try {
      const temp = await this.apiService.getRequest(`/external-page/order-detail/${serviceId}`);
      this.orderDetail = (temp && temp.orderDetail) || {};
    } catch (error) {
      alert(error);
    }
  }

  async pay() {
    console.log('pay now');
    try {
      this.loading = true;
      this.reqBody.creditAmount = this.allData.resourceData.price;
      this.reqBody.amountToPay = this.allData.resourceData.price;
      const data: any = await this.apiService.postRequest(
        `/external-page/payment/pay`,
        {
          allContUrl: `${window.location.origin}/payment/success`,
          paymentType: 'przelewy24',
          serviceId: this.caseDoc && this.caseDoc.serviceDoc && this.caseDoc.serviceDoc._id,
          ...this.reqBody,
          email: this.formData.email,
          description: `Making payment for booking`,
          currencyCode: 'PLN',
          products: [],
        },
        { officeId: '5cb9648308e32961acf1ff1f' }
      );
      console.log(data);
      if (data) {
        window.location.href = data;
      }
      this.loading = false;
    } catch (error) {
      this.commonService.showInfo(error, 'Error', 'error');
      this.loading = false;
      console.log('making payment', error);
    }
  }

  async submit() {
    try {
      console.log(this.formData);
      if (this.formData.email) {
        try {
          this.formData.nextServiceId = this.allData.serviceData._id;
          this.formData.subType = this.allData.serviceData.serviceType;
          this.formData.description = `global.new_service_desc - ${this.allData.serviceData.serviceName}`;
          this.formData.mainServiceName = this.allData.serviceData.serviceName;
          this.formData.booking = true;
          this.formData.selectedVariant = [
            {
              name: 'booking',
              productCode: 'booking',
              price: this.allData.resourceData.price,
              currency: this.allData.resourceData.currency,
            },
          ];
          this.formData.eventId = this.eventData._id;
          this.caseDoc = await this.apiService.postRequest(`/webook/start-shared-service`, this.formData, {
            'webhook-token': this.allData.webhookToken,
          });
          console.log('this.caseDoc - ', this.caseDoc);
        } catch (error) {
          alert(error);
        }
      }
    } catch (error) {
      alert(error);
    }
  }

  logIn(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      const header = new HttpHeaders();
      header.append('Content-Type', 'application/json');
      let url = `${environment.serverUrl}/auth`;
      if (user && user.provider === 'GOOGLE') {
        url = `${environment.serverUrl}/google-auth`;
      }
      return this.http.post(url, user, { headers: header }).subscribe(
        async (data: any) => {
          this.loading = false;
          if (data['success'] && data['result']) {
            data = data['result'] || {};
            localStorage.setItem('ngStorage-token', data.token);
            await this.submit();
            this.continue();
            resolve(true);
          } else {
            reject(data);
          }
        },
        (err) => {
          this.loading = false;
          reject(err);
        }
      );
    });
  }

  signUp(user: any) {
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/json');
    return this.http.post(environment.serverUrl + '/registration', user, {
      headers: header,
    });
  }

  signup(): void {
    this.signUp(this.formData).subscribe(
      (res: any) => {
        if (!res['success']) {
          this.commonService.showInfo('Register Success, Login now.', 'Success', 'success');
          this.view = 'email';
          // this.continue();
        } else {
          this.commonService.showInfo(res['error'], 'Error', 'error');
        }
      },
      (error: any) => {
        this.commonService.showInfo(error, 'Error', 'error');
      }
    );
  }

  login() {
    let user = {
      login: this.formData.email,
      password: md5(this.formData.password),
      appType: 'external',
    };
    this.logIn(user);
  }

  async afterLogin() {
    await this.submit();
    await this.getOrderDetail(this.caseDoc && this.caseDoc.serviceDoc && this.caseDoc.serviceDoc._id);
  }

  async checkAndDecide() {
    try {
      this.accountExists = false;
      this.loading = true;
      const data = await this.apiService.getRequest(`/pinbox24-profile/${this.formData.email}`);
      if (data && data.emailVerified) {
        this.accountExists = true;
        this.headingInfo = 'externalSharedService.already_have_account';
        this.view = 'loginInfo';
      } else {
        this.headingInfo = 'externalSharedService.we_have_created_account';
        this.view = 'signupInfo';
      }

      this.loading = false;
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  async cancelClick() {
    if (this.orderDetail) {
      this.loading = true;
      try {
        await this.apiService.getRequest(`external-page/cancel-order/${this.caseDoc._id}`);
      } catch (error) {
        console.log('error - ', error);
        this.loading = false;
      }
      this.loading = false;
    }
    this.activeModal.close();
  }
}
