<div class="d-flex align-items-center" style="padding-top: 40px">
  <div class="container">
    <div class="d-flex mb-3">
      <button
        [routerLink]="['/' + officeId + '/gdpr']"
        type="button"
        class="btn happ-btn-hover btn-outline-info btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.description' | translate }}
      </button>
      <button
        [routerLink]="['/' + officeId + '/gdpr-details']"
        type="button"
        class="btn happ-btn-hover btn-outline-info btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.provider_details' | translate }}
      </button>
      <button
        [routerLink]="['/' + officeId + '/gdpr-info']"
        type="button"
        class="btn happ-btn-hover btn-outline-info happ-selected-button btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.services' | translate }}
      </button>
    </div>
    <div style="padding-top: 12px">
      <div>
        <h1 class="heading" style="text-align: center; margin: 30px">
          {{ 'externalSharedService.realize_your_right_by' | translate }}
          <!-- You can realize your rights by -->
        </h1>
        <p *ngIf="selectedService" style="text-align: center; margin-top: 15px">
          {{ selectedService.description | translate }}
        </p>
        <br />
      </div>

      <div *ngIf="view !== 'form'" style="display: flex; justify-content: center; align-items: center">
        <ul class="list-group" style="width: 100%">
          <li
            style="display: flex; border-bottom: 1px solid #c7c4c4; margin-bottom: 10px; border: 1px solid #c7c4c4"
            *ngFor="let item of types"
            class="list-group-item clearfix"
          >
            <div>
              <img
                class="img-responsive img-rounded"
                style="width: 128px; height: 256px"
                *ngIf="item.img"
                [src]="item.img"
              />
              <img
                class="img-responsive img-rounded"
                style="width: 128px; height: 128px"
                *ngIf="!item.img"
                src="https://pinbox24.com/wp-content/uploads/2020/08/cropped-Mock-2rescale30pxhigh-e1605388640872.png"
              />
            </div>
            <div style="display: flex; flex-direction: column; text-align: left; width: 100%; margin-left: 20px">
              <h3 class="list-group-item-heading">
                {{ item.title | translate }}
                <!-- <span class="label label-danger pull-right">NEW !</span> -->
              </h3>
              <p class="list-group-item-text lead">
                {{ item.description | translate }}
              </p>
              <div class="btn-toolbar pull-right d-flex justify-content-between" role="toolbar" aria-label="">
                <span></span>

                <a (click)="view = 'form'; selectedService = item" class="btn btn-primary">
                  {{ 'externalSharedService.continue' | translate }}
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="view === 'form'" [@inForm]="'inForm'" style="height: calc(100vh - 410px); display: flex">
        <div class="container mt-3">
          <div class="happ-outer-div">
            <div *ngIf="formView === 'description'" [@inDetail]="'in'">
              <div>
                <div class="form-group">
                  <label>{{ 'externalSharedService.request_description' | translate }}</label>
                  <textarea
                    style="font-size: 24px"
                    class="form-control"
                    [(ngModel)]="formData.description"
                    rows="7"
                  ></textarea>
                </div>
              </div>
            </div>
            <div *ngIf="formView === 'email'" [@inDetail]="'in'">
              <div>
                <div class="form-group">
                  <label for="exampleInputEmail1">{{ 'externalSharedService.email_desc' | translate }}</label>
                  <input
                    style="font-size: 24px"
                    type="email"
                    [(ngModel)]="formData.email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>
              </div>
            </div>
            <div *ngIf="formView === 'error'" [@inDetail]="'in'">
              <h2>
                {{ 'externalSharedService.some_error_occured' | translate }}
              </h2>
            </div>
            <div *ngIf="formView === 'phoneVerify'" [@inDetail]="'in'">
              <ng-container>
                <div class="form-group col-md-12">
                  <label for="phone">{{ 'externalSharedService.phone_no' | translate }}</label>
                  <input
                    style="font-size: 24px"
                    [disabled]="otpSent"
                    [(ngModel)]="formData.phoneNo"
                    type="tel"
                    class="form-control"
                    id="phone"
                    placeholder="phone"
                  />
                </div>
                <!-- <div *ngIf="!otpSent" class="d-flex flex-row-reverse w-100 mr-3">
                              <a class="btn btn-info" (click)="sendOtp()">{{ 'externalSharedService.send_otp' | translate }}</a>
                            </div> -->

                <div class="col-md-12">
                  <ng-container [ngSwitch]="otpStatus">
                    <ng-container *ngSwitchCase="'OTP_SENDED'">
                      <div class="alert alert-success" role="alert">
                        {{ 'externalSharedService.otp_sended' | translate }}
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'OTP_VERIFIED'">
                      <div class="alert alert-success" role="alert">
                        {{ 'externalSharedService.otp_verified' | translate }}
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'INVALID_OTP'">
                      <div class="alert alert-danger" role="alert">
                        {{ 'externalSharedService.invalid_otp' | translate }}
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <!-- <div *ngIf="otpStatus && !phoneVerified" class="d-flex flex-row-reverse w-100 mr-3">
                              <a class="btn btn-info" (click)="verifyOtp()">{{ 'externalSharedService.verify_otp' | translate }}</a>
                            </div> -->
              </ng-container>
            </div>
            <div *ngIf="formView === 'otpView'" [@inDetail]="'in'">
              <div>
                <div class="form-group col-md-12" *ngIf="otpStatus">
                  <label for="otp">{{ 'externalSharedService.otp' | translate }}</label>
                  <input
                    style="font-size: 24px"
                    [(ngModel)]="otp"
                    type="tel"
                    class="form-control"
                    id="otp"
                    placeholder="otp"
                  />
                </div>
              </div>
            </div>
            <div *ngIf="formView === 'thanks'" [@inDetail]="'in'">
              <h2 style="text-align: center; margin-top: 15px">
                {{ 'externalSharedService.thank_you_message' | translate }}
                <!-- Your case have been created. You will recive email with detail of case soon. Thanks for choosing us. -->
              </h2>
              <p style="text-align: center; margin-top: 15px">
                {{ 'externalSharedService.thank_you_message_detail' | translate }}
              </p>
              <button type="button" [routerLink]="['/gdpr']" class="m-2 btn btn-outline-secondary btn-lg btn-block">
                {{ 'externalSharedService.back' | translate }}
              </button>
            </div>
          </div>
          <ng-container [ngSwitch]="formView">
            <ng-container *ngSwitchCase="'description'">
              <div class="m-3 d-flex justify-content-between">
                <span></span>
                <button
                  type="button"
                  [disabled]="!formData.description"
                  (click)="nextStep()"
                  class="m-2 btn btn-outline-secondary btn-lg btn-block"
                >
                  {{ 'externalSharedService.next' | translate }}
                </button>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'email'">
              <div class="m-3 d-flex justify-content-between">
                <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
                  {{ 'externalSharedService.previous' | translate }}
                </button>
                <button
                  type="button"
                  [disabled]="!formData.email"
                  (click)="nextStep()"
                  class="m-2 btn btn-outline-secondary btn-lg btn-block"
                >
                  {{ 'externalSharedService.next' | translate }}
                </button>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'phoneVerify'">
              <div class="m-3 d-flex justify-content-between mt-3">
                <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
                  {{ 'externalSharedService.previous' | translate }}
                </button>
                <button
                  type="button"
                  [disabled]="!formData.phoneNo"
                  (click)="sendOtp()"
                  class="m-2 btn btn-outline-secondary btn-lg btn-block"
                >
                  {{ 'externalSharedService.next' | translate }}
                </button>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'otpView'">
              <div class="m-3 d-flex justify-content-between mt-3">
                <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
                  {{ 'externalSharedService.previous' | translate }}
                </button>
                <button
                  type="button"
                  [disabled]="!otp"
                  (click)="verifyOtp()"
                  class="m-2 btn btn-outline-secondary btn-lg btn-block"
                >
                  {{ 'externalSharedService.next' | translate }}
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
