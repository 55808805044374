import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ss-event-list',
  templateUrl: './ss-event-list.component.html',
  styleUrls: ['./ss-event-list.component.scss'],
})
export class SsEventListComponent implements OnInit {
  providerInfo: any;
  types: any = [];
  myTypes: any[] = [];
  allServices: any[] = [];
  staticServices: any[] = [];
  currentLang = 'en';
  query = '';
  currentType = 'all';
  officeId: any;
  isInternal: any;
  localtoken: string;
  serviceInfo: any;
  formData: any;
  providerContract: any;

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private apiHelper: ApiService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.localtoken = localStorage.getItem('ngStorage-token');
    this.route.queryParams.subscribe((params) => {
      this.currentType = params['type'] || 'all';
    });
    this.currentLang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe((data2: any) => {
      this.currentLang = data2;
    });
    this.officeId = this.commonService.getOfficeId(this.route);
    if (this.officeId) {
      this.getData();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  async getTypes() {
    try {
      const temp = await this.apiHelper.getRequest(`/external-page/shared-service-types`);
      this.types = (temp && temp.data) || [];
      if (this.providerInfo && this.providerInfo.providerContract && this.providerInfo.providerContract.ssTypes) {
        const t1: any = [];
        this.providerInfo.providerContract.ssTypes.map((ele: any) => {
          const index = this.types.findIndex((type: any) => type && type.key === ele);
          if (ele && index !== -1) {
            t1.push(this.types[index]);
          }
        });
        this.myTypes = t1;
      }
    } catch (error) {
      alert(error);
    }
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      const query: any = {
        route: 'event-list',
      };
      this.providerInfo = await this.commonService.getAboutOffice(this.officeId, query);
      this.providerContract = this.providerInfo.providerContract;
      this.allServices = (this.providerInfo && this.providerInfo.resourceList) || [];
      this.staticServices = JSON.parse(JSON.stringify(this.allServices));
      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }
}
