<div style="padding: 30px 10px">
  <section class="wrapper">
    <div fxLayout="row" fxLayout.lt-md="column" style="padding: 0px">
      <div fxFlex="30" fxFlex.lt-md="100" fxLayout="column" style="padding: 12px 8px">
        <ng-container *ngIf="mappedData && mappedData.length">
          <p style="font-size: 20px; margin-left: 18px">Select Area</p>
          <div class="page-content page-container" id="page-content">
            <div>
              <div>
                <div style="width: 100%">
                  <div>
                    <div class="list list-row card" id="sortable" data-sortable-id="0" aria-dropeffect="move">
                      <div
                        (click)="selectedType = item"
                        *ngFor="let item of mappedData"
                        [ngClass]="{ 'happ-active': selectedType && selectedType.key === item.key, 'list-item': true }"
                        data-id="13"
                        data-item-sortable-id="0"
                        draggable="true"
                        role="option"
                        aria-grabbed="false"
                        style="border: 1px solid #e0dfdf"
                      >
                        <div class="flex">
                          <!-- {{ item.title | textTranslate:currentLang }} -->
                          {{ item.key }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div fxFlex="70" fxFlex.lt-md="100">
        <ng-container *ngIf="selectedType && selectedType.allTypes && selectedType.allTypes.length">
          <div class="appointment-popup mt-12">
            <div fxLayout="row" fxLayout.lt-md="column">
              <div class="appointment-calendar">
                <div class="calendar-wrapper">
                  <div class="calendar-week mt-3 mb-2">
                    <div fxLayout="row wrap" class="happ-ul">
                      <div
                        fxLayout="column"
                        class="happ-header-day-item happ-li"
                        [ngClass]="{ active: selectedTypeData && item.key === selectedTypeData.key }"
                        (click)="selectedTypeData = item"
                        *ngFor="let item of selectedType.allTypes"
                      >
                        <span>
                          {{ item.key }}
                        </span>
                        <!-- <span style="font-size: 20px;font-weight: 700;">
                                    {{ item.weekDay }}
                                </span> -->
                      </div>
                    </div>
                  </div>

                  <div fxLayout="column wrap" class="mt-12" style="overflow: auto">
                    <div class="row" *ngIf="providerList && providerList.length">
                      <div class="list-group d-flex flex-row flex-wrap">
                        <div
                          style="
                            display: flex;
                            border-bottom: 1px solid #c7c4c4;
                            margin-bottom: 10px;
                            border: 1px solid #c7c4c4;
                            width: 48%;
                          "
                          *ngFor="let item of providerList"
                          class="list-group-item clearfix m-2"
                        >
                          <div>
                            <img
                              style="width: 124px; height: 124px"
                              class="d-none d-md-block img-responsive img-rounded"
                              *ngIf="item.img"
                              [src]="item.img"
                            />
                            <img
                              style="width: 124px; height: 124px"
                              class="d-none d-md-block img-responsive img-rounded"
                              *ngIf="!item.img"
                              src="https://pinbox24.com/wp-content/uploads/2020/08/cropped-Mock-2rescale30pxhigh-e1605388640872.png"
                            />
                          </div>
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              text-align: left;
                              margin-left: 20px;
                              width: 100%;
                              justify-content: space-between;
                            "
                          >
                            <h3 class="list-group-item-heading">
                              {{ item.name || 'NA' }}
                            </h3>
                            <p class="list-group-item-text lead">
                              {{ item.country || 'NA' }}
                            </p>
                            <div
                              class="btn-toolbar pull-right d-flex justify-content-between"
                              role="toolbar"
                              aria-label=""
                            >
                              <span></span>

                              <a (click)="openProvider(item)" class="btn btn-outline-primary">
                                {{ 'externalSharedService.view_more' | translate }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</div>
