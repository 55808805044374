<div class="d-flex align-items-center" style="background: #efebeb; padding-top: 40px">
  <div class="container">
    <div class="d-flex mb-3 flex-column flex-md-row">
      <button
        [routerLink]="['/' + officeId + '/gdpr']"
        type="button"
        class="btn happ-btn-hover btn-outline-info happ-selected-button btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.description' | translate }}
      </button>
      <button
        [routerLink]="['/' + officeId + '/gdpr-details']"
        type="button"
        class="btn happ-btn-hover btn-outline-info btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.provider_details' | translate }}
      </button>
      <button
        [routerLink]="['/' + officeId + '/gdpr-info']"
        type="button"
        class="btn happ-btn-hover btn-outline-info btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.services' | translate }}
      </button>
    </div>

    <div
      class="happ-pdf-html"
      *ngIf="
        providerInfo &&
          providerInfo.providerContract &&
          providerInfo.providerContract.externalGDPRDescHome &&
          providerInfo.providerContract.externalGDPRDescCheck === 'Yes';
        else otherDoc
      "
      [innerHTML]="providerInfo.providerContract.externalGDPRDescHome | htmlWithStyle"
    ></div>
    <ng-template #otherDoc>
      <div
        class="happ-pdf-html"
        *ngIf="providerInfo && providerInfo.htmlContent"
        [innerHTML]="providerInfo.htmlContent | htmlWithStyle"
      ></div>
    </ng-template>
  </div>
</div>
