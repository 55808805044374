import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent, SharedServiceDetailComponent } from './shared-service-detail.component';
import { Routes, RouterModule } from '@angular/router';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DndDirective } from './dnd.directive';
import { ProgressComponent } from './progress/progress.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SlotBookingModule } from '@app/@shared/components/slot-booking/slot-booking.module';
import { DateTimeRangePickerModule } from '@app/@shared/components/date-time-range-picker/date-time-range-picker.module';
import { DateRangePickerModule } from '@app/@shared/components/date-range-picker/date-range-picker.module';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'gdpr/:serviceId', component: SharedServiceDetailComponent, data: { title: marker('GDPR Info') } },
    {
      path: 'our-services/:serviceId',
      component: SharedServiceDetailComponent,
      data: { title: marker('Service Info') },
    },
    {
      path: ':officeId/gdpr/:serviceId',
      component: SharedServiceDetailComponent,
      data: { title: marker('GDPR Info') },
    },
    {
      path: ':officeId/our-services/:serviceId',
      component: SharedServiceDetailComponent,
      data: { title: marker('Service Info') },
    },
  ]),
];

@NgModule({
  declarations: [SharedServiceDetailComponent, DndDirective, ProgressComponent, ConfirmDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgbModalModule,
    GlobalPipesModule,
    RouterModule.forChild(routes),
    SlotBookingModule,
    DateTimeRangePickerModule,
    DateRangePickerModule,
  ],
  entryComponents: [ConfirmDialogComponent],
})
export class SharedServiceDetailModule {}
