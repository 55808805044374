import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '@app/helpers/common.service';
import { TranslateService } from '@ngx-translate/core';

import { I18nService } from './i18n.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() inNavbar = false;
  @Input() menuClass = '';
  public currentLang = 'en';
  public languages = [
    {
      id: 'en',
      title: 'English',
      flag: 'us',
    },
    {
      id: 'pl',
      title: 'Polish',
      flag: 'pl',
    },
  ];

  constructor(private _translateService: TranslateService, public commonService: CommonService) {}

  ngOnInit() {
    this.getSelectedLanguage();
    this.getActiveLanguages();
  }

  async getActiveLanguages() {
    this.languages = this.commonService.formatTheLanguageArray(await this.commonService.getActiveLangs());
    this.getSelectedLanguage();
    this._translateService.addLangs(this.languages.map((ele) => ele && ele.id) || ['en', 'tr']);
    this._translateService.setDefaultLang(this.currentLang);
    this._translateService.use(this.currentLang);
  }
  getSelectedLanguage() {
    try {
      let userLang = navigator.language;
      if (userLang) {
        userLang = userLang.substr(0, userLang.indexOf('-'));
        const index = this.languages.findIndex((ele) => ele && ele.id === userLang);
        if (index !== -1) {
          this.currentLang = userLang;
        } else {
          this.currentLang = this.languages[0].id;
        }
      }
      if (localStorage.getItem('selectedLanguage')) {
        this.languages.forEach((lang) => {
          if (lang.id === localStorage.getItem('selectedLanguage')) {
            this.currentLang = lang.id;
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  setLanguage(langId: any): void {
    // Set the selected language for the toolbar
    this.currentLang = langId;
    localStorage.setItem('selectedLanguage', langId);

    // Use the selected language for translations
    this._translateService.use(langId);
    this._translateService.setDefaultLang(langId);

    window.location.reload();
  }

  onLangChange() {
    console.log('currentLang ', this.currentLang);
    this.setLanguage(this.currentLang);
    localStorage.setItem('selectedLanguage', this.currentLang);
  }
}
