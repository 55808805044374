<section class="wrapper" *ngIf="providerInfo">
  <div class="container-fostrap">
    <h2 class="mb-5 mt-3">
      {{ 'externalSharedService.event_list_by_provider' | translate }}
    </h2>
    <div class="container">
      <div fxLayout="row" *ngIf="providerContract && providerContract['danceEvent']">
        <span fxFlex></span>
        <select class="form-select" style="width: 200px" aria-label="Default select example">
          <option>
            {{ 'externalSharedService.all' | translate }}
          </option>
          <option *ngFor="let item of providerContract['danceEvent']">
            {{ item }}
          </option>
        </select>
      </div>
      <br />
      <table class="table table-hover table-dark">
        <thead>
          <tr>
            <th class="text-left" scope="col">{{ 'externalSharedService.res_name' | translate }}</th>
            <th class="text-left" scope="col">{{ 'externalSharedService.signature' | translate }}</th>
            <th class="text-left" scope="col">{{ 'externalSharedService.type' | translate }}</th>
            <th class="text-right" scope="col">{{ 'externalSharedService.action' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of allServices | fullTextSearch: query:['serviceName']">
            <td class="text-left">{{ item.resName }}</td>
            <td class="text-left">{{ item.signature }}</td>
            <td class="text-left">{{ item.resType }}</td>
            <td class="text-right" scope="col">
              <a [routerLink]="['/event-detail/' + item._id]" style="color: yellow">
                {{ 'externalSharedService.view' | translate }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="">
                
                <div class="mcw">
                    <div class="cv">
                      <div>
                       <div class="inbox">
                         <div class="inbox-sb">
                           
                         </div>
                         <div class="inbox-bx container-fluid">
                           <div class="row">
                             <div class="col-md-2">
                               <ul>
                                 <li><a href="#">Inbox</a></li>
                                 <li><a href="#">Sent</a></li>
                                 <li><a href="#">Trash</a></li>
                               </ul>
                             </div>
                             <div class="col-md-10">
                                <div class="row" style="margin: 0px;">
                                    <ul class="list-group">
                                        <li style="display: flex;border-bottom: 1px solid #c7c4c4; margin-bottom: 10px; border: 1px solid #c7c4c4;" *ngFor="let item of allServices | fullTextSearch:query:['serviceName'];"  class="list-group-item clearfix">
                                            <div>
                                                <img style="width: 124px; height: 124px;" class="d-none d-md-block img-responsive img-rounded" *ngIf="item.img" [src]="item.img" />
                                                <img style="width: 124px; height: 124px;" class="d-none d-md-block img-responsive img-rounded" *ngIf="!item.img" src="https://pinbox24.com/wp-content/uploads/2020/08/cropped-Mock-2rescale30pxhigh-e1605388640872.png" />
                                            </div>
                                            <div style="display: flex; flex-direction: column; text-align: left; margin-left: 20px;width: 100%; justify-content: space-between;">
                                                <h3 class="list-group-item-heading">
                                                  {{ item.serviceName }}
                                                </h3>
                                                <p>
                                                    {{ item.signature }}
                                                </p>
                                                <div class="d-flex flex-row happ-tag-container">
                                                    <span *ngIf="item.serviceData && item.serviceData.lang">
                                                        {{ item.serviceData.lang }}
                                                    </span>
                                                    <span *ngIf="item.serviceData && item.serviceData.type">
                                                        {{ item.serviceData.type }}
                                                    </span>
                                                </div>
                                                <p class="list-group-item-text lead">
                                                  {{ (item.serviceData && item.serviceData.recordData && item.serviceData.recordData.clientShortDescription) }}
                                                </p>
                                                <div class="btn-toolbar pull-right d-flex justify-content-between" role="toolbar" aria-label="">
                                                 <span></span>
                                                  
                                                  <a [routerLink]="['/event-detail/' + item._id]"  class="btn btn-outline-primary">
                                                      {{ 'externalSharedService.view_more' | translate }}
                                                  </a>
                                                </div>
                                            </div>
                                        </li>
                                      </ul>
                                </div>
                             </div>
                           </div>
                         </div>
                       </div>
                      </div>
                    </div>
                  </div>


                
            </div> -->
    </div>
  </div>
</section>
