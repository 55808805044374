import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/@shared';
import { TranslateModule } from '@ngx-translate/core';
import { SRodoListComponent } from './s-rodo-list.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

const routes: Routes = [
  Shell.childRoutes([{ path: 'rodo-list', component: SRodoListComponent, data: { title: marker('RODO List') } }]),
];

@NgModule({
  declarations: [SRodoListComponent],
  imports: [CommonModule, SharedModule, TranslateModule, FormsModule, RouterModule.forChild(routes)],
  exports: [SRodoListComponent],
})
export class SRodoListModule {}
