import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';

@Component({
  selector: 'app-s-rodo-list',
  templateUrl: './s-rodo-list.component.html',
  styleUrls: ['./s-rodo-list.component.scss'],
})
export class SRodoListComponent implements OnInit {
  files: any[] = [];
  serviceInfo: any;
  officeId: any;
  formData: any = {};
  otpStatus = '';
  view = 'detail';

  formView = 'description';
  otpSent: boolean;
  phoneVerified: boolean;
  userDetail: any;
  otp: any;

  types = [
    {
      title: 'externalSharedService.gdpr_apply_type1',
      description: 'externalSharedService.gdpr_apply_type1_desc',
    },
    {
      title: 'externalSharedService.gdpr_apply_type2',
      description: 'externalSharedService.gdpr_apply_type2_desc',
    },
    {
      title: 'externalSharedService.gdpr_apply_type3',
      description: 'externalSharedService.gdpr_apply_type3_desc',
    },
    {
      title: 'externalSharedService.gdpr_apply_type4',
      description: 'externalSharedService.gdpr_apply_type4_desc',
    },
    {
      title: 'externalSharedService.gdpr_apply_type5',
      description: 'externalSharedService.gdpr_apply_type5_desc',
    },
    {
      title: 'externalSharedService.gdpr_apply_type6',
      description: 'externalSharedService.gdpr_apply_type6_desc',
    },
    {
      title: 'externalSharedService.gdpr_apply_type7',
      description: 'externalSharedService.gdpr_apply_type7_desc',
    },
  ];

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.officeId = this.commonService.getOfficeId(this.route);
    if (this.officeId) {
      // this.getData();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      this.serviceInfo = await this.commonService.getAboutService(this.officeId, this.route.snapshot.params.serviceId);
      this.formInitView();
      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }

  registerCase() {
    this.view = 'form';
  }

  formInitView() {
    if (this.serviceInfo && this.serviceInfo.formShowDescription === 'Yes') {
      this.formView = 'description';
    } else {
      this.formView = 'email';
    }
  }

  nextStep() {
    switch (this.formView) {
      case 'description':
        this.formView = 'email';
        break;
      case 'email':
        if (this.serviceInfo && this.serviceInfo.formShowPhoneVerify === 'Yes') {
          this.formView = 'phoneVerify';
        } else {
          this.formView = 'thanks';
        }
        break;
      case 'phoneVerify':
        this.formView = 'otpView';
        break;
      case 'otpView':
        this.formView = 'thanks';
        break;
    }
  }

  previousStep() {
    switch (this.formView) {
      case 'description':
        break;
      case 'email':
        if (this.serviceInfo && this.serviceInfo.formShowDescription === 'Yes') {
          this.formView = 'description';
        }
        break;
      case 'phoneVerify':
        this.formView = 'email';
        break;
    }
  }

  async sendOtp() {
    if (this.formData.phoneNo) {
      this.commonService.onLoaderChange.next(true);
      try {
        await this.apiService.postRequest(`/auth/send-otp`, { phoneNo: this.formData.phoneNo, lang: 'en' });
        this.otpSent = true;
        this.otpStatus = 'OTP_SENDED';
        this.commonService.onLoaderChange.next(false);
        this.nextStep();
      } catch (error) {
        this.commonService.onLoaderChange.next(false);
      }
    }
  }

  async verifyOtp() {
    if (this.formData.phoneNo && this.otp) {
      try {
        this.commonService.onLoaderChange.next(true);
        await this.apiService.postRequest(`/auth/verify-otp`, { phoneNo: this.formData.phoneNo, otp: this.otp });
        this.otpStatus = 'OTP_VERIFIED';
        this.phoneVerified = true;
        this.userDetail = this.userDetail || {};
        this.userDetail.phoneNoVerified = true;
        this.commonService.onLoaderChange.next(false);
        this.nextStep();
      } catch (error) {
        this.otpStatus = 'INVALID_OTP';
        this.commonService.onLoaderChange.next(false);
      }
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
