<div class="d-flex align-items-center">
  <div [class]="providerInfo && providerInfo.useContainerClass === 'yes' ? 'container' : ''">
    <div
      *ngIf="
        providerInfo &&
          providerInfo.providerContract &&
          providerInfo.providerContract.externalWebsiteHome &&
          providerInfo.providerContract.homePageCheck === 'Yes';
        else otherDoc
      "
      [innerHTML]="providerInfo.providerContract.externalWebsiteHome | htmlWithStyle"
    ></div>
    <ng-template #otherDoc>
      <div
        *ngIf="providerInfo && providerInfo.htmlContent"
        [innerHTML]="providerInfo.htmlContent | htmlWithStyle"
      ></div>
    </ng-template>
  </div>
</div>
