import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/helpers/api.service';
import { CommonService } from '@app/helpers/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingPreviewComponent } from '../booking-panel/booking-preview/booking-preview.component';
import { EventBookingPanelComponent } from '../booking-panel/event-booking-panel/event-booking-panel.component';

@Component({
  selector: 'app-ss-event-detail',
  templateUrl: './ss-event-detail.component.html',
  styleUrls: ['./ss-event-detail.component.scss'],
})
export class SsEventDetailComponent implements OnInit {
  resourceInfo: any = {};
  serviceInfo: any = {};
  providerInfo: any = {};
  eventData: any;
  status: any;
  cardsConfig: any = {};

  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log('query param - ', params);
      this.status = params.status;
    });
    this.getData();
  }

  async getData() {
    try {
      this.commonService.onLoaderChange.next(true);
      this.eventData = await this.apiService.getRequest(
        `/external-page/resource/${this.route.snapshot.params.eventId}`
      );
      this.cardsConfig = this.eventData.cardsConfig || {};
      this.resourceInfo = this.eventData;
      this.serviceInfo = (this.eventData && this.eventData.serviceDoc) || {};
      this.commonService.onLoaderChange.next(false);
    } catch (error) {
      console.error(error);
      this.commonService.onLoaderChange.next(false);
    }
  }

  register() {
    const allData = {
      resourceData: this.resourceInfo,
      serviceData: this.serviceInfo,
      webhookToken: this.eventData.webhookToken,
      type: 'resource',
    };
    const modalRef = this.modalService.open(EventBookingPanelComponent, {
      windowClass: 'happ-semi-width',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.allData = allData;
    modalRef.closed.subscribe((data) => {
      if (data === 'close') {
        // this.router
        // console.log("slot --- ", this.selectedSlot);
        // this.currentValue = null;
        // this.getData();
      } else if (data && data.operation === 'continue') {
        // this.router.navigate([`/our-services/${this.serviceInfo._id}`], { queryParams: { eventId: data.eventId, view: "form", serviceId: this.serviceInfo._id, resourceId: this.selectedResource, date: this.selectedDate, slot: `${this.selectedSlot.formatted}-${this.selectedSlot.end}` } })
      }
    });
  }
}
