import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonService } from '@app/helpers/common.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  loading = false;
  constructor(public commonHelper: CommonService, private _changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.commonHelper.onLoaderChange.subscribe((data) => {
      this.loading = data;
      this._changeDetectorRef.detectChanges();
    });
  }
}
