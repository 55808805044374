import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SHomeComponent } from './s-home.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

const routes: Routes = [
  Shell.childRoutes([{ path: 'start', component: SHomeComponent, data: { title: marker('Home') } }]),
];

@NgModule({
  declarations: [SHomeComponent],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes)],
})
export class SHomeModule {}
