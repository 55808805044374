import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SProviderComponent } from './s-provider.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { FlexModule } from '@angular/flex-layout';
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { MapSearchModule } from '@app/@shared/components/map-search/map-search.module';

const routes: Routes = [
  Shell.childRoutes([{ path: 'all-providers', component: SProviderComponent, data: { title: marker('Providers') } }]),
];

@NgModule({
  declarations: [SProviderComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FlexModule,
    TranslateModule,
    NgbModule,
    GlobalPipesModule,
    MapSearchModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapKey,

      libraries: ['places'],
    }),
    RouterModule.forChild(routes),
  ],
})
export class SProviderModule {}
