import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapSearchComponent } from './map-search.component';
import { AgmCoreModule } from '@agm/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/@shared';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MapSearchComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FlexModule,
    TranslateModule,
    GlobalPipesModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapKey,

      libraries: ['places'],
    }),
  ],
  exports: [MapSearchComponent],
  entryComponents: [MapSearchComponent],
})
export class MapSearchModule {}
