<div class="d-flex align-items-center">
  <div class="container">
    <div
      *ngIf="
        providerInfo &&
          providerInfo.providerContract &&
          providerInfo.providerContract.externalWebsiteContact &&
          providerInfo.providerContract.contactPageCheck === 'Yes';
        else otherDoc
      "
      [innerHTML]="providerInfo.providerContract.externalWebsiteContact | htmlWithStyle"
    ></div>
    <ng-template #otherDoc>
      <div
        *ngIf="providerInfo && providerInfo.htmlContent"
        [innerHTML]="providerInfo.htmlContent | htmlWithStyle"
      ></div>
    </ng-template>
  </div>
</div>
