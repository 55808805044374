<div class="d-flex align-items-center">
  <div class="container">
    <br />
    <br />
    <h2>Register</h2>

    <br />
    <br />
    <div>
      <div class="form-group">
        <label for="exampleInputEmail1">{{ 'externalSharedService.enter_email' | translate }}</label>
        <input
          style="font-size: 24px"
          type="email"
          #contactemail="ngModel"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
          [(ngModel)]="formData.email"
          class="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">{{ 'externalSharedService.enter_desc' | translate }}</label>
        <textarea style="font-size: 24px" class="form-control" [(ngModel)]="formData.description" rows="7"></textarea>
      </div>
      <div fxLayout="row">
        <span fxFlex></span>
        <button
          type="button"
          [disabled]="!formData.description"
          (click)="register()"
          class="m-2 btn btn-success btn-lg btn-block"
        >
          {{ 'externalSharedService.become_specialist' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
