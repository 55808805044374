import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsEventListComponent } from './ss-event-list.component';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  Shell.childRoutes([{ path: 'event-list', component: SsEventListComponent, data: { title: marker('Services') } }]),
];

@NgModule({
  declarations: [SsEventListComponent],
  imports: [CommonModule, GlobalPipesModule, TranslateModule, FlexModule, FormsModule, RouterModule.forChild(routes)],
})
export class SsEventListModule {}
