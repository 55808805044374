<div fxLayout="row" fxLayout.lt-md="column">
  <div fxFlex="40" fxFlex.lt-md="100" style="padding: 12px; border: 1px solid #dadada">
    <h2>
      {{ serviceData.slotBookingHeading }}
    </h2>
    <br />
    <p>
      {{ serviceData.slotBookingDescription }}
    </p>
  </div>
  <div fxLayout="column" fxLayoutAlign="center start" fxFlex="60" fxFlex.lt-md="100" style="padding: 12px">
    <p style="margin: 0px">
      {{ 'externalSharedService.start_date_time' | translate }}
    </p>
    <div style="width: 100%" fxLayout="row">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        (click)="start.toggle()"
        (dateSelect)="onCustomDateChange($event, 'fromDate')"
        ngbDatepicker
        #start="ngbDatepicker"
      />
      <ngb-timepicker
        style="width: 100%; margin-left: 12px"
        name="fieldName"
        ngDefaultControl
        [(ngModel)]="startTime"
      ></ngb-timepicker>
    </div>
    <br />
    <p style="margin: 0px">
      {{ 'externalSharedService.end_date_time' | translate }}
    </p>
    <div style="width: 100%" fxLayout="row">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        (click)="end.toggle()"
        (dateSelect)="onCustomDateChange($event, 'toDate')"
        ngbDatepicker
        #end="ngbDatepicker"
      />
      <ngb-timepicker
        style="width: 100%; margin-left: 12px"
        name="fieldName"
        ngDefaultControl
        [(ngModel)]="endTime"
      ></ngb-timepicker>
    </div>
  </div>
</div>

<div class="m-3 d-flex justify-content-between">
  <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
    {{ 'externalSharedService.previous' | translate }}
  </button>
  <button
    type="button"
    [disabled]="!fromDate || !toDate"
    (click)="nextStep()"
    class="m-2 btn btn-success btn-lg btn-block"
  >
    {{ 'externalSharedService.next' | translate }}
  </button>
</div>
