import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsEventDetailComponent } from './ss-event-detail.component';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { GlobalPipesModule } from '@app/@shared/pipes/globalPipes.module';
import { Shell } from '@app/shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';
const routes: Routes = [
  Shell.childRoutes([
    { path: 'event-detail/:eventId', component: SsEventDetailComponent, data: { title: marker('Services') } },
  ]),
];

@NgModule({
  declarations: [SsEventDetailComponent],
  imports: [
    CommonModule,
    GlobalPipesModule,
    TranslateModule,
    FlexModule,
    AgmCoreModule,
    FormsModule,
    RouterModule.forChild(routes),
  ],
})
export class SsEventDetailModule {}
