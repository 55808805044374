<ng-container *ngIf="serviceInfo && view === 'detail'">
  <div class="py-sm-1 text-white pb-sm-4" style="background: #184b85">
    <div class="container">
      <div
        class="d-flex align-items-center justify-content-center"
        style="border-bottom: 1px solid; padding-bottom: 10px; margin-bottom: 6px"
      >
        <h1 class="happ-detail-heading font-weight-bold m-0">{{ serviceInfo && serviceInfo.serviceName }}</h1>
      </div>

      <div class="d-flex happ-breadcrumb-div">
        <div class="d-none d-md-block">
          <a class="happ-breadcrumb-link" [routerLink]="['/']">
            {{ 'externalSharedService.home' | translate }}
          </a>
          ->
          <a class="happ-breadcrumb-link" [routerLink]="['/our-services']">
            {{ 'externalSharedService.services' | translate }}
          </a>
          -> {{ serviceInfo && serviceInfo.serviceName }}
        </div>
        <a (click)="goBack()" style="color: white"> {{ 'externalSharedService.back_to_service_list' | translate }} </a>
      </div>
      <div style="margin-top: 15px">
        <table style="margin-top: 24px; width: 100%">
          <tr class="row" style="width: 100%; margin: 0px">
            <!-- <td style="padding-right: 16px;" class="d-none d-md-none d-lg-block col-3">
                            <img *ngIf="!(serviceInfo && serviceInfo.ssHeaderImg)" src="https://pinbox24.s3.eu-central-1.amazonaws.com/pages/default/service-page/default_service_heading_img.png" style="width: 100%; height: 100%;" alt="">
                            <img *ngIf="(serviceInfo && serviceInfo.ssHeaderImg)" [src]="serviceInfo.ssHeaderImg.base64" 
                            [style.width]="serviceInfo.ssHeaderImgWidth || '300px'" [style.height]="serviceInfo.ssHeaderImgHeight || 'auto'" alt="">
                        </td> -->
            <td style="width: 100%">
              <button
                type="button"
                style="
                  width: 100%;
                  font-size: 30px;
                  background: #ffc65cf7;
                  border-radius: 50px;
                  margin-bottom: 6px;
                  border: 0px;
                  margin-top: 12px;
                "
                (click)="registerCase()"
                class="btn btn-light"
              >
                {{ serviceInfo.buttonName || ('externalSharedService.create_case' | translate) }}
              </button>

              <div class="my-3 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <p style="text-align: justify">
                    {{ serviceInfo.serviceData && serviceInfo.serviceData.clientShortDescription }}
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div style="margin-top: 12px" *ngIf="serviceInfo && view === 'detail'" [@inDetail]="'in'" class="container">
    <div class="text-secondary">
      <div
        [innerHTML]="(serviceInfo.serviceData && serviceInfo.serviceData.clientDescription) || '' | htmlWithStyle"
      ></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="view === 'form'">
  <div class="d-flex justify-content-between" style="min-height: 110px; background: #184b85">
    <div class="container d-flex flex-column justify-content-center">
      <div>
        <div class="d-flex flex-column" style="color: white">
          <h1 class="font-weight-bold" style="border-bottom: 1px solid; padding-bottom: 10px; text-align: center">
            {{ serviceInfo && serviceInfo.serviceName }}
          </h1>
        </div>
      </div>
      <div class="d-flex happ-breadcrumb-div">
        <div style="color: white" class="d-none d-md-block">
          <a class="happ-breadcrumb-link" (click)="open('home')">
            {{ 'externalSharedService.home' | translate }}
          </a>
          ->
          <a class="happ-breadcrumb-link" (click)="open('services')">
            {{ 'externalSharedService.services' | translate }}
          </a>
          ->
          <a class="happ-breadcrumb-link" (click)="open('service_detail')">
            {{ 'externalSharedService.service_detail' | translate }}
          </a>
          -> {{ serviceInfo && serviceInfo.serviceName }}
        </div>
        <a (click)="open('services')" style="color: white">
          {{ 'externalSharedService.back_to_service_detail' | translate }}
        </a>
      </div>
      <!-- <div class="d-flex align-items-center justify-content-center">
                <a style="color: white; cursor: pointer; font-size: 22px;" (click)="view='detail'" >
                    Show Service info
                </a>
            </div> -->
    </div>
  </div>
  <div *ngIf="view === 'form'" [@inForm]="'inForm'" style="display: flex; justify-content: center; align-items: center">
    <div class="container mt-3">
      <div *ngIf="serviceInfo">
        <div class="happ-outer-div" *ngIf="formView === 'description'" [@inDetail]="'in'">
          <div>
            <div class="form-group">
              <div class="jumbotron" *ngIf="serviceInfo.formShowDescription_comment">
                <h1 style="text-align: center">{{ serviceInfo.formShowDescription_comment }}</h1>
              </div>
              <label>{{ 'externalSharedService.req_description' | translate }}</label>
              <textarea
                style="font-size: 24px"
                class="form-control"
                [(ngModel)]="formData.description"
                rows="7"
              ></textarea>
            </div>
          </div>
          <div *ngIf="this.serviceInfo && this.serviceInfo.formShowFileUpload === 'Yes' && false">
            <div>
              <div class="container-file" appDnd (fileDropped)="onFileDropped($event)">
                <input
                  type="file"
                  #fileDropRef
                  id="fileDropRef"
                  multiple
                  (change)="fileBrowseHandler($event.target.files)"
                />
                <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
                  <g fill="#3B454F" fill-rule="nonzero">
                    <path
                      d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z"
                    />
                    <path
                      d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z"
                    />
                    <path
                      d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z"
                    />
                  </g>
                </svg>

                <h3>Drag and drop file here</h3>
                <h3>or</h3>
                <label for="fileDropRef">Browse for file</label>
              </div>
              <div class="files-list">
                <div class="single-file" *ngFor="let file of files; let i = index">
                  <div class="file-icon" style="width: 50px">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 58 58"
                      style="enable-background: new 0 0 58 58"
                      xml:space="preserve"
                    >
                      <polygon style="fill: #edeada" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                      <g>
                        <path
                          style="fill: #cec9ae"
                          d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
                        />
                        <path
                          style="fill: #cec9ae"
                          d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
                        />
                        <path
                          style="fill: #cec9ae"
                          d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
                        />
                        <path
                          style="fill: #cec9ae"
                          d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
                        />
                        <path
                          style="fill: #cec9ae"
                          d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
                        />
                      </g>
                      <polygon style="fill: #cec9ae" points="37.5,0 37.5,14 51.5,14 " />
                    </svg>
                  </div>
                  <div class="info">
                    <h4 class="name">
                      {{ file?.name }}
                    </h4>
                    <p class="size">
                      {{ formatBytes(file?.size) }}
                    </p>
                    <app-progress [progress]="file?.progress"></app-progress>
                  </div>

                  <div class="delete" (click)="deleteFile(i)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                      <path
                        fill="#B1B1B1"
                        fill-rule="nonzero"
                        d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="happ-outer-div" *ngIf="formView === 'email'" [@inDetail]="'in'">
          <div>
            <div class="form-group">
              <div class="jumbotron" *ngIf="serviceInfo.formShowEmail_comment">
                <h1 style="text-align: center">{{ serviceInfo.formShowEmail_comment }}</h1>
              </div>
              <label for="exampleInputEmail1">{{ 'externalSharedService.enter_email' | translate }}</label>
              <input
                style="font-size: 24px"
                type="email"
                #contactemail="ngModel"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                [(ngModel)]="formData.email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              <div
                style="text-align: center"
                class="md-errors-spacer"
                [hidden]="contactemail.valid || contactemail.untouched"
              >
                <div class="text-danger" *ngIf="contactemail.errors && contactemail.errors.pattern">
                  {{ 'externalSharedService.email_is_invalid' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="happ-outer-div" *ngIf="formView === 'slotBooking'" [@inDetail]="'in'">
          <ng-container *ngIf="serviceInfo.pickerType === 'dateSlot'">
            <app-slot-booking
              (updateServiceData)="updateData($event)"
              [serviceData]="serviceInfo"
              [(model)]="formData.slotDateTime"
              (next)="nextStep()"
              (previous)="previousStep()"
            ></app-slot-booking>
          </ng-container>
          <ng-container *ngIf="serviceInfo.pickerType === 'dateRange'">
            <app-date-range-picker
              (updateServiceData)="updateData($event)"
              [serviceData]="serviceInfo"
              [(model)]="formData.slotDateTime"
              (next)="nextStep()"
              (previous)="previousStep()"
            ></app-date-range-picker>
          </ng-container>
          <ng-container *ngIf="serviceInfo.pickerType === 'dateTimeRange'">
            <app-date-time-range-picker
              (updateServiceData)="updateData($event)"
              [serviceData]="serviceInfo"
              [(model)]="formData.slotDateTime"
              (next)="nextStep()"
              (previous)="previousStep()"
            ></app-date-time-range-picker>
          </ng-container>
          <ng-container *ngIf="serviceInfo.pickerType === 'singleDate'">
            <app-date-range-picker
              type="single"
              (updateServiceData)="updateData($event)"
              [serviceData]="serviceInfo"
              [(model)]="formData.slotDateTime"
              (next)="nextStep()"
              (previous)="previousStep()"
            ></app-date-range-picker>
          </ng-container>
          <ng-container *ngIf="serviceInfo.pickerType === 'singleDateTime'">
            <app-date-time-range-picker
              type="single"
              (updateServiceData)="updateData($event)"
              [serviceData]="serviceInfo"
              [(model)]="formData.slotDateTime"
              (next)="nextStep()"
              (previous)="previousStep()"
            ></app-date-time-range-picker>
          </ng-container>
        </div>

        <div class="happ-outer-div" *ngIf="formView === 'phoneVerify'" [@inDetail]="'in'">
          <ng-container>
            <div class="form-group">
              <div class="jumbotron" *ngIf="serviceInfo.formShowPhoneVerify_comment">
                <h1 style="text-align: center">{{ serviceInfo.formShowPhoneVerify_comment }}</h1>
              </div>
              <label for="phone">{{ 'externalSharedService.phone_no' | translate }}</label>
              <input
                style="font-size: 24px"
                [disabled]="otpSent"
                [(ngModel)]="formData.phoneNo"
                type="tel"
                class="form-control"
                id="phone"
              />
              <div *ngIf="otpStatus" class="col-md-12" style="margin-top: 12px">
                <ng-container [ngSwitch]="otpStatus">
                  <ng-container *ngSwitchCase="'OTP_SENDED'">
                    <div class="alert alert-success" role="alert">
                      {{ 'externalSharedService.otp_sended' | translate }}
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'OTP_VERIFIED'">
                    <div class="alert alert-success" role="alert">
                      {{ 'externalSharedService.otp_verified' | translate }}
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'INVALID_OTP'">
                    <div class="alert alert-danger" role="alert">
                      {{ 'externalSharedService.invalid_otp' | translate }}
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <!-- <div *ngIf="!otpSent" class="d-flex flex-row-reverse w-100 mr-3">
                          <a class="btn btn-info" (click)="sendOtp()">{{ 'externalSharedService.send_otp' | translate }}</a>
                        </div> -->
            <!-- <div *ngIf="otpStatus && !phoneVerified" class="d-flex flex-row-reverse w-100 mr-3">
                          <a class="btn btn-info" (click)="verifyOtp()">{{ 'externalSharedService.verify_otp' | translate }}</a>
                        </div> -->
          </ng-container>
        </div>
        <div class="happ-outer-div" *ngIf="formView === 'otpView'" [@inDetail]="'in'">
          <div>
            <div class="form-group col-md-12" *ngIf="otpStatus">
              <label for="otp">{{ 'externalSharedService.otp' | translate }}</label>
              <input style="font-size: 24px" [(ngModel)]="otp" type="tel" class="form-control" id="otp" />
            </div>
          </div>
        </div>
        <div class="happ-outer-div" *ngIf="formView === 'thanks'" [@inDetail]="'in'">
          <div [ngSwitch]="view2" style="text-align: center; margin-top: 15px">
            <!-- Your case have been created. You will recive email with detail of case soon. Thanks for choosing us. -->
            <ng-container *ngSwitchCase="'error'">
              <div class="jumbotron">
                <h1 style="text-align: center">
                  {{ 'externalSharedService.some_error_occurred' | translate }}
                </h1>
              </div>
              <!-- <button (click)="view = ''" class="btn btn-primary">{{ 'externalSharedService.go_back' | translate }}</button> -->
            </ng-container>
            <ng-container *ngSwitchCase="'success'">
              <ng-container *ngIf="extraData.isNew; else alreadyAccount">
                <div class="jumbotron">
                  <h1>
                    {{ 'externalSharedService.case_create_success_info' | translate }}
                  </h1>
                  <p>
                    {{ 'externalSharedService.case_create_description_info_for_new_account' | translate }}
                    <!-- {{ 'externalSharedService.case_create_description_info' | translate }} -->
                  </p>
                  <p>
                    <a
                      class="btn btn-primary btn-lg"
                      target="_blank"
                      [href]="frontUrl + 'cp-login?redirectUrl=/cp/my-cases/type/all/' + extraData.caseId"
                      >{{ 'externalSharedService.login_here' | translate }}</a
                    >
                  </p>
                </div>
              </ng-container>

              <ng-template #alreadyAccount>
                <div class="jumbotron">
                  <h1>
                    {{ 'externalSharedService.case_create_success_info' | translate }}
                  </h1>
                  <p>
                    {{ 'externalSharedService.case_create_description_info' | translate }}
                  </p>
                  <p>
                    <a
                      class="btn btn-primary btn-lg"
                      target="_blank"
                      [href]="frontUrl + 'cp-login?redirectUrl=/cp/my-cases/type/all/' + extraData.caseId"
                      >{{ 'externalSharedService.open_case' | translate }}</a
                    >
                  </p>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container [ngSwitch]="formView">
        <ng-container *ngSwitchCase="'description'">
          <div class="m-3 d-flex justify-content-between">
            <span></span>
            <button
              type="button"
              [disabled]="!formData.description"
              (click)="nextStep()"
              class="m-2 btn btn-success btn-lg btn-block"
            >
              {{ 'externalSharedService.next' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'email'">
          <div class="m-3 d-flex justify-content-between">
            <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
              {{ 'externalSharedService.previous' | translate }}
            </button>
            <button
              type="button"
              [disabled]="!formData.email || (contactemail && !contactemail.valid)"
              (click)="nextStep()"
              class="m-2 btn btn-success btn-lg btn-block"
            >
              {{ 'externalSharedService.next' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'phoneVerify'">
          <div class="m-3 d-flex justify-content-between mt-3">
            <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
              {{ 'externalSharedService.previous' | translate }}
            </button>
            <button
              type="button"
              [disabled]="!formData.phoneNo"
              (click)="sendOtp()"
              class="m-2 btn btn-success btn-lg btn-block"
            >
              {{ 'externalSharedService.next' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'otpView'">
          <div class="m-3 d-flex justify-content-between mt-3">
            <button type="button" (click)="previousStep()" class="m-2 btn btn-outline-secondary btn-lg btn-block">
              {{ 'externalSharedService.previous' | translate }}
            </button>
            <button type="button" [disabled]="!otp" (click)="verifyOtp()" class="m-2 btn btn-success btn-lg btn-block">
              {{ 'externalSharedService.next' | translate }}
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
