<div class="d-flex align-items-center" style="background: #efebeb; padding-top: 40px">
  <div class="container">
    <div class="d-flex mb-3">
      <button
        [routerLink]="['/rodo']"
        type="button"
        class="btn happ-btn-hover btn-outline-info happ-selected-button btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.description' | translate }}
      </button>
      <button
        [routerLink]="['/rodo-detail']"
        type="button"
        class="btn happ-btn-hover btn-outline-info btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.provider_details' | translate }}
      </button>
      <button
        [routerLink]="['/rodo-list']"
        type="button"
        class="btn happ-btn-hover btn-outline-info btn-lg btn-block m-1"
      >
        {{ 'externalSharedService.services' | translate }}
      </button>
    </div>

    <div
      class="happ-pdf-html"
      *ngIf="providerInfo && providerInfo.html"
      [innerHTML]="providerInfo.html | htmlWithStyle"
    ></div>
  </div>
</div>
