import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { environment } from '@env/environment';
import { Locale } from './translateLoader.service';
import { ToastrService } from 'ngx-toastr';
import { ComponentMapping } from '@app/@shared/contants/componentMapping.const';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  onLoaderChange: Subject<boolean> = new Subject<boolean>();
  public loader = false;
  public showMenu = true;
  officeName = '';
  constructor(private apiService: ApiService, private route: ActivatedRoute, private toastrService: ToastrService) {}

  formatTheLanguageArray(data: any[] = []) {
    return data.map((ele) => {
      if (ele) {
        return {
          id: ele.code,
          title: ele.name,
          flag: ele.code,
        };
      }
    });
  }

  async getActiveLangs(): Promise<any> {
    try {
      let data = await this.apiService.getRequest(`/i18n/langs`);
      data = data || [];
      data = data.filter((ele: any) => ele && ele.active);
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getTrans(comps: any): Promise<Locale[]> {
    const data = await this.apiService.postRequest(`${environment.serverUrl}/i18n/langs`, {
      componentName: ComponentMapping[comps] || [],
    });
    const temp: Locale[] = [];
    // tslint:disable-next-line:forin
    for (const i in data) {
      temp.push(data[i]);
    }
    return temp;
  }

  async getAboutOffice(officeId: string, query: any = {}) {
    let queryStr = '?';
    if (Object.keys(query)) {
      Object.keys(query).forEach((ele) => {
        queryStr = `${queryStr}&${ele}=${query[ele]}`;
      });
    }
    const temp: any = await this.apiService.getRequest(`/external-page/${officeId}${queryStr}`);
    if (temp.officeName) {
      this.officeName = temp.officeName;
    }
    return temp;
  }

  async getHtmlTemplate(query: any = {}) {
    let queryStr = '?';
    if (Object.keys(query)) {
      Object.keys(query).forEach((ele) => {
        queryStr = `${queryStr}&${ele}=${query[ele]}`;
      });
    }
    const temp: any = await this.apiService.getRequest(`/external-page/html-template${queryStr}`);
    if (temp.officeName) {
      this.officeName = temp.officeName;
    }
    return temp;
  }

  async getAboutService(officeId: string, serviceId: any) {
    const temp: any = await this.apiService.getRequest(`/external-page/${officeId}/service/${serviceId}`);
    if (temp.officeName) {
      this.officeName = temp.officeName;
    }
    return temp;
  }

  getOfficeId(route: any) {
    return window.location.hostname;
    let officeId: any;
    const temp: any = window.location.host;
    console.log(temp);
    const t1 = temp.substr(0, temp.indexOf('.'));
    if (route.snapshot.params.officeId) {
      officeId = route.snapshot.params.officeId;
    } else if (t1) {
      officeId = t1;
    }
    console.log('officeID - ', route.snapshot.params);
    return officeId;
  }

  async showInfo(message: string, title: any = '', type: string = 'info', options: any = {}) {
    this.toastrService[type](message, title, options);
  }

  transformSort(array: any[], field: string): any[] {
    if (!array || (array && array.length === 0)) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a[field] < b[field]) {
        return -1;
      } else if (a && b && a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
